import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';

import { AsyncJob, EmailsParams, State } from '../../models';
import { deserializeKeys, serializeKeys } from '../../utils/jsonUtil';
import * as actions from './actions';
import * as types from './types';

type JobsState = State['jobs'];

// Helpers
const deserializeJob = (d: AsyncJob) => deserializeKeys(d, ['taskInput', 'taskResult']);
const serializeJob = (d: AsyncJob) => serializeKeys(d, ['taskInput', 'taskResult']);

const initialState = {} as JobsState;

export default function(state: JobsState = initialState, action: actions.Action): JobsState {
  switch (action.type) {
    case types.FETCH_JOBS:
    case types.FETCH_JOBS_PAGE:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_JOBS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        jobs: action.payload.items.map(deserializeJob),
        pagination: action.payload.pagination,
        error: null,
      };
    case types.FETCH_JOBS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || true,
      };
    case types.FETCH_JOBS_CANCEL:
    case types.CLEAR_JOBS:
      return {
        ...state,
        pagination: null,
        jobs: null,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
}

// action creators
export const fetchJobs = (params: EmailsParams): actions.FetchJobs => ({ type: types.FETCH_JOBS, payload: params });
export const clearEmails = (): actions.ClearJobs => ({ type: types.CLEAR_JOBS });

export const nextPage = (): actions.FetchJobsPage => ({
  type: types.FETCH_JOBS_PAGE,
  payload: 'next',
});
export const prevPage = (): actions.FetchJobsPage => ({
  type: types.FETCH_JOBS_PAGE,
  payload: 'prev',
});
export const firstPage = (): actions.FetchJobsPage => ({
  type: types.FETCH_JOBS_PAGE,
  payload: 'first',
});
export const lastPage = (): actions.FetchJobsPage => ({
  type: types.FETCH_JOBS_PAGE,
  payload: 'last',
});

// epics
const fetchJobsEpic: HyperEpic<actions.FetchJobsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_JOBS).pipe(
    debounceTime(500),
    switchMap((action: actions.FetchJobs) =>
      api.get(`/api/jobs?${queryString.stringify(action.payload)}`).pipe(
        map(data => ({
          type: types.FETCH_JOBS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_JOBS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_JOBS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchJobsPageEpic: HyperEpic<actions.FetchJobsPage | actions.FetchJobsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_JOBS_PAGE).pipe(
    switchMap((action: actions.FetchJobsPage) =>
      api.get(state$.value.emails.pagination.links[action.payload]).pipe(
        map(({ response: payload }) => ({
          type: types.FETCH_JOBS_SUCCESS,
          payload,
        })),
        takeUntil(action$.ofType(types.FETCH_JOBS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_JOBS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

export const jobsEpics = [fetchJobsEpic, fetchJobsPageEpic];
