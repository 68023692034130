import { Icon } from '@hyperfish/fishfood';
import * as React from 'react';

import { Pagination } from '../../models';

import classes from './styles.module.scss';

export interface Props {
  isDisabled?: boolean;
  pagination: Pagination;
  nextPage: () => void;
  prevPage: () => void;
  firstPage: () => void;
  lastPage: () => void;
}

export const Pager = ({ isDisabled, pagination, nextPage, prevPage, firstPage, lastPage }: Props) => {
  const page = pagination && pagination.page != null ? pagination.page.toLocaleString() : '-';
  const pageCount = pagination && pagination.pageCount != null ? pagination.pageCount.toLocaleString() : '-';
  const itemCount = pagination && pagination.count != null ? pagination.count.toLocaleString() : '-';
  const displayString = `${page} of ${pageCount} (${itemCount} items)`;

  const firstEnabled = !isDisabled && pagination && pagination.links && pagination.links.first != null;
  const prevEnabled = !isDisabled && pagination && pagination.links && pagination.links.prev != null;
  const nextEnabled = !isDisabled && pagination && pagination.links && pagination.links.next != null;
  const lastEnabled = !isDisabled && pagination && pagination.links && pagination.links.last != null;

  return (
    <div className={classes.container}>
      {firstPage && (
        <a onClick={firstEnabled ? firstPage : undefined} className={firstEnabled ? '' : classes.disabled}>
          <Icon name="media-previous" />
        </a>
      )}
      {prevPage && (
        <a onClick={prevEnabled ? prevPage : undefined} className={prevEnabled ? '' : classes.disabled}>
          <Icon name="media-play" style={{ transform: 'rotate(180deg)' }} />
        </a>
      )}
      <span>{displayString}</span>
      {nextPage && (
        <a onClick={nextEnabled ? nextPage : undefined} className={nextEnabled ? '' : classes.disabled}>
          <Icon name="media-play" />
        </a>
      )}
      {lastPage && (
        <a onClick={lastEnabled ? lastPage : undefined} className={lastEnabled ? '' : classes.disabled}>
          <Icon name="media-next" />
        </a>
      )}
    </div>
  );
};
