import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { Action, Audit, AuditsParams, State } from '../../models';
import * as actions from './actions';
import * as types from './types';

type AuditsState = State['audits'];

const initialState = {
  latestAuditById: {},
  latestAuditByIdError: {},
  latestAuditByIdLoading: {},
} as AuditsState;

export default function(state: AuditsState = initialState, action: actions.Action): AuditsState {
  switch (action.type) {
    case types.FETCH_AUDITS:
    case types.FETCH_AUDITS_PAGE:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_AUDITS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        audits: action.payload.items,
        pagination: action.payload.pagination,
        relatedEntities: action.payload.relatedEntities,
        error: null,
      };
    case types.FETCH_AUDITS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || true,
      };
    case types.FETCH_AUDITS_CANCEL:
    case types.CLEAR_AUDITS:
      return {
        ...state,
        pagination: null,
        audits: null,
        error: null,
        isLoading: false,
      };
    case types.FETCH_LATEST_AUDIT:
      return {
        ...state,
        latestAuditByIdLoading: {
          ...state.latestAuditByIdLoading,
          [action.payload]: true,
        },
        latestAuditByIdError: {
          ...state.latestAuditByIdError,
          [action.payload]: null,
        },
      };
    case types.FETCH_LATEST_AUDIT_SUCCESS:
      return {
        ...state,
        latestAuditByIdLoading: {
          ...state.latestAuditByIdLoading,
          [action.payload.orgId]: false,
        },
        latestAuditById: {
          ...state.latestAuditById,
          [action.payload.orgId]: action.payload.audit,
        },
      };
    case types.FETCH_LATEST_AUDIT_FAIL:
      return {
        ...state,
        latestAuditByIdLoading: {
          ...state.latestAuditByIdLoading,
          [action.payload.orgId]: false,
        },
        latestAuditByIdError: {
          ...state.latestAuditByIdError,
          [action.payload.orgId]: action.payload.error,
        },
      };
    case types.FETCH_LATEST_AUDIT_CANCEL:
      return {
        ...state,
        latestAuditByIdLoading: {
          ...state.latestAuditByIdLoading,
          [action.payload]: false,
        },
      };
    default:
      return state;
  }
}

// action creators
export const fetchAudits = (params: AuditsParams): actions.FetchAudits => ({
  type: types.FETCH_AUDITS,
  payload: params,
});
export const clearAudits = (): actions.ClearAudits => ({ type: types.CLEAR_AUDITS });

export const nextPage = (): actions.FetchAuditsPage => ({
  type: types.FETCH_AUDITS_PAGE,
  payload: 'next',
});
export const prevPage = (): actions.FetchAuditsPage => ({
  type: types.FETCH_AUDITS_PAGE,
  payload: 'prev',
});
export const firstPage = (): actions.FetchAuditsPage => ({
  type: types.FETCH_AUDITS_PAGE,
  payload: 'first',
});
export const lastPage = (): actions.FetchAuditsPage => ({
  type: types.FETCH_AUDITS_PAGE,
  payload: 'last',
});

export const fetchLatestAudit = (orgId: string): actions.FetchLatestAudit => ({
  type: types.FETCH_LATEST_AUDIT,
  payload: orgId,
});

// epics
const fetchAuditsEpic: HyperEpic<actions.FetchAuditsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_AUDITS).pipe(
    switchMap((action: actions.FetchAudits) =>
      api.get(`/api/audits?${queryString.stringify(action.payload as any)}`).pipe(
        map(data => ({
          type: types.FETCH_AUDITS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_AUDITS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_AUDITS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchAuditsPageEpic: HyperEpic<actions.FetchAuditsPage | actions.FetchAuditsActions> = (
  action$,
  state$,
  { api },
) =>
  action$.ofType(types.FETCH_AUDITS_PAGE).pipe(
    switchMap((action: actions.FetchAuditsPage) =>
      api.get(state$.value.audits.pagination.links[action.payload]).pipe(
        map(({ response: payload }) => ({
          type: types.FETCH_AUDITS_SUCCESS,
          payload,
        })),
        takeUntil(action$.ofType(types.FETCH_AUDITS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_AUDITS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchLatestAuditEpic: HyperEpic<actions.FetchLatestAuditActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_LATEST_AUDIT).pipe(
    switchMap((action: actions.FetchLatestAudit) =>
      api.get(`/api/audits/latest?orgId=${encodeURIComponent(action.payload)}`).pipe(
        map<{ response: Audit }, actions.FetchLatestAuditSuccess>(data => ({
          type: types.FETCH_LATEST_AUDIT_SUCCESS,
          payload: {
            orgId: action.payload,
            audit: data.response,
          },
        })),
        takeUntil(action$.ofType(types.FETCH_LATEST_AUDIT_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_LATEST_AUDIT_FAIL,
            payload: {
              orgId: action.payload,
              error: error.xhr.response,
            },
          }),
        ),
      ),
    ),
  );

export const auditsEpics = [fetchAuditsEpic, fetchAuditsPageEpic, fetchLatestAuditEpic];
