import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { State } from '../../models';
import * as actions from './actions';
import * as types from './types';

type ReportsState = State['reports'];

const initialState = {} as ReportsState;

export default function(state: ReportsState = initialState, action: actions.Action): ReportsState {
  switch (action.type) {
    case types.FETCH_ORGS_LITE:
      return {
        ...state,
        liteOrgsLoading: true,
      };
    case types.FETCH_ORGS_LITE_SUCCESS:
      return {
        ...state,
        liteOrgsLoading: false,
        liteOrgs: action.payload,
        liteOrgsError: null,
      };
    case types.FETCH_ORGS_LITE_FAIL:
      return {
        ...state,
        liteOrgsLoading: false,
        liteOrgsError: action.payload,
      };
    case types.FETCH_ORGS_LITE_CANCEL:
      return {
        ...state,
        liteOrgsLoading: false,
      };
    default:
      return state;
  }
}

// action creators
export const fetchLiteOrgsReport = (): actions.FetchOrgsLite => ({ type: types.FETCH_ORGS_LITE });

// epics
const fetchLiteOrgsEpic: HyperEpic<actions.FetchOrgsLiteActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORGS_LITE).pipe(
    mergeMap((action: actions.FetchOrgsLite) =>
      api.get(`/api/report/orgs/lite`).pipe(
        map(data => ({
          type: types.FETCH_ORGS_LITE_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_ORGS_LITE_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORGS_LITE_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

export const reportsEpics = [fetchLiteOrgsEpic];
