import * as React from 'react';
import { connect } from 'react-redux';
import { Button, DataTable, StyleUtil } from '@hyperfish/fishfood';

import {
  fetchAuthInteropUsers,
  saveAuthInteropUser,
  cancelSaveAuthInteropUser,
  deleteAuthInteropUser,
  fetchAuthInteropAnalysis,
} from '../../store/System';
import {
  AuthInteropTableRow,
  AuthInteropUser,
  convertAuthInteropRowToId,
  convertAuthRegionToFriendly,
  sortScopesByPower,
} from '../../models/api/AuthInterop';
import { AuthInteropEditModal } from '../../components/AuthInteropEditModal';
import Auth from 'services/Auth';

const connector = connect(
  state => ({
    authUsers: state.system.authInteropUserById,
    authUsersLoading: state.system.authInteropUsersLoading,
    authUsersError: state.system.authInteropUsersError,
    isSavingById: state.system.authInteropUserByIdSaving,
    saveErrorById: state.system.authInteropUserByIdSavingError,
    isDeletingById: state.system.authInteropUserByIdDeleting,
    deleteErrorById: state.system.authInteropUserByIdDeletingError,
    analysis: state.system.authInteropAnalysis,
    analysisLoading: state.system.authInteropAnalysisLoading,
    analysisError: state.system.authInteropAnalysisError,
  }),
  {
    fetchUsers: fetchAuthInteropUsers,
    saveUser: saveAuthInteropUser,
    cancelSave: cancelSaveAuthInteropUser,
    deleteUser: deleteAuthInteropUser,
    fetchAnalysis: fetchAuthInteropAnalysis,
  },
);

type Props = typeof connector['props'];
interface State {
  dirtyUser?: AuthInteropUser;
  showAnalysisReport: boolean;
}

const columns = {
  username: { label: 'Username', sortable: true },
  region: { label: 'Region', sortable: true },
  scopes: { label: 'Permissions' },
  category: { label: 'User type', sortable: true },
};

const Table = DataTable.of(columns);

@connector
export class AuthInterop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dirtyUser: null,
      showAnalysisReport: false,
    };

    this.loadAnalysisReport = this.loadAnalysisReport.bind(this);
  }

  componentDidMount() {
    this.props.fetchUsers();
  }

  loadAnalysisReport() {
    this.props.fetchAnalysis();
    this.setState({ showAnalysisReport: true });
  }

  render() {
    return (
      <div>
        <h1>Hyperfish Admin Users</h1>
        {Auth.doesUserHaveScope('god') ? (
          <div>
            <br />
            {this.renderListSection()}
            {this.state.dirtyUser && this.renderEditModal()}
            <br />
            <h2>Notes</h2>
            <p>Access is restricted to AD users in the Livetiles domain.</p>
            <p>
              A user can exist in more than one region at once. &quot;All regions&quot; permissions override local
              region permissions.
            </p>
          </div>
        ) : (
          <></>
        )}
        {!this.state.showAnalysisReport && (
          <Button onClick={() => this.loadAnalysisReport()}>Load analysis report</Button>
        )}
        {this.state.showAnalysisReport && (
          <div>
            <h4>User analysis report</h4>
            {this.props.analysisLoading && <p>Loading...</p>}
            {this.props.analysisError && <p>Error: {JSON.stringify(this.props.analysisError)}</p>}
            {this.props.analysis && (
              <pre style={{ fontFamily: 'monospace', backgroundColor: 'white', overflowX: 'scroll', margin: '0.2em' }}>
                {JSON.stringify(this.props.analysis, null, 4)}
              </pre>
            )}
          </div>
        )}
      </div>
    );
  }

  renderListSection() {
    const { authUsers, authUsersLoading, authUsersError, deleteUser } = this.props;

    if (authUsersLoading) {
      return <div>Loading...</div>;
    }

    if (authUsersError) {
      return <div>Error: {JSON.stringify(authUsersError.message || JSON.stringify(authUsersError))}</div>;
    }

    const data =
      authUsers &&
      Object.keys(authUsers).map(m => {
        const item = authUsers[m];
        return {
          id: convertAuthInteropRowToId(item),
          username: item.username,
          region: convertAuthRegionToFriendly(item.region),
          scopes: sortScopesByPower(item.scopeList || []).join(' '),
          category: item.userCategory,
        };
      });

    const onEdit = (item: any) => {
      const row = authUsers[item.id];
      this.setState({ dirtyUser: row });
      11;
    };

    const onDelete = (item: any) => {
      const row = authUsers[item.id];

      if (confirm('Are you sure?')) {
        deleteUser(row);
      }
    };

    return (
      <div>
        <Table
          loading={authUsersLoading}
          data={data}
          onEdit={onEdit}
          onDelete={onDelete}
          defaultSortBy={'username'}
          groupBy={'category'}
          shouldDisableEdit={!Auth.doesUserHaveScope('god')}
          shouldDisableDelete={!Auth.doesUserHaveScope('god')}
        />
        <br />
        <div style={{ textAlign: 'right', width: '100%' }}>
          <Button onClick={() => this.setState({ dirtyUser: {} as any })} disabled={!Auth.doesUserHaveScope('god')}>
            Add new row
          </Button>
        </div>
      </div>
    );
  }

  renderEditModal() {
    const { saveUser, cancelSave, isSavingById } = this.props;
    const { dirtyUser } = this.state;

    if (!dirtyUser) {
      return;
    }

    const onCancel = () => {
      cancelSave(dirtyUser);
      this.setState({ dirtyUser: null });
    };

    const onEdit = (prop: string, val: any) => {
      this.setState({ dirtyUser: { ...dirtyUser, [prop]: val } });
    };

    const onSave = (row: AuthInteropTableRow) => {
      saveUser(row);
      this.setState({ dirtyUser: null });
    };

    const isEditing = dirtyUser && !!dirtyUser.id;
    const id = convertAuthInteropRowToId(dirtyUser);
    const isSaving = isSavingById[id];

    return (
      <AuthInteropEditModal
        dirtyUser={dirtyUser}
        isEditing={isEditing}
        onCancel={onCancel}
        onEdit={onEdit}
        onSave={onSave}
        isSaving={isSaving}
      />
    );
  }
}
