import { Card } from '@hyperfish/fishfood';
import * as React from 'react';

import Linklist from '../../components/Linklist';

export default class Reports extends React.Component {
  public render() {
    return (
      <Card>
        <h1>What report would you like?</h1>
        <Linklist links={[{ path: '/reports/lite-orgs', label: 'Lite Orgs Report' }]} />
      </Card>
    );
  }
}
