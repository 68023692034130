import { HyperfishLogo, Icon } from '@hyperfish/fishfood';
import cx from 'classnames';
import Radium from 'radium';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface NavItem {
  hide?: boolean;
  icon?: string;
  label: string;
  notifications?: number;
  path?: string;
  subNav?: NavItem[];
}

interface Props {
  disabled?: boolean;
  navList: NavItem[];
  path: string;
}

interface State {
  activeSub?: NavItem;
  justClicked?: boolean;
}

@Radium
export class SideNav extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps({ navList, path }: Props) {
    for (const navItem of navList) {
      if (!navItem.subNav || !navItem.subNav.length) {
        continue;
      }

      for (const subNavItem of navItem.subNav) {
        if (path.indexOf(subNavItem.path) === 0) {
          this.setState({ activeSub: navItem });
          return;
        }
      }
    }
  }

  render() {
    const { navList, path, disabled } = this.props;
    const { activeSub, justClicked } = this.state;

    let activeSubIndex;
    if (!!activeSub) {
      for (let i = 0; i < activeSub.subNav.length; i++) {
        if (path.indexOf(activeSub.subNav[i].path) === 0) {
          activeSubIndex = i;
          break;
        }
      }
    }

    return (
      <aside
        className={cx('side-nav', {
          'sub-active': !!activeSub,
          disabled,
          'nav-hover': Radium.getState(this.state, 'nav', ':hover'),
        })}
      >
        <a href="/" className="logo-container">
          <HyperfishLogo />
        </a>
        <ul
          className="nav"
          // eslint-disable-next-line react/no-string-refs
          ref="nav"
          style={{ ':hover': {} } as any}
          onMouseOut={() => this.setState({ justClicked: false })}
        >
          {navList
            .filter(n => !n.hide)
            .map(n => (
              <li
                key={n.path}
                className={cx({
                  active: path.indexOf(n.path) === 0,
                  'sub-active': activeSub === n,
                  upgrade: n.path === '/upgrade',
                })}
              >
                {!n.subNav ? (
                  <Link
                    to={n.path}
                    onClick={e => (!disabled ? this.setState({ activeSub: null }) : e.preventDefault())}
                  >
                    {n.icon && <Icon name={n.icon} />}
                    <span>
                      {n.label}
                      {(n.notifications || 0) > 0 && ` (${n.notifications})`}
                    </span>
                  </Link>
                ) : (
                  <Link
                    to={n.path}
                    onClick={e => (!disabled ? this.setState({ activeSub: n, justClicked: true }) : e.preventDefault())}
                  >
                    {n.icon && <Icon name={n.icon} />}
                    <span>
                      {n.label}
                      {(n.notifications || 0) > 0 && ` (${n.notifications})`}
                    </span>
                    <Icon name="chevron-right" />
                  </Link>
                )}
              </li>
            ))}
        </ul>
        {activeSub && (
          <ul className="sub-nav" style={justClicked ? { left: 48 } : {}}>
            <li className="sub-nav-header">{activeSub.label}</li>
            {activeSub.subNav
              .filter(n => !n.hide)
              .map((n, i) => (
                <li key={n.path} className={cx({ active: i === activeSubIndex })}>
                  <Link to={n.path}>
                    <span>{n.label}</span>
                  </Link>
                </li>
              ))}
            {activeSubIndex != null && (
              <li
                className="bar"
                style={{
                  transform: `translateY(${(activeSubIndex - 1) * 50}px)`,
                }}
              />
            )}
          </ul>
        )}
      </aside>
    );
  }
}
