import { StyleUtil, Icon, Card } from '@hyperfish/fishfood';
import * as React from 'react';

import Auth from '../services/Auth';
import { SideNav } from './SideNav';
import { Link } from 'react-router-dom';

const auth = new Auth();

const styles = StyleUtil({
  viewWrapper: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    alignItems: 'center',
    borderBottom: `5px solid ${StyleUtil.colors.blue}`,
    display: 'flex',
    flexDirection: 'row-reverse',
    height: 64 + 5,
    justifyContent: 'space-between',
  },
  userContainer: {
    color: 'rgba(58, 58, 58, 0.8)',
    fontSize: 12,
    position: 'relative',
  },
  userGreeting: {
    marginRight: 6,
    verticalAlign: 'top',
  },
  userPhoto: {
    height: 46,
    marginRight: 6,
    verticalAlign: 'middle',
    width: 'auto',
  },
});

export class Layout extends React.Component {
  login = () => auth.login();
  logout = () => auth.logout();

  getNavList(): SideNav['props']['navList'] {
    return [
      {
        path: '/orgs',
        label: 'Orgs',
        icon: 'user-group',
      },
      {
        //   path: '/users',
        //   label: 'Users',
        //   icon: 'user',
        // }, {
        path: '/devices',
        label: 'Devices',
        icon: 'device-desktop',
      },
      {
        path: '/emails',
        label: 'Emails',
        icon: 'mail',
      },
      {
        path: '/audits',
        label: 'Audits',
        icon: 'lifting',
        subNav: [
          { path: '/audits/all', label: 'All Audits' },
          { path: '/audits/current', label: 'Currently Running' },
          { path: '/audits/failed', label: 'Failed' },
        ],
      },
      {
        path: '/reports',
        label: 'Reports',
        icon: 'graph-bar',
        subNav: [{ path: '/reports/lite-orgs', label: 'Lite Orgs' }],
      },
      {
        path: '/jobs',
        label: 'Jobs',
        icon: 'pulse',
      },
    ];
  }

  public render() {
    return (
      <div className="layout-app">
        <SideNav path={window.location.pathname} navList={this.getNavList()} />
        <main role="main">
          {this.renderHeader()}
          <div style={styles.viewWrapper}>
            {this.renderBreadcrumb()}
            {auth.isAuthenticated() || window.location.pathname === '/callback' ? (
              this.props.children
            ) : (
              <Card>
                <h1>
                  Please <a onClick={this.login}>Log In</a>
                </h1>
                {auth.lastAuthenticationError}
              </Card>
            )}
          </div>
        </main>
        {/* <NotificationContainer
          notifications={notifications}
          dismiss={dismissNotification}
        /> */}
      </div>
    );
  }

  renderHeader() {
    const profile = auth.getProfile();

    return (
      <header className="header" style={styles.header}>
        <div style={styles.userContainer}>
          {!auth.isAuthenticated() ? (
            <a onClick={this.login}>Log In</a>
          ) : (
            <div>
              {profile && (
                <span style={styles.userGreeting}>
                  Welcome back, <em>{profile.name}</em> (<span title={profile.aadId}>{profile.email}</span>)
                </span>
              )}
              {/*profile && (
                <img
                  style={styles.userPhoto}
                  className="header-toggle-drop-picture"
                  src={profile.picture}
                  alt="User Avatar"
                />
              )*/}
              <a onClick={this.logout}>Log Out</a>
            </div>
          )}
        </div>
      </header>
    );
  }

  renderBreadcrumb() {
    const sections = window.location.pathname.split('/');
    const title = sections.length < 2 ? '' : sections[1];

    if (sections.length < 3) {
      let icon;

      const navList = this.getNavList();
      for (const nav of navList) {
        if (nav.path === window.location.pathname) {
          icon = nav.icon;
          break;
        }
      }

      return (
        <h1 className="view-title">
          <Icon name={icon || 'cloud'} />
          <span>{title || 'Home'}</span>
        </h1>
      );
    }

    return (
      <Link className="view-title" to={`/${title}`}>
        <Icon name="chevron-left" />
        <span>Back to {title}</span>
      </Link>
    );
  }
}
