import { StyleUtil, TextField, Card } from '@hyperfish/fishfood';
import * as React from 'react';
import { DownloadButton, Props as DownloadButtonProps } from './DownloadButton/Index';
import { Pager, Props as PagerProps } from './Pager';

interface Props {
  placeholder?: string;
  onQueryChange?: TextField['props']['onChange'];
  queryValue?: string;
  table: JSX.Element;
  pagerProps?: PagerProps;
  downloadButtonProps?: DownloadButtonProps;
  customFilters?: React.ReactNode;
}

export const PagedTable = ({
  customFilters,
  placeholder,
  onQueryChange,
  queryValue,
  table,
  pagerProps,
  downloadButtonProps,
}: Props) => {
  return (
    <div>
      {onQueryChange != null && (
        <div style={StyleUtil.styles.tables.header_noFlex}>
          <TextField placeholder={placeholder} onChange={onQueryChange} value={queryValue} type="search" />
        </div>
      )}
      {customFilters != null && customFilters}
      <Card noPadding={true}>{table}</Card>
      {downloadButtonProps && <DownloadButton {...downloadButtonProps} />}
      {pagerProps != null && <Pager {...pagerProps} />}
    </div>
  );
};
