import { DataTable } from '@hyperfish/fishfood';
import * as React from 'react';
import { connect } from 'react-redux';

import { PagedTable } from '../../components/PagedTable';
import { fetchJobs, firstPage, lastPage, nextPage, prevPage } from '../../store/Jobs';

const JobsTable = DataTable.of({
  index: { label: 'index', hidden: true },
  createdAt: { label: 'Created', type: DataTable.types.datetime },
  jobOriginator: { label: 'Originator' },
  jobType: { label: 'Type' },
  taskProgress: { label: 'Progress' },
  taskStatus: { label: 'Status' },
});

const connector = connect(
  state => ({
    jobs: state.jobs.jobs,
    isLoading: state.jobs.isLoading,
    error: state.jobs.error,
    pagination: state.jobs.pagination,
  }),
  {
    fetchJobs,
    firstPage,
    prevPage,
    nextPage,
    lastPage,
  },
);

type Props = typeof connector['props'];

class Jobs extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchJobs({});
  }

  render() {
    const { jobs, isLoading, pagination, firstPage, prevPage, nextPage, lastPage } = this.props;

    return (
      <div>
        {/* <div style={StyleUtil.styles.tables.header}>
        </div> */}
        <PagedTable
          table={
            <JobsTable
              loading={isLoading}
              data={
                jobs &&
                jobs.map((j, index) => ({
                  index,
                  id: j.jobId,
                  createdAt: j.createdAt,
                  jobOriginator: j.jobOriginator,
                  jobType: j.jobType,
                  taskProgress: j.taskProgress,
                  taskStatus: j.taskStatus,
                }))
              }
              renderDetail={this.renderDetail}
            />
          }
          pagerProps={{
            isDisabled: isLoading,
            pagination,
            nextPage,
            prevPage,
            firstPage,
            lastPage,
          }}
        />
      </div>
    );
  }

  renderDetail = ({ index }) => {
    return (
      <div>
        <h2>Input:</h2>
        {JSON.stringify(this.props.jobs[index].taskInput)}
        <br />
        <br />
        <h2>Output:</h2>
        {JSON.stringify(this.props.jobs[index].taskResult)}
      </div>
    );
  };
}

export default connector(Jobs) as typeof Jobs;
