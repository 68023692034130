import { Options, OptionValues } from 'react-select';

export interface AuthInteropAnalysis {
  environment: string;
  email: string;
  loggedInScopes: string[];
  dbScopes: string[];
  dbEntries: {}[];
  userClaims: { [key: string]: string }[];
}

export interface AuthInteropUser extends AuthInteropTableRow {
  id?: string;
}

export interface AuthInteropTableRow {
  region: string;
  username: string;
  scopeList: string[];
  userCategory?: string;
}

export const AllRegionsOptions: Options<OptionValues> = [
  { value: '*', label: 'All regions' },
  { value: 'dev-k8s', label: 'Development' },
  { value: 'prod', label: 'Production US' },
  { value: 'prod-au', label: 'Production AU' },
  { value: 'prod-auc', label: 'Production AU-Central' },
  { value: 'prod-uk', label: 'Production UK' },
];

export const AllScopesOptions: Options<OptionValues> = [
  { label: 'Read', value: 'read' },
  { label: 'Read org emails', value: 'read-emails' },
  { label: 'Create licenses', value: 'create-license' },
  { label: 'Manage devices', value: 'manage-devices' },
  { label: 'Impersonate users', value: 'impersonate-user' },
  { label: 'Edit orgs', value: 'edit-org' },
  { label: 'Delete orgs', value: 'delete-org' },
  { label: 'Developer', value: 'developer' },
  { label: 'God', value: 'god' },
];

export function convertAuthInteropRowToId(row: AuthInteropTableRow) {
  if (!row) {
    return undefined;
  }

  return `${row.username}|${row.region}`;
}

export function convertAuthRegionToFriendly(region: string) {
  const mapped = optionsToObject(AllRegionsOptions);
  return mapped[region] || region;
}

export function sortScopesByPower(scopes: string[]) {
  const orderedScopeList = AllScopesOptions.map(m => m.value);

  const scopesCopy = [...scopes];
  scopesCopy.sort((a, b) => orderedScopeList.indexOf(a) - orderedScopeList.indexOf(b));
  return scopesCopy;
}

function optionsToObject(opts: Options<OptionValues>) {
  return opts.reduce<{ [name: string]: string }>((p, c) => {
    p[c.value as string] = c.label;
    return p;
  }, {});
}
