import { DataTable, Icon, StyleUtil, TextField } from '@hyperfish/fishfood';
import moment from 'moment';
import * as React from 'react';
import * as Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import Select, { Option } from 'react-select';

import { PagedTable } from '../../components/PagedTable';
import { PreWrapper } from '../../components/PreWrapper';
import { Tabs } from '../../components/Tabs';
import { fetchLogs } from '../../store/Devices';

import classes from './styles.module.scss';

const logTableColumns = {
  level: { label: 'Level' },
  datetime: { label: 'Time Stamp', width: 110 },
  message: { label: 'Message', type: DataTable.types.custom },
  raw: { label: 'Raw', hidden: true },
};

const LogTable = DataTable.of(logTableColumns);

const connector = connect(
  (state, ownProps) => ({
    deviceId: ownProps.match.params['id'],
    isLoading: state.devices.logsByDeviceLoading[ownProps.match.params['id']],
    // logs: require('./logs.json') as (typeof state['devices']['logsByDevice']['foo']),
    logs: state.devices.logsByDevice[ownProps.match.params['id']],
    error: state.devices.logsByDeviceError[ownProps.match.params['id']],
  }),
  {
    fetchLogs,
  },
);

type Props = typeof connector['props'];

interface State {
  selectedLogIndex: number;
  filterString: string;
  filterLevel: string;
  page: number;
  activeView: 'raw' | 'table';
}

class DeviceDetail extends React.Component<Props, State> {
  private cachedLogsKey: string;
  private cachedLogs: DataTable<typeof logTableColumns>['props']['data'];
  private pageLength = 100;

  constructor(props: Props) {
    super(props);
    this.state = { selectedLogIndex: 0, filterString: '', filterLevel: '', page: 1, activeView: 'table' };
  }

  componentDidMount() {
    this.props.fetchLogs(this.props.deviceId);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.logs !== nextProps.logs) {
      this.cachedLogs = null;
      this.cachedLogsKey = null;
    }
  }

  getHighlightedText(text) {
    const { filterString } = this.state;
    return (
      <Highlighter
        textToHighlight={text}
        autoEscape={true}
        searchWords={filterString === '' ? [] : filterString.split(' ')}
      />
    );
  }

  getFilteredLogs(): DataTable<typeof logTableColumns>['props']['data'] {
    const { logs } = this.props;
    const { selectedLogIndex, filterString, filterLevel } = this.state;

    const key = `${'' + selectedLogIndex}_${filterLevel}_${filterString}`;

    if (key !== this.cachedLogsKey || !this.cachedLogs) {
      this.cachedLogsKey = key;
      this.cachedLogs =
        (logs &&
          logs[selectedLogIndex] &&
          logs[selectedLogIndex].entries &&
          logs[selectedLogIndex].entries
            .filter(l => {
              if (l == null || l === '') {
                return false;
              }
              if (filterLevel != '') {
                if (l.toLowerCase().indexOf(filterLevel.toLowerCase()) !== 0) {
                  return false;
                }
              }
              if (filterString !== '') {
                const lowerLog = l.toLowerCase();
                const chunks = filterString
                  .toLowerCase()
                  .split(' ')
                  .filter(c => !!c);
                if (chunks.filter(chunk => lowerLog.indexOf(chunk) > -1).length === 0) {
                  return false;
                }
              }
              return true;
            })
            .map((log, i) => {
              const [level, date, time, hyphen, version, ...rest] = log.split(' ');

              const message = this.getHighlightedText(rest.join(' '));

              const formatString = 'MM/DD h:mma';
              const datetime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss,SSS').format(formatString);

              return {
                id: String(i),
                level,
                datetime,
                message,
                raw: log,
              };
            })) ||
        [];
    }

    return this.cachedLogs;
  }

  getFilteredPagedLogs() {
    const { page } = this.state;
    return this.getFilteredLogs().slice(this.pageLength * (page - 1), this.pageLength * page);
  }

  getPagerProps() {
    const { page } = this.state;
    const logs = this.getFilteredLogs();
    const count = logs.length;
    const pageCount = Math.ceil(count / this.pageLength);

    return {
      nextPage: () => this.setState(({ page }) => ({ page: page + 1 })),
      prevPage: () => this.setState(({ page }) => ({ page: page - 1 })),
      firstPage: () => this.setState(({ page }) => ({ page: 1 })),
      lastPage: () => this.setState(({ page }) => ({ page: pageCount })),
      pagination: {
        count,
        page,
        pageCount,
        links: {
          next: page > pageCount - 1 ? null : '',
          prev: page < 2 ? null : '',
          first: page < 2 ? null : '',
          last: page > pageCount - 1 ? null : '',
        },
      },
    };
  }

  handleDownload = () => {
    const { logs } = this.props;
    const { selectedLogIndex } = this.state;

    const log = logs[selectedLogIndex];
    const filename = log.path.split('\\')[log.path.split('\\').length - 1];
    const body = log.entries.join('\n');

    const blob = new Blob([body], { type: 'application/octet-stream' });

    const el = document.createElement('a');
    el.setAttribute('href', URL.createObjectURL(blob));
    el.setAttribute('download', filename);
    el.style.display = 'none';
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  };

  render() {
    const { isLoading, logs, error } = this.props;
    const { selectedLogIndex, page, activeView } = this.state;

    return (
      <div className={classes.container}>
        <div style={StyleUtil.styles.tables.header}>
          <a style={StyleUtil.styles.tables.headerAction}>
            <Icon name="download" />
            <span style={StyleUtil.styles.tables.headerActionText} onClick={this.handleDownload}>
              Download File
            </span>
          </a>
          <Tabs
            activeKey={'' + selectedLogIndex}
            onChange={i => this.setState({ selectedLogIndex: +i, page: 1, filterString: '', filterLevel: '' })}
            tabs={
              logs &&
              logs.map(({ path }, i) => ({
                label: path.split('\\')[path.split('\\').length - 1],
                key: '' + i,
              }))
            }
          />
        </div>
        {!logs || logs[selectedLogIndex].path.slice(-4) === '.log' ? (
          <PagedTable
            customFilters={this.renderFilters()}
            table={
              activeView === 'table' ? (
                <LogTable
                  loading={isLoading}
                  noDataMessage={error ? 'Error loading logs' : 'No logs found'}
                  data={this.getFilteredPagedLogs()}
                  renderDetail={({ raw }) => this.getHighlightedText(raw)}
                />
              ) : (
                <PreWrapper>
                  {this.getHighlightedText(
                    this.getFilteredPagedLogs()
                      .map(l => l.raw)
                      .join('\n'),
                  )}
                </PreWrapper>
              )
            }
            pagerProps={this.getPagerProps()}
          />
        ) : (
          <PreWrapper>{logs[selectedLogIndex].entries.join('\n')}</PreWrapper>
        )}
      </div>
    );
  }

  renderFilters() {
    const { filterLevel, activeView } = this.state;

    return (
      <div style={StyleUtil.styles.tables.header_noFlex}>
        <div className={classes.filterContainer}>
          <Select
            value={filterLevel}
            className={classes.filterDrop}
            placeholder="Filter Level..."
            onChange={(o: Option<string>) => this.setState({ filterLevel: o ? o.value : '' })}
            options={['DEBUG', 'INFO', 'WARN', 'ERROR', 'FATAL'].map(s => ({ label: s, value: s }))}
          />
          <TextField
            placeholder="Search logs..."
            type="search"
            onChange={e => this.setState({ filterString: e.currentTarget.value, page: 1 })}
          />
          <Tabs
            className={classes.filterView}
            activeKey={activeView}
            onChange={(key: State['activeView']) => this.setState({ activeView: key })}
            tabs={[
              { key: 'raw', label: <Icon name="align-left" /> },
              { key: 'table', label: <Icon name="view-list-large" /> },
            ]}
          />
        </div>
      </div>
    );
  }
}

export default connector(DeviceDetail) as typeof DeviceDetail;
