import * as React from 'react';
import moment from 'moment';
import { Modal, HyperForm, HyperFieldFactory as hFields, StyleUtil } from '@hyperfish/fishfood';
import { Option, OptionValues } from 'react-select';
import { License, LicenseFeature, FeatureMap } from '../../models';

interface LocalFormControls {
  dropdownValue: 'standard' | 'premium' | 'trial' | 'custom' | 'lite';
}

interface Props {
  onCancel: () => void;
  onSave: (dirtyLicense: License) => void;
  onEdit: (property: string, value: any) => void;
  isCreating: boolean;
  dirtyLicense: License;
}

interface LicenseType {
  displayName: string;
  type: string;
  features: string[];
  dropdownValue: string;
}

const LicenseTypes = {
  trial: {
    displayName: 'Trial',
    type: 'Hyperfish Basic',
    features: [
      'AllowAnalyzeMode',
      'AllowPilotMode',
      'AllowDirectory',
      'AllowProfileValidation',
      'AllowCollections',
      'AllowReadOnlyModeSwitch',
    ],
    dropdownValue: 'trial',
  },
  lite: {
    displayName: 'Directory Lite',
    type: 'Hyperfish Free',
    features: ['PaidLicense', 'AllowAnalyzeMode', 'AllowPilotMode', 'AllowRunMode', 'AllowReadOnlyModeSwitch'],
    dropdownValue: 'lite',
  },
  standard: {
    displayName: 'Directory Standard',
    type: 'Hyperfish Basic',
    features: ['PaidLicense', 'AllowAnalyzeMode', 'AllowPilotMode', 'AllowRunMode', 'AllowReadOnlyModeSwitch'],
    dropdownValue: 'standard',
  },
  premium: {
    displayName: 'Directory Premium',
    type: 'Hyperfish Basic',
    features: [
      'PaidLicense',
      'AllowAnalyzeMode',
      'AllowPilotMode',
      'AllowRunMode',
      'AllowDirectory',
      'AllowProfileValidation',
      'AllowCollections',
      'AllowReadOnlyModeSwitch',
    ],
    dropdownValue: 'premium',
  },
  custom: {
    displayName: 'Custom license',
    type: 'Hyperfish Basic',
    features: [],
    dropdownValue: 'custom',
  },
};

export const LicenseEditModal = ({ onCancel, onSave, onEdit, isCreating, dirtyLicense }: Props) => {
  if (dirtyLicense === null) {
    return null;
  }

  const getLicenseType = (type: string, features: LicenseFeature[], isCreating: boolean): LicenseType => {
    if (isCreating) {
      return LicenseTypes.premium;
    }

    if (type === 'Hyperfish Free') {
      return LicenseTypes.lite;
    }

    const finder = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      const sortedA = [...a].sort();
      const sortedB = [...b].sort();

      for (var i = 0; i < a.length; ++i) {
        if (sortedA[i] !== sortedB[i]) return false;
      }
      return true;
    };

    const licenseType = Object.entries(LicenseTypes)
      .filter(([key, value]) => value.type === 'Hyperfish Basic')
      .find(([key, value]) => finder(features, value.features));

    return licenseType ? licenseType[1] : (LicenseTypes.custom as LicenseType);
  };

  const onLicenseDropdownChange = (mode: LocalFormControls['dropdownValue']) => {
    switch (mode) {
      case 'trial':
        onEdit('type', trial.type);
        onEdit('features', trial.features);
        onEdit(
          'validityEnd',
          moment()
            .add(3, 'months')
            .toISOString(),
        );
        break;
      case 'lite':
        onEdit('type', lite.type);
        onEdit('features', lite.features);
        onEdit(
          'validityEnd',
          moment()
            .add(1, 'years')
            .toISOString(),
        );
        break;
      case 'standard':
        onEdit('type', standard.type);
        onEdit('features', standard.features);
        onEdit(
          'validityEnd',
          moment()
            .add(1, 'years')
            .toISOString(),
        );
        break;
      case 'premium':
        onEdit('type', premium.type);
        onEdit('features', premium.features);
        onEdit(
          'validityEnd',
          moment()
            .add(1, 'years')
            .toISOString(),
        );
        break;
      case 'custom':
        onEdit('type', custom.type);
        break;
      default:
        onEdit('type', 'Hyperfish Basic');
    }

    setFormControls({ ...formControls, dropdownValue: mode });
  };

  const onLocalEdit = (key: string, value: any) => {
    const licenseType = getLicenseType(dirtyLicense.type, value, false);
    setFormControls({ ...formControls, dropdownValue: licenseType.dropdownValue });
    onEdit(key, value);
  };

  const { custom, trial, lite, standard, premium } = LicenseTypes;
  const licenseType = getLicenseType(dirtyLicense.type, dirtyLicense.features, isCreating);

  const initialFormControls = isCreating ? { dropdownValue: 'premium' } : { dropdownValue: licenseType.dropdownValue };

  const [formControls, setFormControls] = React.useState(initialFormControls);

  return (
    <Modal onClose={onCancel}>
      <h1 style={StyleUtil.styles.modals.newHeader}>{isCreating ? 'Create' : 'Edit'} License</h1>
      <HyperForm
        onCancel={onCancel}
        dirty={true}
        onSubmit={e => {
          e.preventDefault();
          onSave(dirtyLicense);
        }}
        fields={[
          hFields.dropdown({
            label: 'Preset',
            value: formControls.dropdownValue,
            required: true,
            dropdownOptions: [
              //{ label: lite.displayName, value: lite.dropdownValue },
              { label: standard.displayName, value: standard.dropdownValue },
              { label: premium.displayName, value: premium.dropdownValue },
              { label: trial.displayName, value: trial.dropdownValue },
              { label: custom.displayName, value: custom.dropdownValue },
            ],
            onChange: (o: Option<OptionValues>) => onLicenseDropdownChange(o ? (o.value as any) : null),
          }),
          hFields.date({
            label: 'Starts At',
            value: dirtyLicense.validityStart,
            hintText: dirtyLicense.validityStart ? moment(dirtyLicense.validityStart).fromNow() : null,
            onChange: d => onEdit('validityStart', d),
          }),
          hFields.date({
            label: 'Ends At',
            value: dirtyLicense.validityEnd,
            hintText: dirtyLicense.validityEnd ? moment(dirtyLicense.validityEnd).fromNow() : null,
            onChange: d => onEdit('validityEnd', d),
          }),
          hFields.autocompleteMulti({
            label: 'Features',
            value: dirtyLicense.features,
            onChange: (o: Option<LicenseFeature>[]) => onLocalEdit('features', o ? o.map(x => x.value) : null),
            dropdownOptions:
              formControls.dropdownValue === 'trial'
                ? Object.entries(FeatureMap)
                    .filter(([key, value]) => ![FeatureMap.PaidLicense, FeatureMap.AllowRunMode].includes(value))
                    .map(f => ({ label: f[1], value: f[0] }))
                : Object.keys(FeatureMap).map(m => ({ label: FeatureMap[m], value: m })),
            disabled: dirtyLicense.type === 'Hyperfish Free',
            required: true,
          }),
          hFields.number({
            label: 'Licensed user count',
            value: dirtyLicense.licensedUsers,
            placeholder: 'Optional, defaults to unlimited',
            onChange: d => onEdit('licensedUsers', d.currentTarget.value),
          }),
          isCreating
            ? hFields.dropdown({
                label: 'Starting mode',
                value: dirtyLicense.startingModeValue,
                required: true,
                dropdownOptions: [
                  { label: 'Read-only mode enabled', value: 'readOnlyModeEnabled' },
                  { label: 'Read-only mode disabled', value: 'readOnlyModeDisabled' },
                ],
                onChange: (o: Option<OptionValues>) => onEdit('startingModeValue', o ? o.value : null),
              })
            : null,
        ]}
      />
    </Modal>
  );
};
