export const FETCH_AUTH_INTEROP_USERS = 'FETCH_AUTH_INTEROP_USERS' as 'FETCH_AUTH_INTEROP_USERS';
export const FETCH_AUTH_INTEROP_USERS_SUCCESS = 'FETCH_AUTH_INTEROP_USERS_SUCCESS' as 'FETCH_AUTH_INTEROP_USERS_SUCCESS';
export const FETCH_AUTH_INTEROP_USERS_FAIL = 'FETCH_AUTH_INTEROP_USERS_FAIL' as 'FETCH_AUTH_INTEROP_USERS_FAIL';
export const FETCH_AUTH_INTEROP_USERS_CANCEL = 'FETCH_AUTH_INTEROP_USERS_CANCEL' as 'FETCH_AUTH_INTEROP_USERS_CANCEL';

export const SAVE_AUTH_INTEROP_USER = 'SAVE_AUTH_INTEROP_USER' as 'SAVE_AUTH_INTEROP_USER';
export const SAVE_AUTH_INTEROP_USER_SUCCESS = 'SAVE_AUTH_INTEROP_USER_SUCCESS' as 'SAVE_AUTH_INTEROP_USER_SUCCESS';
export const SAVE_AUTH_INTEROP_USER_FAIL = 'SAVE_AUTH_INTEROP_USER_FAIL' as 'SAVE_AUTH_INTEROP_USER_FAIL';
export const SAVE_AUTH_INTEROP_USER_CANCEL = 'SAVE_AUTH_INTEROP_USER_CANCEL' as 'SAVE_AUTH_INTEROP_USER_CANCEL';

export const DELETE_AUTH_INTEROP_USER = 'DELETE_AUTH_INTEROP_USERS' as 'DELETE_AUTH_INTEROP_USER';
export const DELETE_AUTH_INTEROP_USER_SUCCESS = 'DELETE_AUTH_INTEROP_USER_SUCCESS' as 'DELETE_AUTH_INTEROP_USER_SUCCESS';
export const DELETE_AUTH_INTEROP_USER_FAIL = 'DELETE_AUTH_INTEROP_USER_FAIL' as 'DELETE_AUTH_INTEROP_USER_FAIL';
export const DELETE_AUTH_INTEROP_USER_CANCEL = 'DELETE_AUTH_INTEROP_USER_CANCEL' as 'DELETE_AUTH_INTEROP_USER_CANCEL';

export const FETCH_AUTH_INTEROP_ANALYSIS = 'FETCH_AUTH_INTEROP_ANALYSIS' as 'FETCH_AUTH_INTEROP_ANALYSIS';
export const FETCH_AUTH_INTEROP_ANALYSIS_SUCCESS = 'FETCH_AUTH_INTEROP_ANALYSIS_SUCCESS' as 'FETCH_AUTH_INTEROP_ANALYSIS_SUCCESS';
export const FETCH_AUTH_INTEROP_ANALYSIS_FAIL = 'FETCH_AUTH_INTEROP_ANALYSIS_FAIL' as 'FETCH_AUTH_INTEROP_ANALYSIS_FAIL';
export const FETCH_AUTH_INTEROP_ANALYSIS_CANCEL = 'FETCH_AUTH_INTEROP_ANALYSIS_CANCEL' as 'FETCH_AUTH_INTEROP_ANALYSIS_CANCEL';
