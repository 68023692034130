export const FETCH_LICENSING_LINK = 'FETCH_LICENSING_LINK' as 'FETCH_LICENSING_LINK';
export const FETCH_LICENSING_LINK_SUCCESS = 'FETCH_LICENSING_LINK_SUCCESS' as 'FETCH_LICENSING_LINK_SUCCESS';
export const FETCH_LICENSING_LINK_FAIL = 'FETCH_LICENSING_LINK_FAIL' as 'FETCH_LICENSING_LINK_FAIL';
export const FETCH_LICENSING_LINK_CANCEL = 'FETCH_LICENSING_LINK_CANCEL' as 'FETCH_LICENSING_LINK_CANCEL';
export const CLEAR_LICENSING_LINK = 'CLEAR_LICENSING_LINK' as 'CLEAR_LICENSING_LINK';

export const FETCH_IMPERSONATION_LINK = 'FETCH_IMPERSONATION_LINK' as 'FETCH_IMPERSONATION_LINK';
export const FETCH_IMPERSONATION_LINK_SUCCESS = 'FETCH_IMPERSONATION_LINK_SUCCESS' as 'FETCH_IMPERSONATION_LINK_SUCCESS';
export const FETCH_IMPERSONATION_LINK_FAIL = 'FETCH_IMPERSONATION_LINK_FAIL' as 'FETCH_IMPERSONATION_LINK_FAIL';
export const FETCH_IMPERSONATION_LINK_CANCEL = 'FETCH_IMPERSONATION_LINK_CANCEL' as 'FETCH_IMPERSONATION_LINK_CANCEL';
export const CLEAR_IMPERSONATION_LINK = 'CLEAR_IMPERSONATION_LINK' as 'CLEAR_IMPERSONATION_LINK';
