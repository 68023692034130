import * as React from 'react';
import { Modal, HyperForm, HyperFieldFactory as hFields, StyleUtil, Button } from '@hyperfish/fishfood';
import {
  KubernetesCertificateRequest,
  KubernetesCertificateResponse,
  KubernetesCertificateValidationResponse,
} from '../../models';

interface Props {
  isLoading: boolean;
  isSaving: boolean;
  isSaveErrored: any;
  isDeleting: boolean;
  onCancel: () => void;
  onValidate: (cert: KubernetesCertificateRequest) => void;
  onSave: (cert: KubernetesCertificateRequest) => void;
  onDelete: () => void;
  validationData: { loading: boolean; error?: any; data?: KubernetesCertificateValidationResponse };
  currentCert: KubernetesCertificateResponse;
}

export const KubeCertModal = ({
  isLoading,
  isSaving,
  isSaveErrored,
  isDeleting,
  onCancel,
  onValidate,
  onSave,
  onDelete,
  validationData,
  currentCert,
}: Props) => {
  const [startedDelete, setStartedDelete] = React.useState(false);
  const [dirtyCert, setDirtyCert] = React.useState<KubernetesCertificateRequest>({
    secretName: (currentCert && currentCert.Name) || undefined,
    domainName: (currentCert && currentCert.DomainName) || undefined,
    publicKey: undefined,
    privateKey: undefined,
  });

  if (isLoading) {
    return <Modal onClose={onCancel}>Loading...</Modal>;
  }

  if (isDeleting || startedDelete) {
    return <Modal onClose={onCancel}>Removing certificate...</Modal>;
  }

  const handleOnDelete = () => {
    setStartedDelete(true);
    onDelete();
  };

  const onEdit = <T extends keyof KubernetesCertificateRequest, V extends KubernetesCertificateRequest[T]>(
    k: T,
    v: V,
  ) => {
    setDirtyCert({ ...dirtyCert, [k]: v });
  };

  const validationInError = !!(
    !validationData ||
    validationData.loading ||
    validationData.error ||
    !validationData.data ||
    !validationData.data.validated
  );

  let validationErrorMessage: string;
  if (validationData && validationData.error) {
    if (validationData.error.message) {
      validationErrorMessage = validationData.error.message;
    } else {
      validationErrorMessage = JSON.stringify(validationData.error);
    }
  } else if (validationData.data && !validationData.data.validated) {
    validationErrorMessage = validationData.data.errorReason || 'Unknown';
  }

  const validationSupportedDomains = validationData && validationData.data && validationData.data.validDomains;

  return (
    <Modal onClose={onCancel}>
      <h1 style={StyleUtil.styles.modals.newHeader}>SSL Certificate</h1>
      {currentCert && (
        <>
          <div>
            <h2>Current certificate</h2>
            <div>(Give this to Ops to add to the ingress)</div>
            <br />
            <div>Secret name: {currentCert.Name}-tls-secret</div>
            <div>
              Custom domain: <a href={`https://${currentCert.DomainName}`}>https://{currentCert.DomainName}</a>
            </div>
          </div>
          <br />
          {/*<a onClick={handleOnDelete}>Remove</a> add this back with an 'are you sure?'
          <br />
          <br />*/}
        </>
      )}
      <div>
        <h2>{currentCert ? 'Replace' : 'Upload'} certificate</h2>
        <HyperForm
          onCancel={onCancel}
          dirty={true}
          onSubmit={e => {
            e.preventDefault();
            if (validationInError) {
              onValidate(dirtyCert);
            } else {
              onSave(dirtyCert);
            }
          }}
          saveText={validationInError ? 'Validate' : 'Save'}
          disableSave={validationData.loading || isSaving}
          fields={[
            hFields.text({
              label: 'Secret name',
              value: dirtyCert.secretName,
              required: true,
              hintText: !currentCert
                ? 'Internal identifier to use for certificate, should be a single word, dashes allowed'
                : undefined,
              placeholder: 'customername',
              onChange: d => onEdit('secretName', d.currentTarget.value),
              readOnly: !!currentCert,
            }),
            hFields.text({
              label: 'Domain name',
              value: dirtyCert.domainName,
              required: true,
              hintText: 'Domain name assigned to the certificate',
              placeholder: 'profile.customer.net',
              onChange: d => onEdit('domainName', d.currentTarget.value),
            }),
            hFields.longText({
              label: 'Public key',
              value: dirtyCert.publicKey,
              required: true,
              hintText: 'Public key of certificate in PEM format',
              placeholder: '-----BEGIN CERTIFICATE-----\n...',
              onChange: d => onEdit('publicKey', d.currentTarget.value),
            }),
            hFields.longText({
              label: 'Private key',
              value: dirtyCert.privateKey,
              required: !currentCert,
              hintText: !!currentCert
                ? 'Replacing the private key is optional\n'
                : 'Private key of certificate in PEM format',
              placeholder: '-----BEGIN RSA PRIVATE KEY-----\n...',
              onChange: d => onEdit('privateKey', d.currentTarget.value),
            }),
          ]}
        />
        {validationErrorMessage && (
          <p>
            Validation failed: {validationErrorMessage}
            {validationSupportedDomains && (
              <>
                <br />
                Allowed domains: {validationSupportedDomains.join(', ')}
              </>
            )}
          </p>
        )}
        {isSaveErrored && <p>Save failed: {isSaveErrored.message}</p>}
      </div>
    </Modal>
  );
};
