import { DataTable, dateFormatter, StyleUtil } from '@hyperfish/fishfood';
import moment from 'moment';
import * as React from 'react';
import Select, { Option } from 'react-select';

import { Audit, AuditType, Org, Pagination } from '../../models';
import { Copy } from '../Copy';
import { PagedTable } from '../PagedTable';

import classes from './styles.module.scss';

const columns = {
  orgName: { label: 'Org Name', hidden: false },
  createdAt: { label: 'Created' },
  finishedAt: { label: 'Finished' },
  type: { label: 'Type' },
  status: { label: 'Status' },
  auditRecordCount: { label: 'Audit Count' },
  recordCount: { label: 'Record Count' },
  index: { label: 'Index', hidden: true },
};

const Table = DataTable.of(columns);

interface Props {
  audits: Audit[];
  isLoading: boolean;
  orgs?: { [orgId: string]: Org };
  types?: AuditType[];
  onTypesChange?: (types: AuditType[]) => void;

  pagerProps?: {
    pagination: Pagination;
    nextPage: () => void;
    prevPage: () => void;
    firstPage: () => void;
    lastPage: () => void;
  };
}

const shortFormat = 'M/D/YY h:mma';
const dateFormat = 'MM/DD/YYYY h:mm:ss:SSa';

export const AuditsTable = ({ isLoading, audits, orgs, pagerProps, types, onTypesChange }: Props) => (
  <PagedTable
    customFilters={
      types &&
      onTypesChange && (
        <div style={StyleUtil.styles.tables.header}>
          <Select
            className={classes.select}
            placeholder="Filter by Type"
            value={types}
            onChange={(vals: Option<AuditType>[]) => (vals ? onTypesChange(vals.map(v => v.value)) : onTypesChange([]))}
            multi={true}
            options={[
              { value: 'online', label: 'online' },
              { value: 'external', label: 'external' },
              { value: 'onlineIncremental', label: 'onlineIncremental' },
              { value: 'externalIncremental', label: 'externalIncremental' },
            ]}
          />
        </div>
      )
    }
    table={
      <Table
        columns={
          orgs != null
            ? columns
            : {
                ...columns,
                orgName: {
                  ...columns.orgName,
                  hidden: true,
                },
              }
        }
        loading={isLoading}
        data={
          audits &&
          audits.map((a, index) => ({
            ...a,
            createdAt: dateFormatter(a.createdAt, shortFormat),
            finishedAt: dateFormatter(a.finishedAt, shortFormat),
            orgName: orgs && orgs[a.orgId] && orgs[a.orgId].orgName,
            auditRecordCount: a.auditRecordCount.toLocaleString(),
            recordCount: a.recordCount.toLocaleString(),
            index,
          }))
        }
        renderDetail={({ id, index }) => (
          <table className={classes.detailTable}>
            <tr>
              <td>Id:</td>
              <td>
                {id}
                <Copy text={id} />
              </td>
            </tr>
            <tr>
              <td>Created at:</td>
              <td>{dateFormatter(audits[+index].createdAt as string, dateFormat)}</td>
            </tr>
            <tr>
              <td>Started at:</td>
              <td>{dateFormatter(audits[+index].startedAt, dateFormat)}</td>
            </tr>
            <tr>
              <td>Finished at:</td>
              <td>{dateFormatter(audits[+index].finishedAt as string, dateFormat)}</td>
            </tr>
            <tr>
              <td>Duration</td>
              <td>{moment(audits[+index].finishedAt || moment.now()).from(audits[+index].createdAt, true)}</td>
            </tr>
          </table>
        )}
      />
    }
    pagerProps={
      pagerProps && {
        isDisabled: isLoading,
        ...pagerProps,
      }
    }
  />
);
