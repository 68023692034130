import { ajax, AjaxRequest } from 'rxjs/ajax';

interface Auth {
  getAuthorizationHeader(): { Authorization: string };
}

export default class ApiClient {
  private auth: Auth;

  constructor(auth: Auth) {
    this.auth = auth;
  }

  public get = (url: string, options: AjaxRequest = {}) => this.request({ ...options, url, method: 'GET' });
  public post = (url: string, body?: any, options: AjaxRequest = {}) =>
    this.request({ ...options, url, body, method: 'POST' });
  public put = (url: string, body?: any, options: AjaxRequest = {}) =>
    this.request({ ...options, url, body, method: 'PUT' });
  public patch = (url: string, body?: any, options: AjaxRequest = {}) =>
    this.request({ ...options, url, body, method: 'PATCH' });
  public del = (url: string, options: AjaxRequest = {}) => this.request({ ...options, url, method: 'DELETE' });

  private request(options: AjaxRequest) {
    options.responseType = 'json';
    options.headers = {
      ...this.auth.getAuthorizationHeader(),
      'Content-Type': 'application/json',
      ...(options.headers || {}),
    };

    if (options.body != null) {
      options.body = JSON.stringify(options.body);
    }

    return ajax(options);
  }
}
