import { getFrom } from '@hyperfish/fishfood';
import moment from 'moment';
import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, flatMap } from 'rxjs/operators';

import { Action, State } from '../../models';
import { mapArrayToObject } from '../../utils/mapArrayToObject';
import * as actions from './actions';
import * as types from './types';

type DevicesState = State['devices'];
type Device = DevicesState['devicesById']['foo'];

// helpers
function deserializeDevice(d: Device): Device {
  try {
    d.meta = JSON.parse(d.meta as any);
  } finally {
    /* NO OP */
  }

  return d;
}

function serializeDevice(d: Device) {
  try {
    d.meta = JSON.stringify(d.meta) as any;
  } finally {
    /* NO OP */
  }

  return d;
}

const initialState = {
  deviceIds: null,
  devicesById: {},
  isDeviceOffline: {},
  relatedEntities: { orgs: {} },
  isLoading: false,
  error: null,

  logsByDevice: {},
  logsByDeviceLoading: {},
  logsByDeviceError: {},

  settingLevelByDevice: {},
  settingLevelByDeviceError: {},

  settingVersionByDevice: {},
  settingVersionByDeviceError: {},

  settingEnabledByDevice: {},
  settingEnabledByDeviceError: {},
} as DevicesState;

export default function(state: DevicesState = initialState, action: actions.Action): DevicesState {
  switch (action.type) {
    case types.FETCH_DEVICES:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_DEVICES_SUCCESS: {
      const deviceIds = action.payload.devices.map(({ id }) => id);
      const devicesById = mapArrayToObject(action.payload.devices.map(deserializeDevice));

      const isDeviceOffline = {};
      const cutoff = moment().subtract(15, 'minutes');
      for (const id of deviceIds) {
        const d = devicesById[id];
        const lastHeartBeat = getFrom(d.meta)('heartbeat')('timestamp').value;
        const isOffline = lastHeartBeat == null || moment(lastHeartBeat, moment.ISO_8601).isBefore(cutoff);
        isDeviceOffline[d.id] = isOffline;
      }

      return {
        ...state,
        isLoading: false,
        deviceIds,
        devicesById,
        isDeviceOffline,
        relatedEntities: { ...state.relatedEntities, orgs: action.payload.relatedEntities.orgs },
        error: null,
      };
    }
    case types.FETCH_DEVICES_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case types.FETCH_DEVICES_CANCEL:
      return {
        ...state,
        isLoading: false,
      };

    case types.FETCH_LOGS:
      return {
        ...state,
        logsByDeviceLoading: { ...state.logsByDeviceLoading, [action.payload.deviceId]: true },
      };
    case types.FETCH_LOGS_SUCCESS:
      return {
        ...state,
        logsByDeviceLoading: { ...state.logsByDeviceLoading, [action.payload.deviceId]: false },
        logsByDevice: { ...state.logsByDevice, [action.payload.deviceId]: action.payload.results },
        logsByDeviceError: { ...state.logsByDeviceError, [action.payload.deviceId]: null },
      };
    case types.FETCH_LOGS_FAIL:
      return {
        ...state,
        logsByDeviceLoading: { ...state.logsByDeviceLoading, [action.payload.deviceId]: false },
        logsByDeviceError: { ...state.logsByDeviceError, [action.payload.deviceId]: action.payload.error || true },
      };
    case types.FETCH_LOGS_CANCEL:
      return {
        ...state,
        logsByDeviceLoading: { ...state.logsByDeviceLoading, [action.payload.deviceId]: false },
      };
    case types.CLEAR_LOGS:
      return {
        ...state,
        logsByDevice: { ...state.logsByDevice, [action.payload.deviceId]: null },
      };

    case types.FETCH_DEVICE_VERSIONS:
      return {
        ...state,
        versionsLoading: true,
      };
    case types.FETCH_DEVICE_VERSIONS_SUCCESS:
      return {
        ...state,
        versionsLoading: false,
        versionsError: null,
        versions: action.payload,
      };
    case types.FETCH_DEVICE_VERSIONS_FAIL:
      return {
        ...state,
        versionsLoading: false,
        versionsError: action.payload,
      };
    case types.FETCH_DEVICE_VERSIONS_CANCEL:
      return {
        ...state,
        versionsLoading: false,
      };

    case types.SET_LEVEL:
      return {
        ...state,
        settingLevelByDevice: { ...state.settingLevelByDevice, [action.payload.deviceId]: true },
        settingLevelByDeviceError: { ...state.settingLevelByDeviceError, [action.payload.deviceId]: null },
      };
    case types.SET_LEVEL_SUCCESS:
      return {
        ...state,
        settingLevelByDevice: { ...state.settingLevelByDevice, [action.payload.deviceId]: false },
      };
    case types.SET_LEVEL_FAIL:
      return {
        ...state,
        settingLevelByDevice: { ...state.settingLevelByDevice, [action.payload.deviceId]: false },
        settingLevelByDeviceError: {
          ...state.settingLevelByDeviceError,
          [action.payload.deviceId]: action.payload.error || true,
        },
      };
    case types.SET_LEVEL_CANCEL:
      return {
        ...state,
        settingLevelByDevice: { ...state.settingLevelByDevice, [action.payload.deviceId]: false },
      };

    case types.SET_VERSION:
      return {
        ...state,
        settingVersionByDevice: { ...state.settingVersionByDevice, [action.payload.deviceId]: true },
        settingVersionByDeviceError: { ...state.settingVersionByDeviceError, [action.payload.deviceId]: null },
      };
    case types.SET_VERSION_SUCCESS:
      return {
        ...state,
        settingVersionByDevice: { ...state.settingVersionByDevice, [action.payload.deviceId]: false },
      };
    case types.SET_VERSION_FAIL:
      return {
        ...state,
        settingVersionByDevice: { ...state.settingVersionByDevice, [action.payload.deviceId]: false },
        settingVersionByDeviceError: {
          ...state.settingVersionByDeviceError,
          [action.payload.deviceId]: action.payload.error || true,
        },
      };
    case types.SET_VERSION_CANCEL:
      return {
        ...state,
        settingVersionByDevice: { ...state.settingVersionByDevice, [action.payload.deviceId]: false },
      };

    case types.SET_ENABLED:
      return {
        ...state,
        settingEnabledByDevice: { ...state.settingEnabledByDevice, [action.payload.deviceId]: true },
        settingEnabledByDeviceError: { ...state.settingEnabledByDeviceError, [action.payload.deviceId]: null },
      };
    case types.SET_ENABLED_SUCCESS:
      return {
        ...state,
        settingEnabledByDevice: { ...state.settingEnabledByDevice, [action.payload.id]: false },
        devicesById: { ...state.devicesById, [action.payload.id]: deserializeDevice(action.payload) },
      };
    case types.SET_ENABLED_FAIL:
      return {
        ...state,
        settingEnabledByDevice: { ...state.settingEnabledByDevice, [action.payload.deviceId]: false },
        settingEnabledByDeviceError: {
          ...state.settingEnabledByDeviceError,
          [action.payload.deviceId]: action.payload.error || true,
        },
      };
    case types.SET_ENABLED_CANCEL:
      return {
        ...state,
        settingEnabledByDevice: { ...state.settingEnabledByDevice, [action.payload.deviceId]: false },
      };
    default:
      return state;
  }
}

// action creators
export const fetchDevices = (q?: string): actions.FetchDevicesAction => ({ type: types.FETCH_DEVICES });
export const fetchLogs = (deviceId: string): actions.FetchLogs => ({ type: types.FETCH_LOGS, payload: { deviceId } });
export const fetchVersions = (showAll = false): actions.FetchDeviceVersionsAction => ({
  type: types.FETCH_DEVICE_VERSIONS,
  payload: { showAll },
});
export const setVersion = (deviceId: string, version: string): actions.SetVersion => ({
  type: types.SET_VERSION,
  payload: { deviceId, version },
});
export const setLevel = (deviceId: string, level: string): actions.SetLevel => ({
  type: types.SET_LEVEL,
  payload: { deviceId, level },
});
export const setEnabled = (deviceId: string, enabled: boolean): actions.SetEnabled => ({
  type: types.SET_ENABLED,
  payload: { deviceId, enabled },
});

// epics
const fetchDevicesEpic: HyperEpic<actions.FetchDevicesActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_DEVICES).pipe(
    switchMap((action: actions.FetchDevicesAction) =>
      api.get(`/api/devices`).pipe(
        map(data => ({
          type: types.FETCH_DEVICES_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_DEVICES_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_DEVICES_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchLogsEpic: HyperEpic<actions.FetchLogsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_LOGS).pipe(
    switchMap(action =>
      api.get(`/api/devices/${action.payload.deviceId}/logs`).pipe(
        map(data => ({
          type: types.FETCH_LOGS_SUCCESS,
          payload: {
            deviceId: action.payload.deviceId,
            results: data.response,
          },
        })),
        takeUntil(action$.ofType(types.FETCH_LOGS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_LOGS_FAIL,
            payload: {
              deviceId: action.payload.deviceId,
              error: error.xhr.response,
            },
          }),
        ),
      ),
    ),
  );

const fetchDeviceVersionsEpic: HyperEpic<actions.FetchDeviceVersionsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_DEVICE_VERSIONS).pipe(
    switchMap((action: actions.FetchDeviceVersionsAction) =>
      api.get('/api/devices/versions?' + queryString.stringify(action.payload)).pipe(
        map(data => ({ type: types.FETCH_DEVICE_VERSIONS_SUCCESS, payload: data.response })),
        takeUntil(action$.ofType(types.FETCH_DEVICE_VERSIONS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_DEVICE_VERSIONS_FAIL,
            payload: {
              error: error.xhr.response,
            },
          }),
        ),
      ),
    ),
  );

const setVersionEpic: HyperEpic<actions.SetVersionActions> = (action$, state$, { api }) =>
  action$.ofType(types.SET_VERSION).pipe(
    flatMap(({ payload: { deviceId, version } }: actions.SetVersion) =>
      api.post(`/api/devices/${deviceId}/version`, version).pipe(
        map(data => ({ type: types.SET_VERSION_SUCCESS, payload: { deviceId } })),
        takeUntil(action$.ofType(types.SET_VERSION_CANCEL)),
        catchError(error =>
          of({
            type: types.SET_VERSION_FAIL,
            payload: { deviceId, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

const setLevelEpic: HyperEpic<actions.SetLevelActions> = (action$, state$, { api }) =>
  action$.ofType(types.SET_LEVEL).pipe(
    flatMap(({ payload: { deviceId, level } }: actions.SetLevel) =>
      api.post(`/api/devices/${deviceId}/level`, level).pipe(
        map(data => ({ type: types.SET_LEVEL_SUCCESS, payload: { deviceId } })),
        takeUntil(action$.ofType(types.SET_LEVEL_CANCEL)),
        catchError(error =>
          of({
            type: types.SET_LEVEL_FAIL,
            payload: { deviceId, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

const setEnabledEpic: HyperEpic<actions.SetEnabledActions> = (action$, state$, { api }) =>
  action$.ofType(types.SET_ENABLED).pipe(
    flatMap(({ payload: { deviceId, enabled } }: actions.SetEnabled) =>
      api.post(`/api/devices/${deviceId}/enabled`, enabled).pipe(
        map(data => ({ type: types.SET_ENABLED_SUCCESS, payload: data.response })),
        takeUntil(action$.ofType(types.SET_ENABLED_CANCEL)),
        catchError(error =>
          of({
            type: types.SET_ENABLED_FAIL,
            payload: { deviceId, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

export const devicesEpics = [
  fetchDevicesEpic,
  fetchLogsEpic,
  fetchDeviceVersionsEpic,
  setVersionEpic,
  setLevelEpic,
  setEnabledEpic,
];
