import { Button, DataTable, dateFormatter, Icon, Modal, StyleUtil, TextField } from '@hyperfish/fishfood';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select, { Option } from 'react-select';

import Linklist from '../../components/Linklist';
import { OrgEditModal } from '../../components/OrgEditModal';
import { OrgSummaryTable } from '../../components/OrgSummaryTable';
import { PagedTable } from '../../components/PagedTable';
import { Spinner } from '../../components/Spinner';
import {
  editOrg,
  endDelete,
  endEdit,
  exportOrgs,
  fetchFields,
  fetchLicenses,
  fetchOrgs,
  firstPage,
  lastPage,
  nextPage,
  prevPage,
  saveOrg,
  setCollectionByAttribute,
  startDelete,
  startEdit,
} from '../../store/Orgs';
import { getCombinedOrgLicense } from '../../models/api/License';
import Auth from '../../services/Auth';

import classes from './styles.module.scss';

const OrgsTable = DataTable.of({
  orgName: { label: 'Name', type: DataTable.types.custom },
  license: { label: 'License' },
  type: { label: 'Type' },
  mode: { label: 'Mode' },
  readOnlyMode: { label: 'Read-only mode' },
  accounts: { label: 'Accounts', type: DataTable.types.custom },
  createdAt: { label: 'Created At', type: DataTable.types.datetime },
});

const connector = connect(({ orgs, licenses }) => ({ ...orgs, ...licenses }), {
  editOrg,
  endEdit,
  fetchFields,
  fetchLicenses,
  fetchOrgs,
  firstPage,
  lastPage,
  nextPage,
  prevPage,
  saveOrg,
  startEdit,
  startDelete,
  endDelete,
  setCollectionByAttribute,
  exportOrgs,
});

type Props = typeof connector['props'];
type Org = Props['orgsById']['foo'];

interface State {
  deleteOrgInputValue: string;
  deleteOrgPromptId: string;
  collectionByAttributePromptId: string;
  collectionByAttributePromptFieldValue: string;

  filters: {
    filterQ: string;
    filterLicenses: string[];
    filterTypes: string[];
    filterModes: string[];
  };
}

class Orgs extends React.Component<Props, Partial<State>> {
  private getParamsFromFilters(filters) {
    return {
      q: filters.filterQ,
      licenses: filters.filterLicenses,
      types: filters.filterTypes,
      modes: filters.filterModes,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteOrgInputValue: '',
      deleteOrgPromptId: null,
      collectionByAttributePromptId: null,
      collectionByAttributePromptFieldValue: '',
      filters: {
        filterQ: '',
        filterLicenses: ['Hyperfish Free', 'Hyperfish Basic'],
        filterTypes: [],
        filterModes: [],
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { dirtyOrg, isSavingById, collectionByAttributeSaving } = this.props;
    if (dirtyOrg && isSavingById[dirtyOrg.id] && !nextProps.isSavingById[dirtyOrg.id]) {
      nextProps.endEdit();
    }
  }

  public UNSAFE_componentWillMount() {
    this.props.fetchOrgs(this.getParamsFromFilters(this.state.filters));
  }

  private selectOrg = ({ id }) => this.props.startEdit(id);
  private promptDeleteOrg = ({ id }) => {
    this.props.endDelete();
    this.setState({ deleteOrgInputValue: '', deleteOrgPromptId: id });
  };
  private closeDeleteOrg = () => this.setState({ deleteOrgInputValue: '', deleteOrgPromptId: null });

  private changeFilters = (filters: Partial<State['filters']>) => {
    const newFilters = {
      ...this.state.filters,
      ...filters,
    };
    this.setState({ filters: newFilters });
    this.props.fetchOrgs(this.getParamsFromFilters(newFilters));
  };

  private handleQueryChange: TextField['props']['onChange'] = ({ currentTarget: { value } }) => {
    this.changeFilters({ filterQ: value });
  };

  private filterByLicense = (licenses: Option<string>[]) =>
    this.changeFilters({
      filterLicenses: licenses ? licenses.map(l => l.value) : [],
    });

  private filterByMode = (modes: Option<string>[]) =>
    this.changeFilters({ filterModes: modes ? modes.map(l => l.value) : [] });

  private filterByType = (types: Option<string>[]) =>
    this.changeFilters({ filterTypes: types ? types.map(l => l.value) : [] });

  private handleExpandRow = ({ id }) => {
    // const { fetchLicenses } = this.props;
    // fetchLicenses(id); // Don't need it no mo
  };

  public render() {
    const {
      firstPage,
      isLoading,
      lastPage,
      licenseIdsById,
      licensesById,
      nextPage,
      orgIds,
      orgsById,
      pagination,
      prevPage,
      settingsById,
      latestAuditById,
      exportOrgs,
      isExporting,
    } = this.props;

    return (
      <div>
        <PagedTable
          placeholder="Search by name (contains), ID (exact), or Tenant ID (exact)..."
          customFilters={this.renderFilters()}
          onQueryChange={this.handleQueryChange}
          queryValue={this.state.filters.filterQ}
          table={
            <OrgsTable
              loading={isLoading}
              defaultSortBy="createdAt"
              defaultSortDir="descending"
              data={
                orgIds &&
                orgIds.map(id => {
                  const { orgName, type, createdAt } = orgsById[id];

                  const licenses = licenseIdsById[id] && licenseIdsById[id].map(m => licensesById[m]);
                  const combinedLicense = getCombinedOrgLicense(licenses);

                  const readOnlyMode =
                    settingsById[id] &&
                    settingsById[id]
                      .filter(s => s.settingProperty === 'read_only_mode')
                      .map(s => s.settingValue.value)
                      .join(',') === 'true';

                  const mode =
                    settingsById[id] &&
                    settingsById[id]
                      .filter(s => s.settingProperty === 'global_mode')
                      .map(s => s.settingValue.value)
                      .join(',');

                  const latestAudit = latestAuditById[id];
                  const accounts = (
                    <span>
                      {latestAudit == null ? (
                        '-'
                      ) : (
                        <span title={`As of ${dateFormatter(latestAudit.createdAt)}`}>
                          {latestAudit.recordCount.toLocaleString()}
                        </span>
                      )}
                      <span title="Licensed user count">
                        &nbsp;of&nbsp;
                        {combinedLicense && combinedLicense.licensedUsers > 0 ? (
                          combinedLicense.licensedUsers
                        ) : (
                          <>&infin;</>
                        )}
                      </span>
                    </span>
                  );

                  return {
                    id,
                    orgName: <Link to={`/orgs/${id}`}>{orgName}</Link>,
                    type,
                    createdAt,
                    accounts,
                    mode,
                    readOnlyMode: readOnlyMode ? 'Enabled' : 'Disabled',
                    license: (combinedLicense && combinedLicense.softName) || 'Not licensed',
                  };
                })
              }
              onEdit={this.selectOrg}
              shouldDisableEdit={!Auth.doesUserHaveScope('edit-org')}
              onDelete={this.promptDeleteOrg}
              shouldDisableDelete={!Auth.doesUserHaveScope('delete-org')}
              renderDetail={this.renderDetail}
              onExpandRow={this.handleExpandRow}
            />
          }
          pagerProps={{
            pagination,
            nextPage,
            prevPage,
            firstPage,
            lastPage,
            isDisabled: isLoading,
          }}
          downloadButtonProps={{
            enabled: !isLoading && orgIds && orgIds.length > 0,
            exportData: exportOrgs,
            isExporting,
            filter: this.getParamsFromFilters(this.state.filters),
          }}
        />
        {this.renderActiveModal()}
        {this.renderDeleteModal()}
      </div>
    );
  }

  private renderFilters() {
    const {
      filters: { filterLicenses, filterModes, filterTypes },
    } = this.state;

    return (
      <div style={StyleUtil.styles.tables.header}>
        <Select
          className={classes.select}
          placeholder="Filter by License"
          value={filterLicenses}
          onChange={this.filterByLicense}
          multi={true}
          options={[
            { value: 'Hyperfish Free', label: 'Lite' },
            { value: 'Hyperfish Basic', label: 'Basic' },
          ]}
        />
        <Select
          className={classes.select}
          placeholder="Filter by Type"
          value={filterTypes}
          onChange={this.filterByType}
          multi={true}
          options={[
            { value: 'Online', label: 'Online' },
            { value: 'Hybrid', label: 'Hybrid' },
          ]}
        />
        <Select
          className={classes.select}
          placeholder="Filter by Mode"
          value={filterModes}
          onChange={this.filterByMode}
          multi={true}
          options={[
            { value: 'Pilot', label: 'Pilot' },
            { value: 'Analyze', label: 'Analyze' },
            { value: 'Run', label: 'Run' },
          ]}
        />
      </div>
    );
  }

  private renderDetail = ({ id }) => {
    const { licenseIdsById, licensesById, orgsById } = this.props;
    const licenses = licenseIdsById[id] && licenseIdsById[id].map(id => licensesById[id]).filter(Boolean);
    const org = orgsById[id];

    return (
      <div>
        <OrgSummaryTable org={org} licenses={licenses} />
        <Linklist
          links={[
            org.type !== 'Online' && {
              path: `/devices?orgs=${encodeURIComponent(org.id)}`,
              label: 'View Devices',
            },
          ]}
        />
      </div>
    );
  };

  private renderDeleteModal() {
    const { deleteProgress, orgsById, startDelete, endDelete } = this.props;
    const { deleteOrgPromptId, deleteOrgInputValue } = this.state;

    if (deleteOrgPromptId == null || orgsById[deleteOrgPromptId] == null) {
      return null;
    }

    const org = orgsById[deleteOrgPromptId];

    const deleteDone =
      deleteProgress != null &&
      deleteProgress.licensesResult != null &&
      deleteProgress.schedulesResult != null &&
      deleteProgress.broadcastsResult != null &&
      deleteProgress.usersResult != null &&
      deleteProgress.profilesResult != null &&
      deleteProgress.orgResult != null;

    return (
      <Modal
        onClose={
          deleteProgress.id == null
            ? this.closeDeleteOrg
            : deleteDone &&
              (() => {
                endDelete();
                this.closeDeleteOrg();
              })
        }
        style={StyleUtil.styles.modals.newPadding}
      >
        {deleteProgress.id == null ? (
          <div>
            <h1>
              <Icon name="warning" style={{ color: StyleUtil.colors.red }} />
              <span>Are you sure?</span>
            </h1>
            <p>
              You are about to wipe the org: <em>{org.orgName}</em>
            </p>
            <p>
              This will:
              <ol className={classes.deleteList}>
                <li>
                  Delete all <em>schedules</em> associated with this org
                </li>
                <li>
                  Delete all <em>broadcasts</em> associated with this org
                </li>
                <li>
                  Delete all <em>licenses</em> associated with this org
                </li>
                <li>
                  Delete all <em>users</em> (and their <em>providers</em>) associated with this org
                </li>
                <li>
                  Delete all <em>profiles</em> associated with this org
                </li>
                <li>
                  Change the org&apos;s Tenant ID to a random GUID and add &ldquo;-- WIPED&rdquo; to the end of its name
                </li>
              </ol>
            </p>
            <p>If you are sure, copy the org&apos;s name into the textbox below and hit the scary button.</p>
            <TextField
              placeholder="Put org name here if you are sure"
              value={deleteOrgInputValue}
              onChange={({ currentTarget: { value } }) => this.setState({ deleteOrgInputValue: value })}
            />
            <br />
            <Button
              type="primary"
              disabled={deleteOrgInputValue !== org.orgName}
              iconName="trash"
              onClick={() => startDelete(deleteOrgPromptId)}
            >
              I WANT TO PERMANENTLY WIPE THIS ORG
            </Button>
          </div>
        ) : (
          <div>
            <h1>
              {deleteDone ? <Icon name="checkmark" /> : <Spinner />}
              <span>{deleteDone ? 'Finished' : 'Wiping...'}</span>
            </h1>
            <p>
              <ol className={classes.deleteList}>
                <li>
                  Deleting Schedules:
                  {deleteProgress.schedulesResult == null ? (
                    <Spinner />
                  ) : (
                    <Icon name="checkmark" style={{ color: StyleUtil.colors.green }} />
                  )}
                </li>
                <li>
                  Deleting Broadcasts:
                  {deleteProgress.broadcastsResult == null ? (
                    <Spinner />
                  ) : (
                    <Icon name="checkmark" style={{ color: StyleUtil.colors.green }} />
                  )}
                </li>
                <li>
                  Deleting Licenses:
                  {deleteProgress.licensesResult == null ? (
                    <Spinner />
                  ) : (
                    <Icon name="checkmark" style={{ color: StyleUtil.colors.green }} />
                  )}
                </li>
                <li>
                  Deleting Users:
                  {deleteProgress.usersResult == null ? (
                    <Spinner />
                  ) : (
                    <Icon name="checkmark" style={{ color: StyleUtil.colors.green }} />
                  )}
                </li>
                <li>
                  Deleting Profiles:
                  {deleteProgress.profilesResult == null ? (
                    <Spinner />
                  ) : (
                    <Icon name="checkmark" style={{ color: StyleUtil.colors.green }} />
                  )}
                </li>
                <li>
                  Updating Org:
                  {deleteProgress.orgResult == null ? (
                    <Spinner />
                  ) : (
                    <Icon name="checkmark" style={{ color: StyleUtil.colors.green }} />
                  )}
                </li>
              </ol>
            </p>
            <br />
            <Button
              type="primary"
              disabled={!deleteDone}
              onClick={() => {
                endDelete();
                this.closeDeleteOrg();
              }}
              iconName={deleteDone ? 'checkmark' : null}
            >
              OK
            </Button>
          </div>
        )}
      </Modal>
    );
  }

  private renderActiveModal() {
    const { dirtyOrg, saveOrg, isSavingById, endEdit, editOrg } = this.props;

    if (dirtyOrg == null) {
      return null;
    }

    const isSaving = isSavingById[dirtyOrg.id];

    return (
      <OrgEditModal dirtyOrg={dirtyOrg} onCancel={endEdit} onEdit={editOrg} onSave={saveOrg} isSaving={isSaving} />
    );

    // return (
    //   <Modal onClose={this.props.endEdit}>
    //     <HyperForm
    //       fields={[
    //         fields.text({
    //           label: "Name",
    //           value: dirtyOrg.orgName,
    //           onChange: e =>
    //             this.props.editOrg("orgName", e.currentTarget.value),
    //           required: true
    //         }),
    //         fields.text({
    //           label: "ID",
    //           value: dirtyOrg.id,
    //           readOnly: true
    //         }),
    //         fields.dropdown({
    //           label: "Type",
    //           value: dirtyOrg.type,
    //           required: true,
    //           onChange: o => this.props.editOrg("type", o.value as Org["type"]),
    //           dropdownOptions: [
    //             { label: "Online", value: "Online" },
    //             { label: "Hybrid", value: "Hybrid" }
    //           ]
    //         }),
    //         fields.text({
    //           label: "Hubspot Company ID",
    //           value: this.getHubspotIdFromMeta(dirtyOrg) || '',
    //           onChange: e => {
    //             const curMeta = JSON.parse(dirtyOrg.meta) || {};
    //             curMeta.hubspotCompanyId = parseInt(e.currentTarget.value);
    //             return this.props.editOrg("meta", JSON.stringify(curMeta));
    //           },
    //           inputType: "number"
    //         }),
    //         fields.text({
    //           label: "Created At",
    //           value: dirtyOrg.createdAt,
    //           readOnly: true
    //         }),
    //         fields.text({
    //           label: "Tenant ID",
    //           value: dirtyOrg.tenantId,
    //           readOnly: true
    //         })
    //       ]}
    //       onCancel={this.props.endEdit}
    //       saving={isSaving}
    //       onSubmit={e => {
    //         e.preventDefault();
    //         this.props.saveOrg(dirtyOrg);
    //       }}
    //     />
    //   </Modal>
    // );
  }
}

export default connector(Orgs) as typeof Orgs;
