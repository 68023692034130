import * as React from 'react';
import { Modal, StyleUtil, HyperForm, HyperFieldFactory as fields, Button } from '@hyperfish/fishfood';
import { Org, Error, Field } from '../../models';
import { setCollectionByAttribute } from '../../store/Orgs';

const attributesNotEligibleForCollections = [
  'id',
  'mail',
  'mobilePhone',
  'userPrincipalName',
  'profilePicture',
  'manager',
  'userprincipalname',
  'mobile',
  'objectguid',
];

interface Props {
  onClose: () => void;
  org: Org;
  fieldsError: Error;
  orgFields: Field[];
  fieldsLoading: boolean;
  collectionByAttributePromptFieldValue: string;
  collectionByAttributeSaving: boolean;
  setCollectionByAttribute: typeof setCollectionByAttribute;
  collectionByAttributeError: Error;
  onFieldSelect: (property: string) => void;
  currentCollectionsField?: string;
}

export const OrgCollectionModal = ({
  onClose,
  org,
  fieldsError,
  orgFields,
  fieldsLoading,
  collectionByAttributePromptFieldValue,
  onFieldSelect,
  collectionByAttributeSaving,
  setCollectionByAttribute,
  collectionByAttributeError,
  currentCollectionsField,
}: Props) => {
  if (currentCollectionsField) {
    return (
      <Modal onClose={onClose} style={StyleUtil.styles.modals.newPadding}>
        <h1>Collections by Attribute</h1>
        <p>
          Collections by attribute for <em>{org.orgName}</em>
        </p>
        <p>
          Attribute field is: <code>{currentCollectionsField}</code>
        </p>
        <Button onClick={() => setCollectionByAttribute(org.id, currentCollectionsField, false)}>Disable</Button>
        {collectionByAttributeError && (
          <p style={{ color: StyleUtil.colors.red }}>Failed to disable collections by attribute</p>
        )}
      </Modal>
    );
  } else {
    return (
      <Modal onClose={onClose} style={StyleUtil.styles.modals.newPadding}>
        <h1>Collections by Attribute</h1>
        <p>
          Enable collections by attribute for <em>{org.orgName}</em>?
        </p>
        <p style={{ color: StyleUtil.colors.orange }}>
          This action will ensure that the selected attribute is treated as a list, which means that Directory will
          modify the attribute configuration if necessary.
        </p>
        <HyperForm
          fields={[
            fields.autocomplete({
              label: 'Field',
              value: collectionByAttributePromptFieldValue,
              required: true,
              validationText: fieldsError ? 'Failed to load fields for org' : null,
              onChange: val => onFieldSelect(val ? (val.value as string) : null),
              dropdownOptions:
                orgFields &&
                orgFields
                  .filter(f => f.resource === (org.type === 'Online' ? 'graphuser' : 'aduser'))
                  .filter(f => !attributesNotEligibleForCollections.includes(f.field1))
                  .map(({ field1 }) => ({ label: field1, value: field1 })),
              props: {
                isLoading: fieldsLoading,
              },
            }),
          ]}
          onCancel={onClose}
          saving={collectionByAttributeSaving}
          onSubmit={e => {
            e.preventDefault();
            setCollectionByAttribute(org.id, collectionByAttributePromptFieldValue);
          }}
        />
        {collectionByAttributeError && (
          <p style={{ color: StyleUtil.colors.red }}>Failed to enable collections by attribute</p>
        )}
      </Modal>
    );
  }
};
