import { history } from '../';
import { MsalInstance, LoginRequest } from './MSAL';
import { AuthResponse } from 'msal';
import ApiClient from './Api';

const ACCESS_TOKEN = 'access_token';
const ID_TOKEN = 'id_token';
const PROFILE = 'profile';
const EXPIRES_AT = 'expires_at';
const SCOPES = 'scopes';
const LAST_ERROR = 'last_error';

interface Profile {
  name: string;
  email: string;
  aadId: string;
}

type SupportedScopes =
  | 'read'
  | 'read-emails'
  | 'create-license'
  | 'manage-devices'
  | 'impersonate-user'
  | 'edit-org'
  | 'delete-org'
  | 'developer'
  | 'god';

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
  }

  private setSession(authResult: AuthResponse) {
    localStorage.setItem(ID_TOKEN, authResult.idToken.rawIdToken);
    localStorage.setItem(EXPIRES_AT, JSON.stringify(authResult.expiresOn.getTime()));
    localStorage.setItem(
      PROFILE,
      JSON.stringify({
        name: authResult.account.name,
        email: authResult.account.userName,
        aadId: authResult.account.accountIdentifier,
      } as Profile),
    );
    // localStorage.setItem(SCOPES, '');
    sessionStorage.setItem(LAST_ERROR, '');
  }

  get lastAuthenticationError() {
    return sessionStorage.getItem(LAST_ERROR);
  }

  public async login() {
    const loginResponse = await MsalInstance.loginPopup(LoginRequest);
    if (loginResponse) {
      // Fetch scopes from backend
      try {
        const api = new ApiClient({
          getAuthorizationHeader: () => {
            return { Authorization: `Bearer ${loginResponse.idToken.rawIdToken}` };
          },
        });
        await api.get('/api/authinterop/').forEach(res => {
          if (res.response) {
            this.setSession(loginResponse);
            localStorage.setItem(SCOPES, res.response.scopes);
            sessionStorage.setItem(LAST_ERROR, '');
          }
        });
      } catch (err) {
        console.error('Error while fetching scopes from server', err);
        if (err.status === 401) {
          sessionStorage.setItem(LAST_ERROR, 'Login error: User not found or not authorized');
        } else {
          sessionStorage.setItem(LAST_ERROR, 'Login error: ' + err.message);
        }
        return this.logout();
      }

      // we don't really handle inline logins yet
      history.replace('/');
    }
  }

  public logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(ID_TOKEN);
    localStorage.removeItem(PROFILE);
    localStorage.removeItem(EXPIRES_AT);
    localStorage.removeItem(SCOPES);

    // Navigate to the home route
    history.replace('/');
  }

  public isAuthenticated() {
    // Check whether the current time is past the access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem(EXPIRES_AT));
    return new Date().getTime() < expiresAt;
  }

  public getToken() {
    if (!this.isAuthenticated()) {
      this.logout();
      return null;
    }

    return localStorage.getItem(ID_TOKEN);
  }

  public getAuthorizationHeader() {
    return { Authorization: `Bearer ${this.getToken()}` };
  }

  public getPermissions(): SupportedScopes[] {
    const scopes = localStorage.getItem(SCOPES);
    if (!scopes) {
      return [];
    }

    const splitScopes = scopes.split(' ');

    return splitScopes as SupportedScopes[];
  }

  public getProfile(): Profile {
    const p = localStorage.getItem(PROFILE);
    if (!p) {
      return null;
    }

    return JSON.parse(p);
  }

  public static doesUserHaveScope(...scopes: SupportedScopes[]): boolean {
    const auth = new Auth();
    const currentScopes = auth.getPermissions();

    return !!scopes.find(v => currentScopes.includes(v));
  }
}
