import { combineEpics } from 'redux-observable';

import { State as ApplicationState } from '../models';
import audits, { auditsEpics } from './Audits';
import devices, { devicesEpics } from './Devices';
import emails, { emailsEpics } from './Emails';
import jobs, { jobsEpics } from './Jobs';
import kubeCerts, { kubeCertsEpics } from './KubernetesCertificates';
import licenses, { licensesEpics } from './Licenses';
import links, { linksEpics } from './Links';
import orgs, { orgsEpics } from './Orgs';
import providers, { providersEpics } from './Providers';
import reports, { reportsEpics } from './Reports';
import system, { systemEpics } from './System';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  audits,
  devices,
  emails,
  licenses,
  links,
  orgs,
  providers,
  reports,
  jobs,
  kubeCerts,
  system,
};

export const rootEpic = combineEpics(
  ...auditsEpics,
  ...devicesEpics,
  ...emailsEpics,
  ...linksEpics,
  ...orgsEpics,
  ...providersEpics,
  ...reportsEpics,
  ...jobsEpics,
  ...licensesEpics,
  ...kubeCertsEpics,
  ...systemEpics,
);

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
