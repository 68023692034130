import 'core-js';
import 'rxjs';
import 'react-hot-loader';

import Radium from 'radium';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import './assets/styles/reset.css';
import '@hyperfish/fishfood/lib/styles.css';

import { FishFail } from './components/FishFail';
import configureStore from './configureStore';
import { State } from './models';
import Root from './root';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
export const history = createBrowserHistory({ basename: baseUrl });

const dest = document.getElementById('root');
const initialState = (window as any).intialReduxState as State;
const store = configureStore(history, initialState);

ReactDOM.render(
  <FishFail>
    <Radium.StyleRoot>
      <Provider store={store}>
        <Root history={history} />
      </Provider>
    </Radium.StyleRoot>
  </FishFail>,
  dest,
);

// enable debugger
if (process.env.NODE_ENV !== 'production') {
  window['react'] = React;
}
