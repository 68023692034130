export const FETCH_DEVICES = 'FETCH_DEVICES' as 'FETCH_DEVICES';
export const FETCH_DEVICES_SUCCESS = 'FETCH_DEVICES_SUCCESS' as 'FETCH_DEVICES_SUCCESS';
export const FETCH_DEVICES_FAIL = 'FETCH_DEVICES_FAIL' as 'FETCH_DEVICES_FAIL';
export const FETCH_DEVICES_CANCEL = 'FETCH_DEVICES_CANCEL' as 'FETCH_DEVICES_CANCEL';

export const FETCH_LOGS = 'FETCH_LOGS' as 'FETCH_LOGS';
export const FETCH_LOGS_SUCCESS = 'FETCH_LOGS_SUCCESS' as 'FETCH_LOGS_SUCCESS';
export const FETCH_LOGS_FAIL = 'FETCH_LOGS_FAIL' as 'FETCH_LOGS_FAIL';
export const FETCH_LOGS_CANCEL = 'FETCH_LOGS_CANCEL' as 'FETCH_LOGS_CANCEL';
export const CLEAR_LOGS = 'CLEAR_LOGS' as 'CLEAR_LOGS';

export const FETCH_DEVICE_VERSIONS = 'FETCH_DEVICE_VERSIONS' as 'FETCH_DEVICE_VERSIONS';
export const FETCH_DEVICE_VERSIONS_SUCCESS = 'FETCH_DEVICE_VERSIONS_SUCCESS' as 'FETCH_DEVICE_VERSIONS_SUCCESS';
export const FETCH_DEVICE_VERSIONS_FAIL = 'FETCH_DEVICE_VERSIONS_FAIL' as 'FETCH_DEVICE_VERSIONS_FAIL';
export const FETCH_DEVICE_VERSIONS_CANCEL = 'FETCH_DEVICE_VERSIONS_CANCEL' as 'FETCH_DEVICE_VERSIONS_CANCEL';

export const SET_VERSION = 'SET_VERSION' as 'SET_VERSION';
export const SET_VERSION_SUCCESS = 'SET_VERSION_SUCCESS' as 'SET_VERSION_SUCCESS';
export const SET_VERSION_FAIL = 'SET_VERSION_FAIL' as 'SET_VERSION_FAIL';
export const SET_VERSION_CANCEL = 'SET_VERSION_CANCEL' as 'SET_VERSION_CANCEL';

export const SET_LEVEL = 'SET_LEVEL' as 'SET_LEVEL';
export const SET_LEVEL_SUCCESS = 'SET_LEVEL_SUCCESS' as 'SET_LEVEL_SUCCESS';
export const SET_LEVEL_FAIL = 'SET_LEVEL_FAIL' as 'SET_LEVEL_FAIL';
export const SET_LEVEL_CANCEL = 'SET_LEVEL_CANCEL' as 'SET_LEVEL_CANCEL';

export const SET_ENABLED = 'SET_ENABLED' as 'SET_ENABLED';
export const SET_ENABLED_SUCCESS = 'SET_ENABLED_SUCCESS' as 'SET_ENABLED_SUCCESS';
export const SET_ENABLED_FAIL = 'SET_ENABLED_FAIL' as 'SET_ENABLED_FAIL';
export const SET_ENABLED_CANCEL = 'SET_ENABLED_CANCEL' as 'SET_ENABLED_CANCEL';
