export const FETCH_LICENSE = 'FETCH_LICENSE' as 'FETCH_LICENSE';
export const FETCH_LICENSE_SUCCESS = 'FETCH_LICENSE_SUCCESS' as 'FETCH_LICENSE_SUCCESS';
export const FETCH_LICENSE_FAIL = 'FETCH_LICENSE_FAIL' as 'FETCH_LICENSE_FAIL';
export const FETCH_LICENSE_CANCEL = 'FETCH_LICENSE_CANCEL' as 'FETCH_LICENSE_CANCEL';

export const SAVE_LICENSE = 'SAVE_LICENSE' as 'SAVE_LICENSE';
export const SAVE_LICENSE_SUCCESS = 'SAVE_LICENSE_SUCCESS' as 'SAVE_LICENSE_SUCCESS';
export const SAVE_LICENSE_FAIL = 'SAVE_LICENSE_FAIL' as 'SAVE_LICENSE_FAIL';
export const SAVE_LICENSE_CANCEL = 'SAVE_LICENSE_CANCEL' as 'SAVE_LICENSE_CANCEL';

export const CREATE_LICENSE = 'CREATE_LICENSE' as 'CREATE_LICENSE';
export const CREATE_LICENSE_SUCCESS = 'CREATE_LICENSE_SUCCESS' as 'CREATE_LICENSE_SUCCESS';
export const CREATE_LICENSE_FAIL = 'CREATE_LICENSE_FAIL' as 'CREATE_LICENSE_FAIL';
export const CREATE_LICENSE_CANCEL = 'CREATE_LICENSE_CANCEL' as 'CREATE_LICENSE_CANCEL';
