import { StyleUtil } from '@hyperfish/fishfood';
import cx from 'classnames';
import * as React from 'react';

import classes from './styles.module.scss';

interface Tab {
  label: React.ReactNode;
  key: string;
}

interface Props {
  tabs: Tab[];
  onChange: (key: string) => void;
  activeKey: string;
  className?: string;
}

export const Tabs = ({ tabs, onChange, activeKey, className }: Props) => (
  <div className={cx(classes.container, className)}>
    {tabs &&
      tabs.map(({ label, key }, i, a) => (
        <button
          key={key}
          className={cx(classes.tab, { [classes.active]: activeKey === key })}
          onClick={() => onChange(key)}
        >
          {label}
        </button>
      ))}
  </div>
);
