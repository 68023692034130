export function mapArrayToObject<T extends { id: string }>(array: T[]): { [id: string]: T } {
  const o = {};
  if (!array) {
    return o;
  }

  for (const a of array) {
    o[a.id] = a;
  }

  return o;
}
