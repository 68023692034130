import moment from 'moment';

export type LicenseFeature =
  | 'PaidLicense'
  | 'AllowAnalyzeMode'
  | 'AllowPilotMode'
  | 'AllowRunMode'
  | 'AllowDirectory'
  | 'AllowProfileValidation'
  | 'AllowCollections'
  | 'AllowReadOnlyModeSwitch';
export type LicenseType = 'Hyperfish Basic' | 'Hyperfish Free';

export interface License {
  antreaOrgId: string;
  antreaUserId: string;
  createdAt: string;
  features: LicenseFeature[];
  id: string;
  licensedUsers?: number;
  type: LicenseType;
  validityEnd?: string;
  validityStart?: string;
  startingModeValue: string;
}

export function GetInitialLicense(orgId?: string): License {
  return ({
    type: 'Hyperfish Basic',
    antreaOrgId: orgId,
    validityStart: moment().toISOString(),
    validityEnd: moment()
      .add(1, 'years')
      .toISOString(),
    features: [
      'PaidLicense',
      'AllowAnalyzeMode',
      'AllowPilotMode',
      'AllowRunMode',
      'AllowDirectory',
      'AllowProfileValidation',
      'AllowCollections',
      'AllowReadOnlyModeSwitch',
    ],
    startingModeValue: 'readOnlyModeDisabled',
  } as Partial<License>) as License;
}

export function mapLicenseToName(license: { type: LicenseType; features: LicenseFeature[] }) {
  if (!license) {
    return undefined;
  }

  switch (license.type) {
    case 'Hyperfish Basic':
      if (!license.features || license.features.length < 1) {
        return 'Basic, Invalid';
      }

      if (license.features.includes('PaidLicense')) {
        return 'Basic, Paid';
      }

      return 'Basic, Trial';
    case 'Hyperfish Free':
      if (!license.features || license.features.length < 1) {
        return 'Free, Invalid';
      }

      if (license.features.includes('PaidLicense')) {
        return 'Lite, Paid';
      }

      return 'Free';
    default:
      return 'Invalid!';
  }
}

export const FeatureMap = {
  PaidLicense: 'Paid license',
  AllowAnalyzeMode: 'Allow Analyze mode',
  AllowPilotMode: 'Allow Pilot mode',
  AllowRunMode: 'Allow Run mode',
  AllowDirectory: 'Allow Directory webparts',
  AllowProfileValidation: 'Allow Profile Validation feature',
  AllowCollections: 'Allow Collections feature',
  AllowReadOnlyModeSwitch: 'Allow Read-only mode switch',
};

export function mapFeatureToName(feature: LicenseFeature) {
  if (FeatureMap[feature]) {
    return FeatureMap[feature];
  } else {
    return feature;
  }
}

export function getLicenseTimeRemaining(license: { validityStart?: string; validityEnd?: string }) {
  const startDate = moment(license.validityStart, moment.ISO_8601);
  const endDate = moment(license.validityEnd, moment.ISO_8601);
  let timeRemaining: string;
  if (license.validityEnd) {
    if (!license.validityStart || moment().diff(startDate) > 0) {
      const timeRemainingDuration = moment.duration(endDate.diff(moment()));
      const dayCount = Math.floor(timeRemainingDuration.as('days'));
      if (dayCount > 0) {
        timeRemaining = `${dayCount} days`;
      } else {
        timeRemaining = 'Expired';
      }
    } else {
      timeRemaining = 'Unstarted';
    }
  } else {
    timeRemaining = 'Infinite';
  }

  return timeRemaining;
}

type CombinedLicense = Omit<License, 'id' | 'createdAt' | 'validityStart' | 'startingModeValue'> & {
  softName: string;
  timeRemaining: string;
};

export function getCombinedOrgLicense(licenses: License[]): CombinedLicense {
  if (!licenses || licenses.length === 0) {
    return null;
  }

  const liveLicenses = licenses
    .filter(f => !f.validityStart || moment().diff(moment(f.validityStart, moment.ISO_8601)) > 0)
    .filter(f => !f.validityEnd || moment().diff(moment(f.validityEnd, moment.ISO_8601)) < 0);

  if (liveLicenses.length < 1) {
    return null;
  }

  const hasBasicLicense = !!liveLicenses.find(f => f.type === 'Hyperfish Basic');
  const licenseType: LicenseType = hasBasicLicense ? 'Hyperfish Basic' : 'Hyperfish Free';

  let maxLicensedUsers = 0;
  const hasInfiniteUsers = liveLicenses.find(m => !m.licensedUsers);
  if (!hasInfiniteUsers) {
    maxLicensedUsers = liveLicenses
      .map(m => (m.licensedUsers > 0 ? m.licensedUsers : 0))
      .reduce((p, c) => (c > p ? c : p), 0);
  }

  const allFeatures = Array.from(
    new Set(
      liveLicenses
        .map(m => m.features)
        .reduce((p, c) => {
          if (c && Array.isArray(c)) {
            p.push(...c);
          }
          return p;
        }, []),
    ),
  );

  let oldestEnd: string = null;
  const hasInfiniteEnd = liveLicenses.find(m => !m.validityEnd);
  if (!hasInfiniteEnd) {
    const sortedLicenseExpirations = liveLicenses
      .filter(m => !!m.validityEnd)
      .map(m => moment(m.validityEnd))
      .sort((a, b) => a.valueOf() - b.valueOf())
      .reverse();
    oldestEnd = sortedLicenseExpirations.length > 0 ? sortedLicenseExpirations[0].toISOString() : null;
  }

  return {
    antreaOrgId: liveLicenses[0].antreaOrgId,
    antreaUserId: liveLicenses[0].antreaUserId,
    type: (hasBasicLicense ? 'Hyperfish Basic' : 'Hyperfish Free') as LicenseType,
    licensedUsers: maxLicensedUsers > 0 ? maxLicensedUsers : null,
    features: allFeatures,
    validityEnd: oldestEnd,
    softName: mapLicenseToName({ type: licenseType, features: allFeatures }),
    timeRemaining: getLicenseTimeRemaining({ validityEnd: oldestEnd }),
  };
}
