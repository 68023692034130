import { Card } from '@hyperfish/fishfood';
import * as React from 'react';

import Linklist from '../../components/Linklist';

export class Audits extends React.Component {
  public render() {
    return (
      <Card>
        <h1>What audits would you like to see?</h1>
        <Linklist
          links={[
            { path: '/audits/all', label: 'All Audits' },
            { path: '/audits/current', label: 'Currently Running Audits' },
            { path: '/audits/failed', label: 'Failed Audits' },
          ]}
        />
      </Card>
    );
  }
}
