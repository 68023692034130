import { Modal, HyperForm, HyperFieldFactory as fields, StyleUtil } from '@hyperfish/fishfood';
import * as React from 'react';

import { AllRegionsOptions, AllScopesOptions, AuthInteropTableRow } from '../../models/api/AuthInterop';

interface Props {
  dirtyUser: AuthInteropTableRow;
  onSave: (dirtyUser: AuthInteropTableRow) => void;
  onEdit: (property: string, value: any) => void;
  onCancel: () => void;
  isEditing: boolean;
  isSaving: boolean;
}

const UserTypeOptions = ['Support', 'CSM', 'Product', 'Other'];

export const AuthInteropEditModal = ({ onSave, onEdit, onCancel, isSaving, isEditing, dirtyUser }: Props) => {
  if (!dirtyUser) {
    return null;
  }

  return (
    <Modal onClose={onCancel}>
      <h1 style={StyleUtil.styles.modals.header}>{isEditing ? 'Editing' : 'Adding'} user</h1>
      <HyperForm
        fields={[
          fields.text({
            label: 'Username',
            value: dirtyUser.username,
            required: true,
            readOnly: isEditing,
            onChange: o => onEdit('username', o.currentTarget.value),
            hintText: 'Livetiles email address without the domain',
          }),
          fields.dropdown({
            label: 'Region',
            value: dirtyUser.region,
            required: true,
            readOnly: isEditing,
            onChange: o => onEdit('region', o.value),
            dropdownOptions: AllRegionsOptions,
            hintText: 'Region for these permissions. Create a new row for other regions',
          }),
          fields.autocomplete({
            label: 'User type',
            value: dirtyUser.userCategory,
            required: true,
            onChange: o => onEdit('userCategory', o.value),
            dropdownOptions: UserTypeOptions.map(m => ({ label: m, value: m })),
            hintText: 'User type only used for list categorization',
          }),
          fields.dropdownMulti({
            label: 'Permissions',
            value: dirtyUser.scopeList,
            required: true,
            onChange: o =>
              onEdit(
                'scopeList',
                o.map(m => m.value),
              ),
            dropdownOptions: AllScopesOptions,
          }),
        ]}
        onCancel={onCancel}
        saving={isSaving}
        disableSave={isSaving}
        onSubmit={e => {
          e.preventDefault();
          onSave(dirtyUser);
        }}
      />
    </Modal>
  );
};
