import { StyleUtil } from '@hyperfish/fishfood';
import * as React from 'react';

import { ContentFrame } from '../ContentFrame';

interface Props {
  templatePreview: {
    headers: {
      [header: string]: string;
    };
    body: string;
  };
}

const S = StyleUtil({
  templatePreview: {
    backgroundColor: '#fff',
    border: '1px solid #d4d4d4',
    marginTop: 20,
    padding: 11,
  },
  templatePreviewHeaders: {
    fontSize: 10,
    marginBottom: 11,
    color: '#39393a',
  },
  templatePreviewHeaderLabel: {
    fontWeight: 700,
    lineHeight: 1,
    padding: '4px 5px 4px 0',
    textTransform: 'capitalize',
  },
  templatePreviewHeaderValue: {},
  templatePreviewFrame: {
    width: '100%',
    height: 500,
    borderTop: '1px solid #d4d4d4',
  },
});

export const EmailPreview = ({ templatePreview }: Props) => {
  if (!templatePreview) {
    return;
  }

  const headerOrder = ['to', 'fromname', 'from', 'replyto', 'subject'];

  const prettyNameMap = {
    fromname: 'From Name',
    replyto: 'Reply To',
  };

  const headers = headerOrder
    .filter(key => !!templatePreview.headers[key])
    .concat(Object.keys(templatePreview.headers).filter(key => headerOrder.indexOf(key) === -1));

  return (
    <div style={S.templatePreview}>
      <table style={S.templatePreviewHeaders}>
        <tbody>
          {headers.map(key => (
            <tr key={key}>
              <td style={S.templatePreviewHeaderLabel}>{prettyNameMap[key] || key}:</td>
              <td style={S.templatePreviewHeaderValue}>{templatePreview.headers[key]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ContentFrame style={S.templatePreviewFrame} content={templatePreview.body} />
    </div>
  );
};
