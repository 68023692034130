import * as Msal from 'msal';

const msalConfig: Msal.Configuration = {
  auth: {
    clientId: 'bd94e1d7-c0e4-47f4-9dbb-c03be578c314',
    authority: 'https://login.microsoftonline.com/9b74026c-bf52-4d2b-bc80-5e7e6d0570f8',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const LoginRequest: Msal.AuthenticationParameters = {
  scopes: ['openid', 'profile', 'User.Read'],
  redirectUri: window.location.origin,
};

export const MsalInstance = new Msal.UserAgentApplication(msalConfig);
