import * as React from 'react';
import { connect } from 'react-redux';

const connector = connect(state => ({}), {});

type Props = typeof connector['props'];

export class Users extends React.Component<Props> {
  render() {
    return <div />;
  }
}
