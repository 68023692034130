export const FETCH_AUDITS = 'FETCH_AUDITS' as 'FETCH_AUDITS';
export const FETCH_AUDITS_SUCCESS = 'FETCH_AUDITS_SUCCESS' as 'FETCH_AUDITS_SUCCESS';
export const FETCH_AUDITS_FAIL = 'FETCH_AUDITS_FAIL' as 'FETCH_AUDITS_FAIL';
export const FETCH_AUDITS_CANCEL = 'FETCH_AUDITS_CANCEL' as 'FETCH_AUDITS_CANCEL';
export const CLEAR_AUDITS = 'CLEAR_AUDITS' as 'CLEAR_AUDITS';

export const FETCH_LATEST_AUDIT = 'FETCH_LATEST_AUDIT' as 'FETCH_LATEST_AUDIT';
export const FETCH_LATEST_AUDIT_SUCCESS = 'FETCH_LATEST_AUDIT_SUCCESS' as 'FETCH_LATEST_AUDIT_SUCCESS';
export const FETCH_LATEST_AUDIT_FAIL = 'FETCH_LATEST_AUDIT_FAIL' as 'FETCH_LATEST_AUDIT_FAIL';
export const FETCH_LATEST_AUDIT_CANCEL = 'FETCH_LATEST_AUDIT_CANCEL' as 'FETCH_LATEST_AUDIT_CANCEL';

export const FETCH_AUDITS_PAGE = 'FETCH_AUDITS_PAGE' as 'FETCH_AUDITS_PAGE';
