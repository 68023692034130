export const FETCH_CERTS = 'FETCH_CERTS' as 'FETCH_CERTS';
export const FETCH_CERTS_SUCCESS = 'FETCH_CERTS_SUCCESS' as 'FETCH_CERTS_SUCCESS';
export const FETCH_CERTS_FAIL = 'FETCH_CERTS_FAIL' as 'FETCH_CERTS_FAIL';
export const FETCH_CERTS_CANCEL = 'FETCH_CERTS_CANCEL' as 'FETCH_CERTS_CANCEL';

export const FETCH_CERT = 'FETCH_CERT' as 'FETCH_CERT';
export const FETCH_CERT_SUCCESS = 'FETCH_CERT_SUCCESS' as 'FETCH_CERT_SUCCESS';
export const FETCH_CERT_FAIL = 'FETCH_CERT_FAIL' as 'FETCH_CERT_FAIL';
export const FETCH_CERT_CANCEL = 'FETCH_CERT_CANCEL' as 'FETCH_CERT_CANCEL';

export const SAVE_CERT = 'SAVE_CERT' as 'SAVE_CERT';
export const SAVE_CERT_SUCCESS = 'SAVE_CERT_SUCCESS' as 'SAVE_CERT_SUCCESS';
export const SAVE_CERT_FAIL = 'SAVE_CERT_FAIL' as 'SAVE_CERT_FAIL';
export const SAVE_CERT_CANCEL = 'SAVE_CERT_CANCEL' as 'SAVE_CERT_CANCEL';

export const VALIDATE_CERT = 'VALIDATE_CERT' as 'VALIDATE_CERT';
export const VALIDATE_CERT_SUCCESS = 'VALIDATE_CERT_SUCCESS' as 'VALIDATE_CERT_SUCCESS';
export const VALIDATE_CERT_FAIL = 'VALIDATE_CERT_FAIL' as 'VALIDATE_CERT_FAIL';
export const VALIDATE_CERT_CANCEL = 'VALIDATE_CERT_CANCEL' as 'VALIDATE_CERT_CANCEL';

export const DELETE_CERT = 'DELETE_CERT' as 'DELETE_CERT';
export const DELETE_CERT_SUCCESS = 'DELETE_CERT_SUCCESS' as 'DELETE_CERT_SUCCESS';
export const DELETE_CERT_FAIL = 'DELETE_CERT_FAIL' as 'DELETE_CERT_FAIL';
export const DELETE_CERT_CANCEL = 'DELETE_CERT_CANCEL' as 'DELETE_CERT_CANCEL';
