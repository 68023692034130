import { Icon } from '@hyperfish/fishfood';
import * as React from 'react';
import classes from './styles.module.scss';

export interface Props {
  enabled?: boolean;
  isExporting?: boolean;
  exportData?: ((filter?: any) => void) | (() => void);
  filter?: any;
}

export const DownloadButton = ({ enabled, isExporting, filter, exportData }: Props) => {
  const canClick = !isExporting && enabled;
  return (
    <div className={classes.container}>
      <a
        onClick={() => {
          if (canClick) {
            if (filter) exportData(filter);
            else exportData();
          }
        }}
        className={canClick ? '' : classes.disabled}
        title="Download as CSV"
      >
        {isExporting && <Icon name="loading" />}
        {!isExporting && <Icon name="download" />}
      </a>
    </div>
  );
};
