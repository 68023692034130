import * as React from 'react';
import { Modal, HyperForm, HyperFieldFactory as fields } from '@hyperfish/fishfood';
import { Org } from '../../models';

interface Props {
  onCancel: () => void;
  onSave: (dirtyOrg: Org) => void;
  onEdit: (property: string, value: any) => void;
  isSaving: boolean;
  dirtyOrg: Org;
}

export const OrgEditModal = ({ onCancel, onSave, onEdit, isSaving, dirtyOrg }: Props) => {
  if (dirtyOrg == null) {
    return null;
  }

  return (
    <Modal onClose={onCancel}>
      <HyperForm
        fields={[
          fields.text({
            label: 'Name',
            value: dirtyOrg.orgName,
            onChange: e => onEdit('orgName', e.currentTarget.value),
            required: true,
          }),
          fields.text({
            label: 'ID',
            value: dirtyOrg.id,
            readOnly: true,
          }),
          fields.dropdown({
            label: 'Type',
            value: dirtyOrg.type,
            required: true,
            onChange: o => onEdit('type', o.value as Org['type']),
            dropdownOptions: [
              { label: 'Online', value: 'Online' },
              { label: 'Hybrid', value: 'Hybrid' },
            ],
          }),
          fields.text({
            label: 'Created At',
            value: dirtyOrg.createdAt,
            readOnly: true,
          }),
          fields.text({
            label: 'Tenant ID',
            value: dirtyOrg.tenantId,
            readOnly: true,
          }),
        ]}
        onCancel={onCancel}
        saving={isSaving}
        onSubmit={e => {
          e.preventDefault();
          onSave(dirtyOrg);
        }}
      />
    </Modal>
  );
};
