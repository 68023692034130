import { Button, Card, Checkbox, DataTable, getFrom, Icon, Modal, StyleUtil } from '@hyperfish/fishfood';
import * as queryString from 'query-string';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select, { Option } from 'react-select';

import Linklist from '../../components/Linklist';
import { PreWrapper } from '../../components/PreWrapper';
import { SummaryTable } from '../../components/SummaryTable';
import { fetchDevices, fetchVersions, setEnabled, setLevel, setVersion } from '../../store/Devices';
import Auth from '../../services/Auth';

import classes from './styles.module.scss';
import { DownloadButton } from 'components/DownloadButton/Index';
import { exportToCsv } from 'utils/fileUtil';
import { convertDateToStandardFormat, getDateToFileName } from 'utils/dateUtil';
import { Device } from 'models/api';

const DevicesTable = DataTable.of({
  orgName: { label: 'Org Name', type: 'custom', sortable: true },
  createdAt: { label: 'Created At', type: DataTable.types.datetime, sortable: true },
  heartbeat: { label: 'Last Heartbeat', type: DataTable.types.datetime, sortable: true },
  level: { label: 'Level', center: true, sortable: true },
  version: { label: 'Version', sortable: true },
  enabled: { label: 'Enabled', type: DataTable.types.checkmark, sortable: true },
});

const connector = connect(
  state => ({
    devices: state.devices.deviceIds && state.devices.deviceIds.map(id => state.devices.devicesById[id]),
    devicesById: state.devices.devicesById,
    isDeviceOffline: state.devices.isDeviceOffline,
    isLoading: state.devices.isLoading,
    orgs: state.devices.relatedEntities.orgs,
    settingLevelByDevice: state.devices.settingLevelByDevice,
    settingLevelByDeviceError: state.devices.settingLevelByDeviceError,
    settingVersionByDevice: state.devices.settingVersionByDevice,
    settingVersionByDeviceError: state.devices.settingVersionByDeviceError,
    settingEnabledByDevice: state.devices.settingEnabledByDevice,
    settingEnabledByDeviceError: state.devices.settingEnabledByDeviceError,
    versions: state.devices.versions,
    versionsLoading: state.devices.versionsLoading,
    versionsError: state.devices.versionsError,
  }),
  {
    fetchDevices,
    fetchVersions,
    setLevel,
    setVersion,
    setEnabled,
  },
);

type Props = typeof connector['props'];

interface State {
  selectedDevices: string[];
  filterOrgs: string[];
  filterVersions: string[];
  devicesToUpgrade: string[];
  upgradeVersion: string;
  upgradeShowAllVersions: boolean;
  upgradeSent: boolean;
  logPromptDevice: string;
  logLevel: string;
  showDisabled: boolean;
  disablePromptDevice: string;
  isExporting: boolean;
}

class Devices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const query = queryString.parse(window.location.search) as { orgs: string; showDisabled?: string };

    const orgsParam = query.orgs;
    const filterOrgs = orgsParam ? orgsParam.split(',') : null;
    const showDisabled = Object.prototype.hasOwnProperty.call(query, 'showDisabled') && query.showDisabled !== 'false';

    this.state = {
      selectedDevices: [],
      filterOrgs,
      upgradeVersion: '',
      showDisabled,
      isExporting: false,
    } as State;
  }

  componentDidMount() {
    this.props.fetchDevices();
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { logPromptDevice, disablePromptDevice } = this.state;
    if (
      this.props.settingLevelByDevice[logPromptDevice] &&
      !nextProps.settingLevelByDevice[logPromptDevice] &&
      !nextProps.settingLevelByDeviceError[logPromptDevice]
    ) {
      this.setState({ logPromptDevice: null });
    }

    if (
      this.props.settingEnabledByDevice[disablePromptDevice] &&
      !nextProps.settingEnabledByDevice[disablePromptDevice] &&
      !nextProps.settingEnabledByDeviceError[disablePromptDevice]
    ) {
      this.setState({ disablePromptDevice: null });
    }
  }

  handleSelect: DataTable<any>['props']['onSelect'] = ids => {
    const newIds = ids.filter(id => this.state.selectedDevices.indexOf(id) === -1);
    const selectedDevices = this.state.selectedDevices.concat(newIds);
    this.setState({ selectedDevices });
  };

  handleDeselect: DataTable<any>['props']['onDeselect'] = ids => {
    const selectedDevices = this.state.selectedDevices.filter(id => ids.indexOf(id) === -1);
    this.setState({ selectedDevices });
  };

  filterByOrg: Select['props']['onChange'] = (o: Option<string>[]) =>
    this.setState({ filterOrgs: o && o.length > 0 ? o.map(({ value }) => value) : null });

  filterByVersion: Select['props']['onChange'] = (o: Option<string>[]) =>
    this.setState({ filterVersions: o && o.length > 0 ? o.map(({ value }) => value) : null });

  setDevicesToUpgrade = (devicesToUpgrade: string[]) => {
    this.props.fetchVersions();
    this.setState({ devicesToUpgrade, upgradeSent: false, upgradeVersion: '', upgradeShowAllVersions: false });
  };

  getLevelFromDevice(d: Props['devices'][0]) {
    const levels = getFrom(d)('meta')('heartbeat')('currentLogLevels').value;
    if (!levels) {
      return '';
    }
    const levelMap = Object.keys(levels)
      .map(k => levels[k])
      .reduce((p, c) => {
        p[c] = c;
        return p;
      }, {});
    return Object.keys(levelMap).join(', ');
  }

  resolveDeviceToTable(d: Device) {
    const { orgs } = this.props;

    return {
      id: d.id,
      orgName: <Link to={`/orgs/${d.antreaOrgId}`}>{getFrom(orgs)(d.antreaOrgId)('orgName').value}</Link>,
      createdAt: d.createdAt,
      heartbeat: getFrom(d.meta)('heartbeat')('timestamp').value,
      level: this.getLevelFromDevice(d),
      version: getFrom(d.meta)('heartbeat')('version').value,
      enabled: d.enabled,
    };
  }

  resolveDeviceToCsv(d: Device) {
    const { orgs } = this.props;

    return {
      id: d.antreaOrgId,
      orgName: getFrom(orgs)(d.antreaOrgId)('orgName').value,
      createdAt: convertDateToStandardFormat(d.createdAt),
      heartbeat: convertDateToStandardFormat(getFrom(d.meta)('heartbeat')('timestamp').value),
      level: this.getLevelFromDevice(d),
      version: getFrom(d.meta)('heartbeat')('version').value,
      enabled: d.enabled ? 'Enabled' : 'Disabled',
    };
  }

  getDevicesMapped(isToTable: boolean) {
    const { devices } = this.props;
    return devices
      .filter(d => this.state.filterOrgs == null || this.state.filterOrgs.indexOf(d.antreaOrgId) > -1)
      .filter(d => this.state.showDisabled || d.enabled)
      .map(d => (isToTable ? this.resolveDeviceToTable(d) : this.resolveDeviceToCsv(d)))
      .filter(d => this.state.filterVersions == null || this.state.filterVersions.indexOf(d.version) > -1);
  }

  exportDevicesToCSV = () => {
    this.setState({ isExporting: true });

    const header: {}[] = [
      {
        id: 'Org Id',
        orgName: 'Org Name',
        createdAt: 'Created At',
        heartbeat: 'Last Heartbeat',
        level: 'Level',
        version: 'Version',
        enabled: 'Enabled',
      },
    ];
    const devices = header.concat(this.getDevicesMapped(false));
    exportToCsv('Devices_' + getDateToFileName() + '.csv', devices);

    this.setState({ isExporting: false });
  };

  render() {
    const { devices, isDeviceOffline, isLoading, orgs } = this.props;
    const { selectedDevices, filterVersions, filterOrgs } = this.state;

    const orgFilterOptions =
      !isLoading && devices
        ? [...new Set(devices.map(({ antreaOrgId }) => antreaOrgId))]
            .map(antreaOrgId => orgs[antreaOrgId])
            .filter(org => !!org)
            .map(org => ({ value: org.id, label: org.orgName }))
            .sort((a, b) => a.value.localeCompare(b.value))
        : null;

    if (filterOrgs && orgFilterOptions) {
      for (const id of filterOrgs) {
        if (orgFilterOptions.map(o => o.value).indexOf(id) === -1) {
          orgFilterOptions.push({ value: id, label: id });
        }
      }
    }

    return (
      <div>
        <div style={StyleUtil.styles.tables.header}>
          <a onClick={() => this.setDevicesToUpgrade(selectedDevices.slice())}>
            Upgrade {selectedDevices.length} selected device{selectedDevices.length === 1 ? '' : 's'}
          </a>
          <label className={classes.showDisabled} htmlFor="showDisabled">
            <Checkbox
              checked={this.state.showDisabled}
              id="showDisabled"
              onChange={e => this.setState({ showDisabled: e.currentTarget.checked })}
            />
            <span>Show disabled devices</span>
          </label>
        </div>
        <div style={StyleUtil.styles.tables.header}>
          <Select
            className={classes.select}
            placeholder="Filter by Versions"
            value={filterVersions}
            onChange={this.filterByVersion}
            isLoading={isLoading}
            multi={true}
            options={
              !isLoading && devices
                ? [...new Set(devices.map(d => getFrom(d.meta)('heartbeat')('version').value))]
                    .filter(v => !!v)
                    .map(v => ({ value: v, label: v }))
                    .sort((a, b) => a.value.localeCompare(b.value))
                : []
            }
          />
          <Select
            className={classes.select}
            placeholder="Filter by Orgs"
            value={filterOrgs}
            onChange={this.filterByOrg}
            isLoading={isLoading}
            multi={true}
            options={orgFilterOptions || []}
          />
        </div>
        <Card noPadding={true}>
          <DevicesTable
            loading={isLoading}
            data={devices && this.getDevicesMapped(true)}
            getRowStyle={item => (isDeviceOffline[item.id] ? StyleUtil.styles.tables.tr_invalid : {})}
            selected={selectedDevices}
            onSelect={this.handleSelect}
            onDeselect={this.handleDeselect}
            renderDetail={this.renderDetail}
            actions={[
              {
                icon: 'document',
                onAction: ({ id }) => this.props.history.push(`/devices/${id}`),
                title: 'View Logs',
                disabled: ({ enabled }) => enabled !== true,
              },
            ]}
          />
        </Card>
        <DownloadButton
          enabled={!isLoading && devices && this.getDevicesMapped(false).length > 0}
          exportData={this.exportDevicesToCSV}
          isExporting={this.state.isExporting}
        />
        {this.state.devicesToUpgrade != null && this.renderUpgradeModal()}
        {this.state.logPromptDevice != null && this.renderLogLevelModal()}
        {this.state.disablePromptDevice != null && this.renderDisconnectModal()}
      </div>
    );
  }

  renderDetail = ({ id }) => {
    const device = this.props.devicesById[id];
    const isCsm = Auth.doesUserHaveScope('manage-devices');
    return (
      <div>
        <SummaryTable
          rows={[
            { label: 'Device ID', value: id, isCopyable: true },
            { label: 'Org ID', value: device.antreaOrgId, isCopyable: true },
            // { label: 'Provider ID', value: getFrom(device.meta)('providerId').value, isCopyable: true },
            // { label: 'Domain Controller', value: getFrom(device.meta)('domainController').value, isCopyable: true },
            { label: 'Code', value: getFrom(device.meta)('code').value, isCopyable: true },
            { label: 'Client', value: getFrom(device.meta)('heartbeat')('client').value, isCopyable: true },
            {
              label: 'Current Log Levels',
              value: (
                <PreWrapper>{JSON.stringify(getFrom(device.meta)('heartbeat')('currentLogLevels').value)}</PreWrapper>
              ),
              isCopyable: false,
            },
          ].filter(Boolean)}
        />
        <Linklist
          links={[
            { label: 'View Logs', onClick: () => this.props.history.push(`/devices/${id}`) },
            isCsm && { label: 'Set log level', onClick: () => this.setState({ logPromptDevice: id, logLevel: null }) },
            isCsm && { label: 'Upgrade Version', onClick: () => this.setDevicesToUpgrade([id]) },
            isCsm && {
              label: (
                <span style={{ color: StyleUtil.colors.red }}>
                  <Icon name="link-broken" />
                  <span>Disable Device</span>
                </span>
              ),
              onClick: () => this.setState({ disablePromptDevice: id }),
            },
          ]}
        />
      </div>
    );
  };

  renderUpgradeModal() {
    const { setVersion, settingVersionByDevice, settingVersionByDeviceError, versions, versionsLoading } = this.props;
    const { devicesToUpgrade, upgradeSent, upgradeVersion } = this.state;
    const count = devicesToUpgrade.length;
    const finishedUpgrading = devicesToUpgrade.filter(id => !settingVersionByDevice[id]);
    const errorUpgrading = devicesToUpgrade.filter(id => !!settingVersionByDeviceError[id]);

    return (
      <Modal onClose={() => this.setState({ devicesToUpgrade: null })} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.header}>
          Upgrade {count} device{count !== 1 ? 's' : ''}
        </h1>
        {upgradeSent ? (
          <div>
            <p>
              {finishedUpgrading.length} of {count} upgrade messages sent.
            </p>
            {errorUpgrading.length > 0 && (
              <p style={{ color: StyleUtil.colors.red }}>
                {errorUpgrading.length} message{errorUpgrading.length !== 1 ? 's' : ''} had error on send.
              </p>
            )}
          </div>
        ) : (
          <div>
            <Select
              placeholder="Select Version"
              isLoading={versionsLoading}
              value={upgradeVersion}
              options={versions && versions.map(v => ({ label: v, value: v }))}
              onChange={(o: Option<string>) => this.setState({ upgradeVersion: o ? o.value : null })}
            />
            <br />
            <Checkbox
              id="show-all-versions-checkbox"
              wrapperClass={classes.showVersionsCheck}
              checked={this.state.upgradeShowAllVersions}
              onChange={e => {
                const checked = e.currentTarget.checked;
                this.setState({ upgradeShowAllVersions: checked });
                this.props.fetchVersions(checked);
              }}
            />
            <label htmlFor="show-all-versions-checkbox" className={classes.showVersionsLabel}>
              Show all versions
            </label>
            <br />
            <br />
          </div>
        )}
        <div style={StyleUtil.styles.modals.buttonContainer}>
          <Button
            size="small"
            style={StyleUtil.styles.modals.spacedBtn}
            onClick={() => this.setState({ devicesToUpgrade: null })}
          >
            Cancel
          </Button>
          {upgradeSent && finishedUpgrading.length === count ? (
            <Button size="small" type="primary" onClick={() => this.setState({ devicesToUpgrade: null })}>
              DONE
            </Button>
          ) : (
            <Button
              size="small"
              type="primary"
              disabled={upgradeVersion === '' || upgradeSent}
              iconName={upgradeSent ? 'loading' : null}
              onClick={() => {
                devicesToUpgrade.forEach(id => setVersion(id, upgradeVersion));
                this.setState({ upgradeSent: true });
              }}
            >
              Upgrade
            </Button>
          )}
        </div>
      </Modal>
    );
  }

  renderLogLevelModal() {
    const { settingLevelByDevice, settingLevelByDeviceError, setLevel } = this.props;
    const { logLevel, logPromptDevice } = this.state;
    const isSetting = settingLevelByDevice[logPromptDevice];
    const error = settingLevelByDeviceError[logPromptDevice];

    return (
      <Modal onClose={() => this.setState({ logPromptDevice: null })} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.header}>Set log level</h1>
        <Select
          value={logLevel}
          onChange={(o: Option<string>) => this.setState({ logLevel: o ? o.value : null })}
          options={['ALL', 'DEBUG', 'INFO', 'WARN', 'ERROR', 'FATAL', 'OFF'].map(s => ({ label: s, value: s }))}
        />
        {error && <p style={{ color: StyleUtil.colors.red }}>Error setting log level</p>}
        <br />
        <br />
        <br />
        <div style={StyleUtil.styles.modals.buttonContainer}>
          <Button
            size="small"
            style={StyleUtil.styles.modals.spacedBtn}
            onClick={() => this.setState({ logPromptDevice: null })}
          >
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            disabled={logLevel == null || isSetting}
            iconName={isSetting ? 'loading' : null}
            onClick={() => setLevel(logPromptDevice, logLevel)}
          >
            SEND
          </Button>
        </div>
      </Modal>
    );
  }

  renderDisconnectModal() {
    const { settingEnabledByDevice, setEnabled, devicesById, orgs } = this.props;
    const { disablePromptDevice } = this.state;
    const device = devicesById[disablePromptDevice];
    const org = orgs[device.antreaOrgId];
    const isSetting = settingEnabledByDevice[disablePromptDevice];

    return (
      <Modal onClose={() => this.setState({ disablePromptDevice: null })} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.header}>Disable Device?</h1>
        <p>
          Would you like to disable device ({disablePromptDevice}) for the org {org.orgName}?
        </p>
        <p>
          If you do this to the wrong device you could make us look dumb to a customer and ruin Justin&apos;s day. Click
          carefully.
        </p>
        <div style={StyleUtil.styles.modals.buttonContainer}>
          <Button
            size="small"
            style={StyleUtil.styles.modals.spacedBtn}
            onClick={() => this.setState({ disablePromptDevice: null })}
          >
            Cancel
          </Button>
          <Button
            size="small"
            type="primary"
            disabled={isSetting}
            iconName={isSetting ? 'loading' : 'link-broken'}
            onClick={() => setEnabled(disablePromptDevice, false)}
          >
            DISCONNECT THIS DEVICE
          </Button>
        </div>
      </Modal>
    );
  }
}

export default connector(Devices) as typeof Devices;

// Levels for reference (from log4net):
// ALL
// DEBUG
// INFO
// WARN
// ERROR
// FATAL
// OFF
