export const FETCH_ORGS = 'FETCH_ORGS' as 'FETCH_ORGS';
export const FETCH_ORGS_SUCCESS = 'FETCH_ORGS_SUCCESS' as 'FETCH_ORGS_SUCCESS';
export const FETCH_ORGS_FAIL = 'FETCH_ORGS_FAIL' as 'FETCH_ORGS_FAIL';
export const FETCH_ORGS_CANCEL = 'FETCH_ORGS_CANCEL' as 'FETCH_ORGS_CANCEL';

export const FETCH_ORGS_PAGE = 'FETCH_ORGS_PAGE' as 'FETCH_ORGS_PAGE';

export const FETCH_ORG = 'FETCH_ORG' as 'FETCH_ORG';
export const FETCH_ORG_SUCCESS = 'FETCH_ORG_SUCCESS' as 'FETCH_ORG_SUCCESS';
export const FETCH_ORG_FAIL = 'FETCH_ORG_FAIL' as 'FETCH_ORG_FAIL';
export const FETCH_ORG_CANCEL = 'FETCH_ORG_CANCEL' as 'FETCH_ORG_CANCEL';

export const FETCH_ORG_LICENSES = 'FETCH_ORG_LICENSES' as 'FETCH_ORG_LICENSES';
export const FETCH_ORG_LICENSES_SUCCESS = 'FETCH_ORG_LICENSES_SUCCESS' as 'FETCH_ORG_LICENSES_SUCCESS';
export const FETCH_ORG_LICENSES_FAIL = 'FETCH_ORG_LICENSES_FAIL' as 'FETCH_ORG_LICENSES_FAIL';
export const FETCH_ORG_LICENSES_CANCEL = 'FETCH_ORG_LICENSES_CANCEL' as 'FETCH_ORG_LICENSES_CANCEL';

export const FETCH_ORG_FIELDS = 'FETCH_ORG_FIELDS' as 'FETCH_ORG_FIELDS';
export const FETCH_ORG_FIELDS_SUCCESS = 'FETCH_ORG_FIELDS_SUCCESS' as 'FETCH_ORG_FIELDS_SUCCESS';
export const FETCH_ORG_FIELDS_FAIL = 'FETCH_ORG_FIELDS_FAIL' as 'FETCH_ORG_FIELDS_FAIL';
export const FETCH_ORG_FIELDS_CANCEL = 'FETCH_ORG_FIELDS_CANCEL' as 'FETCH_ORG_FIELDS_CANCEL';

export const SET_ORG_COLLECTION_BY_ATTRIBUTE = 'SET_ORG_COLLECTION_BY_ATTRIBUTE' as 'SET_ORG_COLLECTION_BY_ATTRIBUTE';
export const SET_ORG_COLLECTION_BY_ATTRIBUTE_SUCCESS = 'SET_ORG_COLLECTION_BY_ATTRIBUTE_SUCCESS' as 'SET_ORG_COLLECTION_BY_ATTRIBUTE_SUCCESS';
export const SET_ORG_COLLECTION_BY_ATTRIBUTE_FAIL = 'SET_ORG_COLLECTION_BY_ATTRIBUTE_FAIL' as 'SET_ORG_COLLECTION_BY_ATTRIBUTE_FAIL';
export const SET_ORG_COLLECTION_BY_ATTRIBUTE_CANCEL = 'SET_ORG_COLLECTION_BY_ATTRIBUTE_CANCEL' as 'SET_ORG_COLLECTION_BY_ATTRIBUTE_CANCEL';

export const SAVE_ORG = 'SAVE_ORG' as 'SAVE_ORG';
export const SAVE_ORG_SUCCESS = 'SAVE_ORG_SUCCESS' as 'SAVE_ORG_SUCCESS';
export const SAVE_ORG_FAIL = 'SAVE_ORG_FAIL' as 'SAVE_ORG_FAIL';
export const SAVE_ORG_CANCEL = 'SAVE_ORG_CANCEL' as 'SAVE_ORG_CANCEL';

export const DELETE_ORG_START = 'DELETE_ORG_START' as 'DELETE_ORG_START';
export const DELETE_ORG_PROGRESS = 'DELETE_ORG_PROCESS' as 'DELETE_ORG_PROCESS';
export const DELETE_ORG_END = 'DELETE_ORG_END' as 'DELETE_ORG_END';

export const EDIT_ORG_START = 'EDIT_ORG_START' as 'EDIT_ORG_START';
export const EDIT_ORG_END = 'EDIT_ORG_END' as 'EDIT_ORG_END';
export const EDIT_ORG = 'EDIT_ORG' as 'EDIT_ORG';

export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS' as 'FETCH_PERMISSIONS';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS' as 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAIL = 'FETCH_PERMISSIONS_FAIL' as 'FETCH_PERMISSIONS_FAIL';
export const FETCH_PERMISSIONS_CANCEL = 'FETCH_PERMISSIONS_CANCEL' as 'FETCH_PERMISSIONS_CANCEL';

export const FETCH_ORG_SETTINGS = 'FETCH_ORG_SETTINGS' as 'FETCH_ORG_SETTINGS';
export const FETCH_ORG_SETTINGS_SUCCESS = 'FETCH_ORG_SETTINGS_SUCCESS' as 'FETCH_ORG_SETTINGS_SUCCESS';
export const FETCH_ORG_SETTINGS_FAIL = 'FETCH_ORG_SETTINGS_FAIL' as 'FETCH_ORG_SETTINGS_FAIL';
export const FETCH_ORG_SETTINGS_CANCEL = 'FETCH_ORG_SETTINGS_CANCEL' as 'FETCH_ORG_SETTINGS_CANCEL';

export const SAVE_ORG_SETTINGS = 'SAVE_ORG_SETTINGS' as 'SAVE_ORG_SETTINGS';
export const SAVE_ORG_SETTINGS_SUCCESS = 'SAVE_ORG_SETTINGS_SUCCESS' as 'SAVE_ORG_SETTINGS_SUCCESS';
export const SAVE_ORG_SETTINGS_FAIL = 'SAVE_ORG_SETTINGS_FAIL' as 'SAVE_ORG_SETTINGS_FAIL';
export const SAVE_ORG_SETTINGS_CANCEL = 'SAVE_ORG_SETTINGS_CANCEL' as 'SAVE_ORG_SETTINGS_CANCEL';

export const FETCH_ORG_AUDIENCES = 'FETCH_ORG_AUDIENCES' as 'FETCH_ORG_AUDIENCES';
export const FETCH_ORG_AUDIENCES_SUCCESS = 'FETCH_ORG_AUDIENCES_SUCCESS' as 'FETCH_ORG_AUDIENCES_SUCCESS';
export const FETCH_ORG_AUDIENCES_FAIL = 'FETCH_ORG_AUDIENCES_FAIL' as 'FETCH_ORG_AUDIENCES_FAIL';
export const FETCH_ORG_AUDIENCES_CANCEL = 'FETCH_ORG_AUDIENCES_CANCEL' as 'FETCH_ORG_AUDIENCES_CANCEL';

export const EXPORT_ORGS = 'EXPORT_ORG' as 'EXPORT_ORG';
export const EXPORT_ORGS_SUCCESS = 'EXPORT_ORG_SUCCESS' as 'EXPORT_ORG_SUCCESS';
export const EXPORT_ORGS_FAIL = 'EXPORT_ORG_FAIL' as 'EXPORT_ORG_FAIL';
export const EXPORT_ORGS_CANCEL = 'EXPORT_ORG_CANCEL' as 'EXPORT_ORG_CANCEL';
