import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, flatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { Email, EmailsParams, State } from '../../models';
import { deserializeKeys, serializeKeys } from '../../utils/jsonUtil';
import * as actions from './actions';
import * as types from './types';

type EmailsState = State['emails'];

// helpers
const deserializeEmail = (s: Email) => deserializeKeys(s, ['event', 'meta']);
const serializeEmail = (s: Email) => serializeKeys(s, ['event', 'meta']);

const initialState = {
  emailDetailById: {},
  emailDetailByIdLoading: {},
  emailDetailByIdError: {},
} as EmailsState;

export default function(state: EmailsState = initialState, action: actions.Action): EmailsState {
  switch (action.type) {
    case types.FETCH_EMAILS:
    case types.FETCH_EMAILS_PAGE:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_EMAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        emails: action.payload.items,
        pagination: action.payload.pagination,
        error: null,
      };
    case types.FETCH_EMAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || true,
      };
    case types.FETCH_EMAILS_CANCEL:
    case types.CLEAR_EMAILS:
      return {
        ...state,
        pagination: null,
        emails: null,
        error: null,
        isLoading: false,
      };
    case types.FETCH_EMAIL_DETAIL:
      return {
        ...state,
        emailDetailByIdLoading: {
          ...state.emailDetailByIdLoading,
          [action.payload]: true,
        },
      };
    case types.FETCH_EMAIL_DETAIL_SUCCESS:
      return {
        ...state,
        emailDetailByIdLoading: {
          ...state.emailDetailByIdLoading,
          [action.payload.id]: false,
        },
        emailDetailById: {
          ...state.emailDetailById,
          [action.payload.id]: deserializeEmail(action.payload),
        },
        emailDetailByIdError: {
          ...state.emailDetailByIdError,
          [action.payload.id]: null,
        },
      };
    case types.FETCH_EMAIL_DETAIL_FAIL:
      return {
        ...state,
        emailDetailByIdLoading: {
          ...state.emailDetailByIdLoading,
          [action.id]: false,
        },
        emailDetailByIdError: {
          ...state.emailDetailByIdError,
          [action.id]: action.payload || true,
        },
      };
    case types.FETCH_EMAIL_DETAIL_CANCEL:
      return {
        ...state,
        emailDetailByIdLoading: {
          ...state.emailDetailByIdLoading,
          [action.payload]: false,
        },
      };
    case types.SEND_EMAIL:
      return {
        ...state,
        isSending: true,
      };
    case types.SEND_EMAIL_SUCCESS:
      return {
        ...state,
        isSending: false,
        sendError: null,
      };
    case types.SEND_EMAIL_FAIL:
      return {
        ...state,
        isSending: false,
        sendError: action.payload || true,
      };
    case types.SEND_EMAIL_CANCEL:
      return {
        ...state,
        isSending: false,
      };
    default:
      return state;
  }
}

// action creators
export const fetchEmails = (params: EmailsParams): actions.FetchEmails => ({
  type: types.FETCH_EMAILS,
  payload: params,
});
export const fetchEmailDetail = (id: string): actions.FetchEmailDetail => ({
  type: types.FETCH_EMAIL_DETAIL,
  payload: id,
});
export const clearEmails = (): actions.ClearEmails => ({ type: types.CLEAR_EMAILS });
export const sendEmail = (id: string, to: string) => ({ type: types.SEND_EMAIL, payload: { id, to } });

export const nextPage = (): actions.FetchEmailsPage => ({
  type: types.FETCH_EMAILS_PAGE,
  payload: 'next',
});
export const prevPage = (): actions.FetchEmailsPage => ({
  type: types.FETCH_EMAILS_PAGE,
  payload: 'prev',
});
export const firstPage = (): actions.FetchEmailsPage => ({
  type: types.FETCH_EMAILS_PAGE,
  payload: 'first',
});
export const lastPage = (): actions.FetchEmailsPage => ({
  type: types.FETCH_EMAILS_PAGE,
  payload: 'last',
});

// epics
const fetchEmailsEpic: HyperEpic<actions.FetchEmailsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_EMAILS).pipe(
    debounceTime(500),
    switchMap((action: actions.FetchEmails) =>
      api.get(`/api/emails?${queryString.stringify(action.payload as any)}`).pipe(
        map(data => ({
          type: types.FETCH_EMAILS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_EMAILS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_EMAILS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchEmailsPageEpic: HyperEpic<actions.FetchEmailsPage | actions.FetchEmailsActions> = (
  action$,
  state$,
  { api },
) =>
  action$.ofType(types.FETCH_EMAILS_PAGE).pipe(
    switchMap((action: actions.FetchEmailsPage) =>
      api.get(state$.value.emails.pagination.links[action.payload]).pipe(
        map(({ response: payload }) => ({
          type: types.FETCH_EMAILS_SUCCESS,
          payload,
        })),
        takeUntil(action$.ofType(types.FETCH_EMAILS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_EMAILS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchEmailDetailEpic: HyperEpic<actions.FetchEmailDetailActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_EMAIL_DETAIL).pipe(
    flatMap((action: actions.FetchEmailDetail) =>
      api.get(`/api/emails/${action.payload}`).pipe(
        map(data => ({
          type: types.FETCH_EMAIL_DETAIL_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_EMAIL_DETAIL_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_EMAIL_DETAIL_FAIL,
            payload: error.xhr.response,
            id: action.payload,
          }),
        ),
      ),
    ),
  );

const sendEmailEpic: HyperEpic<actions.SendEmailActions> = (action$, state$, { api }) =>
  action$.ofType(types.SEND_EMAIL).pipe(
    switchMap((action: actions.SendEmail) =>
      api.post(`/api/emails/${action.payload.id}/send`, { to: action.payload.to }).pipe(
        map(({ response: payload }) => ({
          type: types.SEND_EMAIL_SUCCESS,
          payload,
        })),
        takeUntil(action$.ofType(types.SEND_EMAIL_CANCEL)),
        catchError(error =>
          of({
            type: types.SEND_EMAIL_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

export const emailsEpics = [fetchEmailsEpic, fetchEmailsPageEpic, fetchEmailDetailEpic, sendEmailEpic];
