import {
  Button,
  Card,
  DataTable,
  Icon,
  LoadingSplash,
  Modal,
  StyleUtil,
  TextField,
  getFrom,
  HyperForm,
  HyperFieldFactory as hFields,
} from '@hyperfish/fishfood';
import {
  AuditType,
  Setting,
  SettingsPost,
  License,
  GetInitialLicense,
  mapFeatureToName,
  mapLicenseToName,
  getLicenseTimeRemaining,
  KubernetesCertificateRequest,
} from '../../models';
import * as React from 'react';
import { connect } from 'react-redux';
import Select, { Option } from 'react-select';
import { HyperField } from '@hyperfish/fishfood/lib/components/HyperForm';
import moment from 'moment';

import { AuditsTable } from '../../components/AuditsTable';
import { Copy } from '../../components/Copy';
import { EmailsTable } from '../../components/EmailsTable';
import LinkList from '../../components/Linklist';
import { OrgCollectionModal } from '../../components/OrgCollectionModal';
import { OrgEditModal } from '../../components/OrgEditModal';
import { OrgSummaryTable } from '../../components/OrgSummaryTable';
import { PagedTable } from '../../components/PagedTable';
import { Tabs } from '../../components/Tabs';
import { LicenseEditModal } from '../../components/LicenseEditModal';
import { KubeCertModal } from '../../components/KubeCertModal';
import {
  clearAudits,
  fetchAudits,
  fetchLatestAudit,
  firstPage as auditsFirstPage,
  lastPage as auditsLastPage,
  nextPage as auditsNextPage,
  prevPage as auditsPrevPage,
} from '../../store/Audits';
import {
  clearEmails,
  fetchEmailDetail,
  fetchEmails,
  firstPage as emailsFirstPage,
  lastPage as emailsLastPage,
  nextPage as emailsNextPage,
  prevPage as emailsPrevPage,
  sendEmail,
} from '../../store/Emails';
import { saveLicense, createLicense } from '../../store/Licenses';
import { clearImpersonationLink, fetchImpersonationLink } from '../../store/Links';
import {
  editOrg,
  endEdit,
  fetchAudiences,
  fetchFields,
  fetchLicenses,
  fetchOrg,
  fetchPermissions,
  fetchSettings,
  saveOrg,
  setCollectionByAttribute,
  startEdit,
  saveSettings,
} from '../../store/Orgs';
import {
  clearProviders,
  fetchProviders,
  firstPage as providersFirstPage,
  lastPage as providersLastPage,
  nextPage as providersNextPage,
  prevPage as providersPrevPage,
} from '../../store/Providers';
import Auth from '../../services/Auth';
import { fetchCert, saveCert, validateCert, deleteCert } from '../../store/KubernetesCertificates';

import classes from './styles.module.scss';
import { DataSourceModal } from 'components/DataSourceModal';

const AudiencesTable = DataTable.of({
  name: { label: 'Name', sortable: true },
  accounts: { label: 'Accounts', sortable: true, type: DataTable.types.custom },
  type: { label: 'Type', center: true, width: 75 },
  createdAt: {
    label: 'Created At',
    type: DataTable.types.datetime,
    sortable: true,
    width: 175,
  },
  definition: {
    label: 'Definition',
    hidden: true,
    type: DataTable.types.custom,
  },
});

const LicensesTable = DataTable.of({
  type: { label: 'Type' },
  validityStart: { label: 'Start', width: 175 },
  validityEnd: { label: 'End', width: 175 },
  timeRemaining: { label: 'Time remaining', width: 175 },
  userCount: { label: 'User count', width: 100 },
});

const PermissionsTable = DataTable.of({
  role: { label: 'Role', sortable: true },
  audience: { label: 'Audience', sortable: true },
  name: { label: 'Name' },
  createdAt: {
    label: 'Created At',
    type: DataTable.types.datetime,
    sortable: true,
    width: 175,
  },
  type: { label: 'Type', hidden: true },
  fieldNames: { label: 'Field Names', hidden: true },
});

const SettingsTable = DataTable.of({
  settingProperty: { label: 'Property', sortable: true },
  settingValue: { label: 'Value' },
  inheritanceEnabled: {
    label: 'Inheritance',
    type: DataTable.types.custom /* sortable: true */,
  },
});

const ProvidersTable = DataTable.of({
  name: { label: 'Name' },
  providerName: { label: 'Type' },
  username: { label: 'Username' },
  permissions: { label: 'Permissions' },
});

const connector = connect(
  ({ audits, emails, orgs, links, providers, licenses, kubeCerts }) => ({
    audiencesById: orgs.audiencesById,
    audiencesByIdError: orgs.audiencesByIdError,
    audiencesByIdLoading: orgs.audiencesByIdLoading,
    audits: audits.audits,
    auditsError: audits.error,
    auditsIsLoading: audits.isLoading,
    auditsPagination: audits.pagination,
    latestAuditById: audits.latestAuditById,
    latestAuditByIdLoading: audits.latestAuditByIdLoading,
    latestAuditByIdError: audits.latestAuditByIdError,
    collectionByAttributeError: orgs.collectionByAttributeError,
    collectionByAttributeSaving: orgs.collectionByAttributeSaving,
    dirtyOrg: orgs.dirtyOrg,
    emailDetailById: emails.emailDetailById,
    emailDetailByIdError: emails.emailDetailByIdError,
    emailDetailByIdLoading: emails.emailDetailByIdLoading,
    emails: emails.emails,
    emailsError: emails.error,
    emailsIsLoading: emails.isLoading,
    emailsPagination: emails.pagination,
    fieldsById: orgs.fieldsById,
    fieldsByIdError: orgs.fieldsByIdError,
    fieldsByIdLoading: orgs.fieldsByIdLoading,
    impersonationLink: links.impersonationLink,
    impersonationLinkError: links.impersonationLinkError,
    impersonationLinkIsLoading: links.impersonationLinkIsLoading,
    isSavingById: orgs.isSavingById,
    isSending: emails.isSending,
    licensesById: licenses.licensesById,
    licensesByIdSaving: licenses.licensesByIdSaving,
    licensesByIdSavingError: licenses.licensesByIdSavingError,
    licensesCreating: licenses.creating,
    licensesCreatingError: licenses.creatingError,
    licenseIdsById: orgs.licenseIdsById,
    licensesByIdLoading: orgs.licensesByIdLoading,
    orgsById: orgs.orgsById,
    orgsByIdLoading: orgs.orgsByIdLoading,
    permissionsById: orgs.permissionsById,
    permissionsByIdError: orgs.permissionsByIdError,
    permissionsByIdLoading: orgs.permissionsByIdLoading,
    providers: providers.providers,
    providersError: providers.error,
    providersIsLoading: providers.isLoading,
    providersPagination: providers.pagination,
    providersRelatedEntities: providers.relatedEntities,
    sendError: emails.sendError,
    settingsById: orgs.settingsById,
    settingsByIdError: orgs.settingsByIdError,
    settingsByIdLoading: orgs.settingsByIdLoading,
    settingsSaving: orgs.settingsIsSaving,
    settingsSavingError: orgs.settingsSavingError,
    kubeCertsById: kubeCerts.certsById,
    kubeCertsByIdError: kubeCerts.certsByIdError,
    kubeCertsByIdLoading: kubeCerts.certsByIdLoading,
    kubeValidationById: kubeCerts.validationById,
    kubeValidationByIdError: kubeCerts.validationByIdError,
    kubeValidationByIdLoading: kubeCerts.validationByIdLoading,
    kubeCertsByIdSaving: kubeCerts.certsByIdSaving,
    kubeCertsByIdSavingError: kubeCerts.certsByIdSavingError,
    kubeCertsByIdDeleting: kubeCerts.certsByIdDeleting,
    kubeCertsByIdDeletingError: kubeCerts.certsByIdDeletingError,
  }),
  {
    auditsFirstPage,
    auditsLastPage,
    auditsNextPage,
    auditsPrevPage,
    clearAudits,
    clearEmails,
    clearImpersonationLink,
    clearProviders,
    editOrg,
    emailsFirstPage,
    emailsLastPage,
    emailsNextPage,
    emailsPrevPage,
    endEdit,
    fetchAudiences,
    fetchAudits,
    fetchLatestAudit,
    fetchEmailDetail,
    fetchEmails,
    fetchFields,
    fetchImpersonationLink,
    fetchLicenses,
    fetchOrg,
    fetchPermissions,
    fetchProviders,
    fetchSettings,
    providersFirstPage,
    providersLastPage,
    providersNextPage,
    providersPrevPage,
    saveOrg,
    sendEmail,
    setCollectionByAttribute,
    startEdit,
    saveSettings,
    createLicense,
    saveLicense,
    fetchCert,
    saveCert,
    validateCert,
    deleteCert,
  },
);

type Props = typeof connector['props'];

interface State {
  tab: string;
  audience: string;
  property: string;
  showCollectionModal: boolean;
  showDataSourceModal: boolean;
  showKubeCertModal: boolean;
  collectionByAttributePromptFieldValue: string;
  selectedDataSource?: string;
  auditTypes: AuditType[];
  dirtySettings: SettingsPost;
  dirtyLicense: License;
  newSetting: { audienceId: string; settingProperty: string };
  submittedNewDataSource: boolean;
}

const auditSourceKey = 'audit_source';
const adminConsentKey = 'auth_providers_microsoftGraph_adminConsent';

@connector
export class OrgDetail extends React.Component<Props, State> {
  private settingForm: HyperForm;
  private settingFieldGetterMap: { [settingProperty: string]: (value: any, audienceId: string) => HyperField } = {
    global_mode: (value, audienceId) =>
      hFields.dropdown({
        label: 'Global Mode',
        value,
        required: true,
        dropdownOptions: [
          { label: 'Analyze', value: 'Analyze' },
          { label: 'Pilot', value: 'Pilot' },
          { label: 'Run', value: 'Run' },
        ],
        onChange: o => this.handleSettingEdit(audienceId, 'global_mode', { value: o ? o.value : null }),
      }),
  };

  get orgId() {
    return this.props && this.props.match && this.props.match.params && this.props.match.params['id'];
  }

  private showCollectionModal = () => {
    this.props.fetchFields(this.orgId);
    this.setState({
      showCollectionModal: true,
      collectionByAttributePromptFieldValue: '',
    });
  };
  private closeCollectionModal = () => {
    this.setState({
      showCollectionModal: false,
      collectionByAttributePromptFieldValue: '',
    });
  };

  private showDataSourceModal = () => {
    this.props.fetchFields(this.orgId);
    this.setState({
      showDataSourceModal: true,
      selectedDataSource: null,
      submittedNewDataSource: false,
    });
  };
  private closeDataSourceModal = () => {
    this.setState({
      showDataSourceModal: false,
      selectedDataSource: null,
      submittedNewDataSource: false,
    });
  };

  private showKubeCertModal = () => {
    this.setState({
      showKubeCertModal: true,
    });
  };
  private closeKubeCertModal = () => {
    this.setState({
      showKubeCertModal: false,
    });
  };

  private getAudienceName(audienceId: string) {
    const { audiencesById } = this.props;
    const audiences = audiencesById[this.orgId];

    if (audienceId === this.orgId) {
      return 'Master';
    }
    if (!audiences) {
      return audienceId;
    }

    for (const audience of audiences) {
      if (audience.id === audienceId) {
        return audience.type === 'Global' ? 'Master' : audience.definition.name;
      }
    }

    return '';
  }

  private getAudienceAccountCount(audienceId: string) {
    const { latestAuditById, latestAuditByIdError, latestAuditByIdLoading } = this.props;
    const orgId = this.orgId;
    const audit = latestAuditById[orgId];
    if (latestAuditByIdLoading[orgId]) {
      return <Icon name="spinner" />;
    }
    if (!audit || latestAuditByIdError[orgId]) {
      return '-';
    }
    try {
      const meta: {
        stats: { [audienceId: string]: { paths: any; auditRecordCount: number; totalRecordCount: number } };
      } = JSON.parse(audit.meta);

      const count: number = getFrom(meta)('stats')(audienceId)('auditRecordCount').value || 0;
      return count.toLocaleString();
    } catch (e) {
      return '-';
    }
  }

  private createSetting = () => {
    this.setState({ newSetting: { audienceId: this.orgId, settingProperty: '' } });
  };

  private editSetting = ({ id }: { id: string }) => {
    const { settingsById } = this.props;
    const settings = settingsById[this.orgId];
    if (!settings) {
      console.error('OrgDetail.editSetting() - No settings found!');
      return;
    }
    let setting: Setting;
    for (const s of settings) {
      if (s.id === id) {
        setting = JSON.parse(JSON.stringify(s));
        break;
      }
    }
    if (!setting) {
      console.error(`OrgDetail.editSetting() - Setting with ID ${id} not found!`);
      return;
    }
    this.setState(({ dirtySettings }) => {
      const newDirtySettings = dirtySettings ? { ...dirtySettings } : {};

      if (newDirtySettings[setting.audienceId] == null) {
        newDirtySettings[setting.audienceId] = {};
      }

      newDirtySettings[setting.audienceId][setting.settingProperty] = JSON.stringify(setting.settingValue);

      return { dirtySettings: newDirtySettings };
    });
  };

  private handleSettingEdit = (audienceId: string, settingProperty: string, settingValue: string | { value: any }) => {
    const safeValue = typeof settingValue === 'string' ? settingValue : JSON.stringify(settingValue);

    this.setState(({ dirtySettings }) => ({
      dirtySettings: {
        ...(dirtySettings || {}),
        [audienceId]: {
          ...((dirtySettings || {})[audienceId] || {}),
          [settingProperty]: safeValue,
        },
      },
    }));
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      tab: 'audiences',
      audience: null,
      property: '',
      showCollectionModal: false,
      showDataSourceModal: false,
      showKubeCertModal: false,
      collectionByAttributePromptFieldValue: '',
      auditTypes: ['external', 'online'],
      dirtySettings: null,
      dirtyLicense: null,
      newSetting: null,
      selectedDataSource: null,
      submittedNewDataSource: false,
    };
  }

  componentDidMount() {
    this.props.fetchOrg(this.orgId);
    this.props.fetchLicenses(this.orgId);
    this.props.fetchPermissions(this.orgId);
    this.props.fetchSettings(this.orgId);
    this.props.fetchAudiences(this.orgId);
    this.props.clearAudits();
    this.props.fetchAudits({ orgId: this.orgId, types: this.state.auditTypes });
    this.props.fetchEmails({ orgId: this.orgId });
    this.props.fetchProviders({ orgId: this.orgId });
    this.props.fetchLatestAudit(this.orgId);
    this.props.fetchCert(this.orgId);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { dirtyOrg, isSavingById, collectionByAttributeSaving } = this.props;
    if (dirtyOrg && isSavingById[dirtyOrg.id] && !nextProps.isSavingById[dirtyOrg.id]) {
      nextProps.endEdit();
    }
    if (
      collectionByAttributeSaving &&
      !nextProps.collectionByAttributeSaving &&
      !nextProps.collectionByAttributeError
    ) {
      this.closeCollectionModal();
    }
    if (this.props.licensesCreating && !nextProps.licensesCreating && !nextProps.licensesCreatingError) {
      this.setState({ dirtyLicense: null });
    }
    if (
      this.state.dirtyLicense &&
      this.state.dirtyLicense.id &&
      this.props.licensesByIdSaving[this.state.dirtyLicense.id] &&
      !nextProps.licensesByIdSaving[this.state.dirtyLicense.id] &&
      !nextProps.licensesByIdSavingError[this.state.dirtyLicense.id]
    ) {
      this.setState({ dirtyLicense: null });
    }
  }

  render() {
    const { orgsById, orgsByIdLoading, licensesById, licenseIdsById, settingsById, latestAuditById } = this.props;
    const { tab } = this.state;
    const orgId = this.orgId;
    const org = orgsById[orgId];
    const licenses = licenseIdsById[orgId] && licenseIdsById[orgId].map(id => licensesById[id]).filter(Boolean);
    const settings = settingsById[orgId];
    const latestAudit = latestAuditById[orgId];

    if (!org || orgsByIdLoading[orgId]) {
      return <LoadingSplash />;
    }

    return (
      <div className={classes.container}>
        <h1>
          <span>{org.orgName}</span>
          {Auth.doesUserHaveScope('edit-org') && (
            <a onClick={() => this.props.startEdit(orgId)}>
              <Icon name="pencil" />
            </a>
          )}
        </h1>
        <Card>
          <OrgSummaryTable org={org} licenses={licenses} settings={settings} latestAudit={latestAudit} />
          <LinkList
            links={[
              Auth.doesUserHaveScope('edit-org') && {
                label: 'Configure Collections by Attribute',
                onClick: () => this.showCollectionModal(),
              },
              org.type == 'Online' &&
                Auth.doesUserHaveScope('edit-org') && {
                  label: 'Configure Data Source',
                  onClick: () => this.showDataSourceModal(),
                },
              org.type !== 'Online' && {
                path: `/devices?orgs=${encodeURIComponent(org.id)}`,
                label: 'View Devices',
              },
              {
                label: 'SSL Configuration',
                onClick: () => this.showKubeCertModal(),
              },
            ]}
          />
        </Card>
        <div style={StyleUtil.styles.tables.header_noFlex}>
          <Tabs
            tabs={[
              { key: 'audiences', label: 'Audiences' },
              { key: 'licenses', label: 'Licenses' },
              { key: 'providers', label: 'Providers' },
              { key: 'permissions', label: 'Permissions' },
              { key: 'settings', label: 'Settings' },
              { key: 'audits', label: 'Recent Audits' },
              { key: 'emails', label: 'Emails' },
            ]}
            onChange={tab =>
              this.setState(state => ({
                tab,
                property: '',
                audience: tab === 'settings' && !state.audience ? this.orgId : state.audience,
              }))
            }
            activeKey={tab}
          />
        </div>
        {['permissions', 'settings'].indexOf(tab) > -1 && this.renderFilters()}
        {tab === 'audiences' && this.renderAudiences()}
        {tab === 'licenses' && this.renderLicenses()}
        {tab === 'providers' && this.renderProviders()}
        {tab === 'permissions' && this.renderPermissions()}
        {tab === 'settings' && this.renderSettings()}
        {tab === 'audits' && this.renderAudits()}
        {tab === 'emails' && this.renderEmails()}
        {this.renderImpersonationModal()}
        {this.renderEditModal()}
        {this.renderEditLicenseModal()}
        {this.renderCollectionModal()}
        {this.renderDataSourceModal()}
        {this.renderSettingsEditModal()}
        {this.renderCreateSettingModal()}
        {this.renderKubeCertModal()}
      </div>
    );
  }

  renderAudiences() {
    const { audiencesById, audiencesByIdError, audiencesByIdLoading } = this.props;
    const audiences = audiencesById[this.orgId];
    const error = audiencesByIdError[this.orgId];
    const loading = audiencesByIdLoading[this.orgId];

    return (
      <Card noPadding={true}>
        <AudiencesTable
          loading={loading}
          defaultSortBy="name"
          renderDetail={({ type, definition }) =>
            type === 'OU' ? (
              <div>
                <em>DNStrings:</em>
                <ul>
                  {definition['DNStrings'].map(s => (
                    <li key={s}>{s}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div />
            )
          }
          data={
            audiences &&
            audiences.map(a => ({
              id: a.id,
              name: a.type === 'Global' ? 'Master' : a.definition['name'],
              accounts: this.getAudienceAccountCount(a.id),
              type: a.type,
              createdAt: a.createdAt,
              definition: a.definition as any,
            }))
          }
        />
      </Card>
    );
  }

  renderLicenses() {
    const { licenseIdsById, licensesById, licensesByIdLoading } = this.props;
    const licenses =
      licenseIdsById[this.orgId] && licenseIdsById[this.orgId].map(id => licensesById[id]).filter(Boolean);
    const loading = licensesByIdLoading[this.orgId];

    return (
      <>
        {Auth.doesUserHaveScope('edit-org') && (
          <div style={StyleUtil.styles.tables.header}>
            <a
              style={StyleUtil.styles.tables.headerAction}
              onClick={() =>
                this.setState({
                  dirtyLicense: GetInitialLicense(this.orgId),
                })
              }
            >
              <Icon name="plus" />
              <span style={StyleUtil.styles.tables.headerActionText}>Add License</span>
            </a>
          </div>
        )}
        <Card noPadding={true}>
          <LicensesTable
            data={
              licenses &&
              licenses.map(m => {
                const startDate = moment(m.validityStart, moment.ISO_8601);
                const endDate = moment(m.validityEnd, moment.ISO_8601);

                return {
                  ...m,
                  type: mapLicenseToName(m),
                  features: m.features && m.features.length > 0 ? m.features.map(mapFeatureToName) : undefined,
                  validityStart: m.validityStart ? startDate.format('MMM D, YYYY') : 'The Beginning of Time',
                  validityEnd: m.validityEnd ? endDate.format('MMM D, YYYY') : 'Never',
                  timeRemaining: getLicenseTimeRemaining(m),
                  userCount: m.licensedUsers > 0 ? m.licensedUsers : 'Unlimited',
                  preservedLicense: m,
                };
              })
            }
            renderDetail={l => (
              <>
                <h2>Features:</h2>
                <ul>{(l['features'] && l['features'].map(f => <li key={f}>{f}</li>)) || 'None'}</ul>
              </>
            )}
            onEdit={l => this.setState({ dirtyLicense: l['preservedLicense'] })}
            shouldDisableEdit={!Auth.doesUserHaveScope('edit-org')}
          />
        </Card>
      </>
    );
  }

  renderProviders() {
    const {
      fetchImpersonationLink,
      fetchProviders,
      permissionsById,
      providers,
      providersError,
      providersFirstPage,
      providersIsLoading,
      providersLastPage,
      providersNextPage,
      providersPagination,
      providersPrevPage,
      providersRelatedEntities,
    } = this.props;
    const permissions = permissionsById[this.orgId];

    const getName = (p: typeof providers[0]) => {
      const displayName = p.antreaUser && p.antreaUser.displayName;

      const emails =
        providersRelatedEntities && providersRelatedEntities.emails && providersRelatedEntities.emails[p.antreaUserId];
      const emailString = (emails && emails.map(e => e.address).join(', ')) || 'No Email';

      return !displayName ? emailString : `${displayName} (${emailString})`;
    };

    const getPermissions = (p: typeof providers[0]) => {
      if (!permissions) {
        return '';
      }

      return permissions
        .filter(perm => perm.type === 'SpecificEntity' && perm.definition.entityId === p.username)
        .map(perm => perm.role)
        .join(', ');
    };

    return (
      <PagedTable
        placeholder="Search by antreaUser display name or email (starts with)..."
        onQueryChange={e =>
          fetchProviders({
            orgId: this.orgId,
            q: e.currentTarget.value || null,
          })
        }
        table={
          <ProvidersTable
            loading={providersIsLoading}
            data={
              providers &&
              providers.map(p => ({
                id: p.id,
                name: getName(p),
                providerName: p.providerName,
                username: p.username,
                permissions: getPermissions(p),
              }))
            }
            actions={[
              {
                icon: 'user-id',
                onAction: ({ id }) => fetchImpersonationLink(this.orgId, id, true),
                title: 'Impersonate User',
                disabled: !Auth.doesUserHaveScope('impersonate-user'),
              },
            ]}
          />
        }
        pagerProps={{
          pagination: providersPagination,
          firstPage: providersFirstPage,
          prevPage: providersPrevPage,
          nextPage: providersNextPage,
          lastPage: providersLastPage,
        }}
      />
    );
  }

  renderPermissions() {
    const { fetchImpersonationLink, permissionsById, permissionsByIdError, permissionsByIdLoading } = this.props;
    const permissions = permissionsById[this.orgId];
    const error = permissionsByIdError[this.orgId];
    const loading = permissionsByIdLoading[this.orgId];

    return (
      <Card noPadding={true}>
        <PermissionsTable
          loading={loading}
          defaultSortBy="role"
          data={
            permissions &&
            permissions
              .filter(p => this.state.audience == null || p.permissionGroup === this.state.audience)
              .map(p => ({
                id: p.id,
                role: p.role,
                name:
                  p.type === 'ProfileBased'
                    ? "[ User's Manager ]"
                    : `${p.meta.entityDisplayName} (${p.meta.entityEmail || 'No Email'})`,
                audience: this.getAudienceName(p.permissionGroup),
                createdAt: p.createdAt,
                type: p.type,
                fieldNames: p.role === 'Approver' ? p.config.fieldNames : (null as any),
              }))
          }
          actions={[
            {
              icon: 'user-id',
              onAction: ({ id }) => fetchImpersonationLink(this.orgId, id),
              disabled: ({ type }) => !Auth.doesUserHaveScope('impersonate-user') || type !== 'SpecificEntity',
              title: 'Impersonate User',
            },
          ]}
          renderDetail={({ role, fieldNames }) =>
            role === 'Approver' ? (
              <div>
                <em>Assigned Fields:</em>
                {fieldNames && fieldNames['length'] > 0 ? (
                  <ul>{fieldNames && ((fieldNames as any) as string[]).map(f => <li key={f}>{f}</li>)}</ul>
                ) : (
                  <p>All Fields</p>
                )}
              </div>
            ) : (
              <img src="https://media3.giphy.com/media/zdIGTIdD1mi4/giphy.gif" alt="WHAT DO YOU WANT" />
            )
          }
        />
      </Card>
    );
  }

  renderSettings() {
    const { settingsById, settingsByIdLoading, settingsByIdError } = this.props;
    const { audience, property } = this.state;
    const settings = settingsById[this.orgId];
    const error = settingsByIdError[this.orgId];
    const loading = settingsByIdLoading[this.orgId];

    return (
      <>
        {Auth.doesUserHaveScope('edit-org') && (
          <div style={StyleUtil.styles.tables.header}>
            <a style={StyleUtil.styles.tables.headerAction} onClick={this.createSetting}>
              <Icon name="plus" />
              <span style={StyleUtil.styles.tables.headerActionText}>Add New Setting</span>
            </a>
          </div>
        )}
        <Card noPadding={true}>
          <SettingsTable
            loading={loading}
            defaultSortBy="settingProperty"
            onEdit={this.editSetting}
            shouldDisableEdit={!Auth.doesUserHaveScope('edit-org')}
            data={
              settings &&
              settings
                .filter(s => {
                  if (audience != null && audience !== s.audienceId) {
                    return false;
                  }
                  if (
                    property != null &&
                    property !== '' &&
                    s.settingProperty.toLowerCase().indexOf(property.toLowerCase()) === -1
                  ) {
                    return false;
                  }

                  return true;
                })
                .map(s => ({
                  id: s.id,
                  settingProperty: s.settingProperty,
                  settingValue:
                    typeof s.settingValue.value === 'object'
                      ? JSON.stringify(s.settingValue.value)
                      : String(s.settingValue.value),
                  inheritanceEnabled: s.inheritanceEnabled ? (
                    <span
                      style={{
                        color: StyleUtil.colors.green,
                        fontSize: 14,
                        textAlign: 'center',
                        display: 'block',
                      }}
                    >
                      <Icon name="checkmark" />
                    </span>
                  ) : null,
                }))
            }
          />
        </Card>
      </>
    );
  }

  renderFilters() {
    const { audiencesById, audiencesByIdError, audiencesByIdLoading } = this.props;
    const { audience, tab, property } = this.state;
    const audiences = audiencesById[this.orgId];
    const error = audiencesByIdError[this.orgId];
    const loading = audiencesByIdLoading[this.orgId];

    return (
      <div style={StyleUtil.styles.tables.header}>
        <div className={classes.select}>
          {tab === 'settings' && (
            <TextField
              placeholder="Filter by property"
              value={property}
              onChange={e => this.setState({ property: e.currentTarget.value })}
            />
          )}
        </div>
        <Select
          className={classes.select}
          placeholder="Filter by Audience"
          isLoading={loading}
          value={audience}
          clearable={tab !== 'settings'}
          options={
            audiences &&
            audiences.map(a => ({
              label: a.type === 'Global' ? 'Master' : a.definition.name,
              value: a.id,
            }))
          }
          onChange={(o: Option<string>) => this.setState({ audience: o ? o.value : null })}
        />
      </div>
    );
  }

  renderAudits() {
    const {
      audits,
      auditsIsLoading,
      auditsPagination,
      auditsNextPage,
      auditsPrevPage,
      auditsFirstPage,
      auditsLastPage,
    } = this.props;
    const { auditTypes } = this.state;

    return (
      <AuditsTable
        audits={audits}
        isLoading={auditsIsLoading}
        types={auditTypes}
        onTypesChange={auditTypes => {
          this.setState({ auditTypes });
          this.props.fetchAudits({ orgId: this.orgId, types: auditTypes });
        }}
        pagerProps={{
          pagination: auditsPagination,
          nextPage: auditsNextPage,
          prevPage: auditsPrevPage,
          firstPage: auditsFirstPage,
          lastPage: auditsLastPage,
        }}
      />
    );
  }

  renderEmails() {
    const {
      emails,
      emailsError,
      emailDetailById,
      emailDetailByIdLoading,
      emailDetailByIdError,
      emailsFirstPage,
      emailsIsLoading,
      emailsLastPage,
      emailsNextPage,
      emailsPagination,
      emailsPrevPage,
      fetchEmails,
      fetchEmailDetail,
      isSending,
      sendEmail,
      sendError,
    } = this.props;

    return (
      <EmailsTable
        emails={emails}
        onQueryChange={e => fetchEmails({ orgId: this.orgId, to: e.currentTarget.value || null })}
        isLoading={emailsIsLoading}
        error={emailsError}
        pagerProps={{
          pagination: emailsPagination,
          nextPage: emailsNextPage,
          prevPage: emailsPrevPage,
          firstPage: emailsFirstPage,
          lastPage: emailsLastPage,
        }}
        isSending={isSending}
        sendEmail={sendEmail}
        sendEmailError={sendError}
        emailDetailById={emailDetailById}
        emailDetailByIdError={emailDetailByIdError}
        emailDetailByIdLoading={emailDetailByIdLoading}
        fetchEmailDetail={fetchEmailDetail}
      />
    );
  }

  renderImpersonationModal() {
    const {
      clearImpersonationLink,
      impersonationLink,
      impersonationLinkError,
      impersonationLinkIsLoading,
    } = this.props;

    if (impersonationLink == null && !impersonationLinkIsLoading && impersonationLinkError == null) {
      return null;
    }

    return (
      <Modal onClose={clearImpersonationLink} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.header}>Impersonation Link</h1>

        {impersonationLinkIsLoading && <LoadingSplash />}

        {impersonationLinkError && <p style={{ color: StyleUtil.colors.red }}>Error getting link</p>}

        {impersonationLink && (
          <div className={classes.linkContainer}>
            <div className={classes.linkWrapper}>
              <a href={impersonationLink.href} target="_blank" rel="noopener noreferrer">
                {impersonationLink.href}
              </a>
            </div>
            <Copy text={impersonationLink.href} />
          </div>
        )}

        <div style={StyleUtil.styles.modals.buttonContainer}>
          <Button size="small" type="primary" onClick={clearImpersonationLink}>
            DONE
          </Button>
        </div>
      </Modal>
    );
  }

  renderEditModal() {
    const { dirtyOrg, saveOrg, isSavingById, endEdit, editOrg } = this.props;

    if (dirtyOrg == null) {
      return null;
    }

    const isSaving = isSavingById[dirtyOrg.id];

    return (
      <OrgEditModal dirtyOrg={dirtyOrg} onCancel={endEdit} onEdit={editOrg} onSave={saveOrg} isSaving={isSaving} />
    );
  }

  renderEditLicenseModal() {
    const { createLicense, saveLicense } = this.props;
    const { dirtyLicense } = this.state;

    if (!dirtyLicense) {
      return null;
    }

    const closeModal = () => this.setState({ dirtyLicense: null });
    const isCreating = dirtyLicense.id == null;
    const setValue = <T extends keyof License>(key: T, value: License[T]) => {
      this.setState(({ dirtyLicense }) => ({
        dirtyLicense: {
          ...dirtyLicense,
          [key]: value,
        },
      }));
    };
    const onSave = () => {
      if (isCreating) {
        createLicense(dirtyLicense);
      } else {
        saveLicense(dirtyLicense.id, dirtyLicense);
      }
    };

    return (
      <LicenseEditModal
        dirtyLicense={dirtyLicense}
        onCancel={closeModal}
        onEdit={setValue}
        onSave={onSave}
        isCreating={isCreating}
      />
    );
  }

  renderCollectionModal() {
    const {
      orgsById,
      fieldsById,
      fieldsByIdError,
      fieldsByIdLoading,
      collectionByAttributeSaving,
      collectionByAttributeError,
      setCollectionByAttribute,
      settingsById,
    } = this.props;
    const { collectionByAttributePromptFieldValue } = this.state;
    const org = orgsById[this.orgId];
    const orgFields = fieldsById[this.orgId] || [];
    const fieldsError = fieldsByIdError[this.orgId];
    const fieldsLoading = fieldsByIdLoading[this.orgId];
    const orgSettings = settingsById[this.orgId];

    if (org == null || this.state.showCollectionModal === false) {
      return null;
    }

    const collectionSetting = orgSettings.find(
      f => f.audienceId === this.orgId && f.settingProperty === 'collections_attributes',
    );
    const currentCollectionsField =
      collectionSetting &&
      collectionSetting.settingValue &&
      collectionSetting.settingValue.value &&
      collectionSetting.settingValue.value.enabled &&
      collectionSetting.settingValue.value.fieldName;

    return (
      <OrgCollectionModal
        org={org}
        orgFields={orgFields}
        onClose={this.closeCollectionModal}
        fieldsError={fieldsError}
        fieldsLoading={fieldsLoading}
        collectionByAttributePromptFieldValue={collectionByAttributePromptFieldValue}
        collectionByAttributeSaving={collectionByAttributeSaving}
        setCollectionByAttribute={setCollectionByAttribute}
        collectionByAttributeError={collectionByAttributeError}
        onFieldSelect={collectionByAttributePromptFieldValue =>
          this.setState({ collectionByAttributePromptFieldValue })
        }
        currentCollectionsField={currentCollectionsField}
      />
    );
  }

  handleDataSourceChange = (dataSource: string) => {
    const settingsToSave: SettingsPost = {};
    const masterAudienceId = this.orgId;
    settingsToSave[masterAudienceId] = {};
    settingsToSave[masterAudienceId][auditSourceKey] = JSON.stringify(dataSource);
    settingsToSave[masterAudienceId][adminConsentKey] = JSON.stringify(false);

    this.props.saveSettings(this.orgId, settingsToSave);
    this.setState({ submittedNewDataSource: true });
  };

  renderDataSourceModal() {
    const { orgsById, settingsById } = this.props;
    const { selectedDataSource } = this.state;
    const org = orgsById[this.orgId];
    const orgSettings = settingsById[this.orgId];

    if (org == null || this.state.showDataSourceModal === false) {
      return null;
    }

    if (this.state.submittedNewDataSource && !this.props.settingsSavingError && !this.props.settingsSaving) {
      this.props.fetchSettings(this.orgId);
      this.closeDataSourceModal();
    }

    const auditSourceSetting = orgSettings.find(
      f => f.audienceId === this.orgId && f.settingProperty === auditSourceKey,
    );
    const currentDataSource =
      auditSourceSetting && auditSourceSetting.settingValue && auditSourceSetting.settingValue.value;

    return (
      <DataSourceModal
        org={org}
        onClose={this.closeDataSourceModal}
        handleDataSourceChange={this.handleDataSourceChange}
        onFieldSelect={selectedDataSource => this.setState({ selectedDataSource })}
        currentDataSource={currentDataSource}
        selectedDataSource={selectedDataSource || currentDataSource}
        dataSourceChangeError={this.props.settingsSavingError}
        dataSourceSaving={this.props.settingsSaving}
      />
    );
  }

  renderKubeCertModal() {
    const {
      kubeCertsById,
      kubeCertsByIdLoading,
      kubeCertsByIdSaving,
      kubeCertsByIdSavingError,
      kubeCertsByIdError,
      kubeCertsByIdDeleting,
      kubeCertsByIdDeletingError,
      kubeValidationById,
      kubeValidationByIdLoading,
      kubeValidationByIdError,
      saveCert,
      validateCert,
      deleteCert,
    } = this.props;

    if (!this.state.showKubeCertModal) {
      return null;
    }

    const isLoading = kubeCertsByIdLoading[this.orgId];
    const isErrored = kubeCertsByIdError[this.orgId];
    const isSaving = kubeCertsByIdSaving[this.orgId];
    const saveError = kubeCertsByIdSaving[this.orgId];
    const isDeleting = kubeCertsByIdDeleting[this.orgId];
    const deleteError = kubeCertsByIdDeletingError[this.orgId];

    const currentCert = kubeCertsById && kubeCertsById[this.orgId];
    const validation = {
      loading: kubeValidationByIdLoading && kubeValidationByIdLoading[this.orgId],
      error: kubeValidationByIdError && kubeValidationByIdError[this.orgId],
      data: kubeValidationById && kubeValidationById[this.orgId],
    };

    const onValidate = (cert: KubernetesCertificateRequest) => {
      validateCert(this.orgId, cert);
    };
    const onSave = (cert: KubernetesCertificateRequest) => {
      saveCert(this.orgId, cert);
      if (currentCert) {
        this.closeKubeCertModal();
      }
    };
    const onDelete = () => {
      deleteCert(this.orgId);
    };

    const renderErrorModal = (header: string, message: string) => (
      <Modal
        onClose={() => {
          this.closeKubeCertModal();
        }}
      >
        <h2>{header}</h2>
        <p>{message}</p>
      </Modal>
    );

    if (isErrored) {
      return renderErrorModal('Error', 'Got service error' + (isErrored.message || JSON.stringify(isErrored)));
    } else if (deleteError) {
      return renderErrorModal(
        'Error',
        'Got service error while attempting to delete:' + (deleteError.message || JSON.stringify(deleteError)),
      );
    }

    return (
      <KubeCertModal
        isLoading={isLoading}
        isSaving={isSaving}
        isSaveErrored={saveError}
        isDeleting={isDeleting}
        onCancel={this.closeKubeCertModal}
        onValidate={onValidate}
        onSave={onSave}
        onDelete={onDelete}
        validationData={validation}
        currentCert={currentCert}
      />
    );
  }

  renderCreateSettingModal() {
    const { audiencesById } = this.props;
    const audiences = audiencesById[this.orgId];
    const { newSetting } = this.state;
    const closeModal = () => this.setState({ newSetting: null });

    if (!newSetting) {
      return null;
    }

    const audienceOptions = audiences
      ? audiences.map(a => ({
          label: a.type === 'Global' ? 'Master' : a.definition.name,
          value: a.id,
        }))
      : [];

    const selectedAudiences = audienceOptions.filter(a => a.value === newSetting.audienceId);
    const selectedAudience = selectedAudiences.length > 0 ? selectedAudiences[0] : null;

    return (
      <Modal onClose={closeModal} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.newHeader}>Create Setting</h1>
        <HyperForm
          onCancel={closeModal}
          onSubmit={e => {
            e.preventDefault();
            this.setState(({ dirtySettings }) => {
              const newDirtySettings = dirtySettings ? { ...dirtySettings } : {};

              if (newDirtySettings[newSetting.audienceId] == null) {
                newDirtySettings[newSetting.audienceId] = {};
              }

              newDirtySettings[newSetting.audienceId][newSetting.settingProperty] = '{"value":""}';

              return { newSetting: null, dirtySettings: newDirtySettings };
            });
          }}
          fields={[
            hFields.dropdown({
              label: 'Audience',
              value: selectedAudience,
              required: true,
              dropdownOptions: audienceOptions,
              onChange: o =>
                this.setState(({ newSetting }) => ({
                  newSetting: { ...newSetting, audienceId: o ? (o.value as string) : '' },
                })),
            }),
            hFields.text({
              label: 'Property',
              value: newSetting.settingProperty,
              required: true,
              onChange: ({ currentTarget: { value } }) =>
                this.setState(({ newSetting }) => ({
                  newSetting: { ...newSetting, settingProperty: value },
                })),
            }),
          ]}
        />
      </Modal>
    );
  }

  renderSettingsEditModal() {
    const { dirtySettings } = this.state;
    const fields: { audienceId: string; fields: HyperField[] }[] = [];
    const closeModal = () => this.setState({ dirtySettings: null });

    if (!dirtySettings) {
      return null;
    }

    const audienceIds = Object.keys(dirtySettings);

    for (const audienceId of audienceIds) {
      const audienceFields = { audienceId, fields: [] };
      fields.push(audienceFields);
      const audienceSettings = dirtySettings[audienceId];
      const settingProperties = Object.keys(audienceSettings);

      for (const prop of settingProperties) {
        let parsedSettingValue;
        try {
          parsedSettingValue = JSON.parse(audienceSettings[prop]);
        } catch {
          // No Op
        }
        const value = getFrom(parsedSettingValue)('value').value;
        if (this.settingFieldGetterMap[prop] != null) {
          audienceFields.fields.push(this.settingFieldGetterMap[prop](value, audienceId));
          continue;
        }

        switch (typeof value) {
          case 'boolean':
            audienceFields.fields.push(
              hFields.toggle({
                label: prop,
                value,
                onChange: e => this.handleSettingEdit(audienceId, prop, { value: e.currentTarget.checked }),
              }),
            );
            break;
          default:
            audienceFields.fields.push(
              hFields.longText({
                label: prop,
                value: audienceSettings[prop],
                required: true,
                onChange: e => this.handleSettingEdit(audienceId, prop, e.currentTarget.value),
                validationText: (val => {
                  if (!val) {
                    return 'Must have a value';
                  }
                  try {
                    const parsedVal = JSON.parse(val);
                    const valKeys = Object.keys(parsedVal);
                    if (valKeys.length !== 1 || valKeys[0] !== 'value') {
                      return "Value must be a JSON object with a single property, 'value'";
                    }
                  } catch {
                    return 'Value must be valid JSON';
                  }
                  return undefined;
                })(audienceSettings[prop]),
              }),
            );
        }
      }
    }

    return (
      <Modal onClose={closeModal} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.newHeader}>Edit Settings</h1>
        {fields.map(a => (
          <React.Fragment key={a.audienceId}>
            <h2>{this.getAudienceName(a.audienceId)}</h2>
            <HyperForm
              fields={a.fields}
              ref={form => (this.settingForm = form)}
              onCancel={closeModal}
              onSubmit={e => {
                e.preventDefault();

                const settingsToSave: SettingsPost = {};
                const dirtySettings = this.state.dirtySettings;
                const audienceIds = Object.keys(dirtySettings);
                for (const audienceId of audienceIds) {
                  settingsToSave[audienceId] = {};
                  const settingProps = Object.keys(dirtySettings[audienceId]);
                  for (const settingProp of settingProps) {
                    settingsToSave[audienceId][settingProp] = JSON.stringify(
                      getFrom(JSON.parse(dirtySettings[audienceId][settingProp]))('value').value,
                    );
                  }
                }

                this.props.saveSettings(this.orgId, settingsToSave);
              }}
              saving={this.props.settingsSaving}
            />
          </React.Fragment>
        ))}
      </Modal>
    );
  }
}
