export function exportToCsv(filename, rows) {
  const separator = ',';
  const csvContent = rows
    .map(row => {
      return Object.values(row)
        .map(value => {
          if (typeof value === 'string' && value.includes(separator)) {
            return `"${value}"`;
          } else {
            return value;
          }
        })
        .join(separator);
    })
    .join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
