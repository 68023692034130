import { Card, LoadingSplash, StyleUtil } from '@hyperfish/fishfood';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Area, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { fetchLiteOrgsReport } from '../../store/Reports';

import classes from './styles.module.scss';

const connector = connect(state => state.reports, {
  fetchLiteOrgsReport,
});

type Props = typeof connector['props'];

@connector
export class LiteOrgsReport extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchLiteOrgsReport();
  }

  render() {
    const { liteOrgs, liteOrgsLoading, liteOrgsError } = this.props;

    if (liteOrgsError != null) {
      return (
        <Card>
          <h1>Well that isn&apos;t great...</h1>
          <p>We failed to load the lite orgs report :(</p>
        </Card>
      );
    }

    if (!liteOrgs || liteOrgsLoading) {
      return <LoadingSplash />;
    }

    const orgCountByStatusObject = {};
    for (const x of liteOrgs.orgCountByOnboardingStatus) {
      const key = String(JSON.parse(x.value).value);
      orgCountByStatusObject[key] = x.count;
    }

    const orgCountByAdminObject = {};
    for (const x of liteOrgs.orgCountByAdminConsent) {
      const key = String(JSON.parse(x.value).value);
      orgCountByAdminObject[key] = x.count;
    }

    const dateFormat = 'M/D/YY';
    const formatDate = (dateString: string) => moment(dateString, moment.ISO_8601).format(dateFormat);

    const dataPoints: { [key: string]: { date: string; count: number; names: string[] } } = {};
    for (const x of liteOrgs.createdByDate) {
      const date = formatDate(x.date);
      dataPoints[date] = {
        date,
        count: x.count,
        names: x.names,
      };
    }

    const chartData: (typeof dataPoints['foo'] & { cumulative: number })[] = [];
    const lastDate = moment();
    const date = moment(liteOrgs.createdByDate[0].date, moment.ISO_8601);
    let cumulative = 0;
    while (date.isBefore(lastDate)) {
      const dateString = date.format(dateFormat);
      if (dataPoints.hasOwnProperty(dateString)) {
        const dataPoint = dataPoints[dateString];
        cumulative += dataPoint.count;
        chartData.push({
          ...dataPoint,
          cumulative,
        });
      } else {
        chartData.push({
          date: dateString,
          count: 0,
          names: [],
          cumulative,
        });
      }
      date.add(1, 'day');
    }

    return (
      <Card>
        <h1 className={classes.h1}>Lite Orgs Report</h1>
        <div className={classes.chart}>
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={chartData} margin={{ top: 20, right: 30, left: 0, bottom: 10 }}>
              <Legend verticalAlign="top" height={36} />
              <CartesianGrid stroke="#f5f5f5" strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis yAxisId="left" orientation="left" dataKey="cumulative" />
              <YAxis yAxisId="right" orientation="right" dataKey="count" />
              <Area
                yAxisId="left"
                type="monotone"
                dataKey="cumulative"
                name="Cumulative"
                stroke={StyleUtil.colors.orange}
                fill={StyleUtil.colors.orange}
              />
              <Line
                yAxisId="right"
                dataKey="count"
                name="Daily"
                stroke={StyleUtil.colors.blue}
                label={{ dy: -10, fontSize: 8, textAnchor: 'middle' }}
              />
              <Tooltip />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <table className={classes.statsTable}>
          <tr>
            <td>Total Orgs</td>
            <td>{liteOrgs.totalOrgCount.toLocaleString()}</td>
            <td>OnBoarding Complete</td>
            <td>{Math.round((orgCountByStatusObject['complete'] / liteOrgs.totalOrgCount) * 100)}%</td>
          </tr>
          <tr>
            <td>Average User Count</td>
            <td>{Math.round(liteOrgs.averageUserCount).toLocaleString()}</td>
            <td>Admin Consent</td>
            <td>{Math.round((orgCountByAdminObject['true'] / liteOrgs.totalOrgCount) * 100)}%</td>
          </tr>
          <tr>
            <td>Total User Count</td>
            <td>{liteOrgs.totalUserCount.toLocaleString()}</td>
            <td colSpan={2} />
          </tr>
        </table>
      </Card>
    );
  }
}
