import { Button, DataTable, dateFormatter, getFrom, Modal, StyleUtil, TextField } from '@hyperfish/fishfood';
import * as React from 'react';

import { EmailSearchResult, Error, Org, Pagination } from '../../models';
import { EmailDetailPayload } from '../../models/state/Emails';
import { fetchEmailDetail, sendEmail } from '../../store/Emails';
import { EmailPreview } from '../EmailPreview';
import { PagedTable } from '../PagedTable';
import Auth from '../../services/Auth';

const columns = {
  // orgId: { label: 'Org ID' },
  to: { label: 'To' },
  subject: { label: 'Subject' },
  createdAt: { label: 'Created' },
};

const Table = DataTable.of(columns);

interface Props {
  emails: EmailSearchResult[];
  emailDetailById?: { [id: string]: EmailDetailPayload };
  emailDetailByIdLoading?: { [id: string]: boolean };
  emailDetailByIdError?: { [id: string]: Error };
  fetchEmailDetail?: typeof fetchEmailDetail;
  isLoading: boolean;
  error: any;
  onQueryChange: TextField['props']['onChange'];
  orgs?: { [orgId: string]: Org };
  sendEmail: typeof sendEmail;
  isSending: boolean;
  sendEmailError: any;

  pagerProps?: {
    pagination: Pagination;
    nextPage: () => void;
    prevPage: () => void;
    firstPage: () => void;
    lastPage: () => void;
  };
}

interface State {
  idToSend: string;
  toAddress: string;
  emailSent: boolean;
}

const shortFormat = 'M/D/YY h:mma';
const dateFormat = 'MM/DD/YYYY h:mm:ss:SSa';

export class EmailsTable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { idToSend: null, toAddress: '', emailSent: false };
  }

  render() {
    const { emails, isLoading, onQueryChange, orgs, pagerProps, fetchEmailDetail, error } = this.props;

    return (
      <div>
        <PagedTable
          placeholder="Find email by recipient address (starts with)..."
          onQueryChange={onQueryChange}
          table={
            <Table
              loading={isLoading}
              noDataMessage={error ? 'Failed to load emails' : 'No emails found'}
              data={
                emails &&
                emails.map((e, index) => ({
                  ...e,
                  index,
                  // orgId: e.orgId,
                  createdAt: dateFormatter(e.createdAt, shortFormat),
                  subject: getFrom(e.headers)('subject').value,
                  to: getFrom(e.headers)('to').value,
                }))
              }
              renderDetail={this.props.emailDetailById ? this.renderDetail : null}
              onExpandRow={fetchEmailDetail ? ({ id }) => fetchEmailDetail(id) : null}
              actions={[
                {
                  icon: 'mail',
                  onAction: ({ id }) => this.setState({ idToSend: id, toAddress: '', emailSent: false }),
                  disabled: !Auth.doesUserHaveScope('read-emails'),
                },
              ]}
            />
          }
          pagerProps={
            pagerProps && {
              isDisabled: isLoading,
              ...pagerProps,
            }
          }
        />
        {this.state.idToSend != null && this.renderSendModal()}
      </div>
    );
  }

  renderDetail = ({ id }) => {
    const email = this.props.emailDetailById[id];
    const loading = this.props.emailDetailByIdLoading && this.props.emailDetailByIdLoading[id];
    const error = this.props.emailDetailByIdError && this.props.emailDetailByIdError[id];

    if (loading) {
      return <p>Loading detail...</p>;
    }

    if (error) {
      return <p>Failed to load detail</p>;
    }

    if (!email) {
      return <p>Detail not found... tell Ted</p>;
    }

    const body = getFrom(email.event)('meta')('details')('body').value;
    const headers = {
      to: getFrom(email.event)('meta')('to').value,
      fromname: getFrom(email.event)('meta')('details')('fromname').value,
      from: getFrom(email.event)('meta')('details')('from').value,
      subject: getFrom(email.event)('meta')('details')('subject').value,
    };

    return (
      <EmailPreview
        templatePreview={{
          headers,
          body,
        }}
      />
    );
  };

  renderSendModal() {
    const { sendEmail, sendEmailError, isSending } = this.props;
    const { emailSent, idToSend, toAddress } = this.state;

    return (
      <Modal onClose={() => this.setState({ idToSend: null })} style={StyleUtil.styles.modals.newPadding}>
        {emailSent && !isSending ? (
          <div>
            <h1 style={StyleUtil.styles.modals.header}>
              {!sendEmailError ? 'Email sent!' : "Sorry, it didn't work..."}
            </h1>
            <div style={StyleUtil.styles.modals.buttonContainer}>
              <Button type="primary" onClick={() => this.setState({ idToSend: null })}>
                DONE
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <h1 style={StyleUtil.styles.modals.header}>Send email to:</h1>
            <TextField
              placeholder="Anywhere you want it. That is where you need it. Anywhere you waaant it..."
              value={toAddress}
              onChange={e => this.setState({ toAddress: e.currentTarget.value })}
              type="email"
            />
            <div style={StyleUtil.styles.modals.buttonContainer}>
              <Button
                type="primary"
                disabled={!toAddress || isSending}
                onClick={() => this.setState({ emailSent: true }, () => sendEmail(idToSend, toAddress))}
                iconName={isSending ? 'loading' : null}
              >
                SEND
              </Button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}
