import { Card } from '@hyperfish/fishfood';
import * as React from 'react';
import { connect } from 'react-redux';

import {
  clearEmails,
  fetchEmails,
  fetchEmailDetail,
  nextPage,
  prevPage,
  firstPage,
  lastPage,
  sendEmail,
} from '../../store/Emails';
import { EmailsTable } from '../../components/EmailsTable';

const connector = connect(state => state.emails, {
  clearEmails,
  fetchEmails,
  fetchEmailDetail,
  firstPage,
  lastPage,
  nextPage,
  prevPage,
  sendEmail,
});

type Props = typeof connector['props'];

@connector
export class Emails extends React.Component<Props> {
  componentDidMount() {
    this.props.clearEmails();
    this.props.fetchEmails({});
  }

  render() {
    const {
      emails,
      emailDetailById,
      emailDetailByIdError,
      emailDetailByIdLoading,
      error,
      fetchEmails,
      fetchEmailDetail,
      firstPage,
      isLoading,
      isSending,
      lastPage,
      nextPage,
      pagination,
      prevPage,
      sendEmail,
      sendError,
    } = this.props;

    return (
      <EmailsTable
        emails={emails}
        isLoading={isLoading}
        error={error}
        // orgs={relatedEntities && relatedEntities.orgs}
        onQueryChange={e => fetchEmails({ to: e.currentTarget.value || null })}
        pagerProps={{
          pagination,
          nextPage,
          prevPage,
          firstPage,
          lastPage,
        }}
        isSending={isSending}
        sendEmail={sendEmail}
        sendEmailError={sendError}
        emailDetailById={emailDetailById}
        emailDetailByIdError={emailDetailByIdError}
        emailDetailByIdLoading={emailDetailByIdLoading}
        fetchEmailDetail={fetchEmailDetail}
      />
    );
  }
}
