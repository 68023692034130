import * as React from 'react';
import { Modal, StyleUtil, HyperForm, HyperFieldFactory as fields, Button } from '@hyperfish/fishfood';
import { Org, Error } from '../../models';

const OnlineDataSources: { label: string; value: string }[] = [
  { label: 'Ms Graph', value: 'MsGraph' },
  { label: 'Profile Api', value: 'ProfileApi' },
];
const OnpremDataSources: { label: string; value: string }[] = [{ label: 'Agent', value: 'Agent' }];

interface Props {
  onClose: () => void;
  org: Org;
  dataSourceSaving: boolean;
  handleDataSourceChange: (dataSource: string) => void;
  dataSourceChangeError: Error;
  onFieldSelect: (property: string) => void;
  selectedDataSource?: string;
  currentDataSource?: string;
}

export const DataSourceModal = ({
  onClose,
  org,
  onFieldSelect,
  dataSourceSaving,
  handleDataSourceChange,
  dataSourceChangeError,
  selectedDataSource,
  currentDataSource,
}: Props) => {
  const defaultDataSource = org.type === 'Online' ? OnlineDataSources[0].value : OnpremDataSources[0].value;

  return (
    <Modal onClose={onClose} style={StyleUtil.styles.modals.newPadding}>
      <h1>Data source</h1>
      <p>
        This determines where Directory will get the data from. This can be from the Microsoft Graph API or from the
        Profile API.
      </p>
      {org.type === 'Online' && currentDataSource != selectedDataSource && (
        <p style={{ color: StyleUtil.colors.orange }}>
          In case of a data source change, the customer will need to reauthorize. Their Directory app will show a
          message indicating that they are disconnected until the new consent is granted.
        </p>
      )}
      <HyperForm
        fields={[
          fields.dropdown({
            label: 'Source',
            value: selectedDataSource || defaultDataSource,
            required: true,
            onChange: val => onFieldSelect(val ? (val.value as string) : null),
            dropdownOptions: org.type === 'Online' ? OnlineDataSources : OnpremDataSources,
          }),
        ]}
        onCancel={onClose}
        saving={dataSourceSaving}
        disableSave={selectedDataSource == currentDataSource}
        onSubmit={e => {
          e.preventDefault();
          handleDataSourceChange(selectedDataSource);
        }}
      />
      {dataSourceChangeError && <p style={{ color: StyleUtil.colors.red }}>Failed to change the data source</p>}
    </Modal>
  );
};
