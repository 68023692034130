import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { ProvidersParams, State } from '../../models';
import * as actions from './actions';
import * as types from './types';

type ProvidersState = State['providers'];

const initialState = {} as ProvidersState;

export default function(state: ProvidersState = initialState, action: actions.Action): ProvidersState {
  switch (action.type) {
    case types.FETCH_PROVIDERS:
    case types.FETCH_PROVIDERS_PAGE:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        providers: action.payload.items,
        pagination: action.payload.pagination,
        relatedEntities: action.payload.relatedEntities,
        error: null,
      };
    case types.FETCH_PROVIDERS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload || true,
      };
    case types.FETCH_PROVIDERS_CANCEL:
    case types.CLEAR_PROVIDERS:
      return {
        ...state,
        pagination: null,
        providers: null,
        error: null,
        isLoading: false,
      };
    default:
      return state;
  }
}

// action creators
export const fetchProviders = (params: ProvidersParams): actions.FetchProviders => ({
  type: types.FETCH_PROVIDERS,
  payload: params,
});
export const clearProviders = (): actions.ClearProviders => ({ type: types.CLEAR_PROVIDERS });

export const nextPage = (): actions.FetchProvidersPage => ({
  type: types.FETCH_PROVIDERS_PAGE,
  payload: 'next',
});
export const prevPage = (): actions.FetchProvidersPage => ({
  type: types.FETCH_PROVIDERS_PAGE,
  payload: 'prev',
});
export const firstPage = (): actions.FetchProvidersPage => ({
  type: types.FETCH_PROVIDERS_PAGE,
  payload: 'first',
});
export const lastPage = (): actions.FetchProvidersPage => ({
  type: types.FETCH_PROVIDERS_PAGE,
  payload: 'last',
});

// epics
const fetchProvidersEpic: HyperEpic<actions.FetchProvidersActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_PROVIDERS).pipe(
    debounceTime(500),
    switchMap((action: actions.FetchProviders) =>
      api.get(`/api/providers?${queryString.stringify(action.payload as any)}`).pipe(
        map(data => ({
          type: types.FETCH_PROVIDERS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_PROVIDERS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_PROVIDERS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchProvidersPageEpic: HyperEpic<actions.FetchProvidersPage | actions.FetchProvidersActions> = (
  action$,
  state$,
  { api },
) =>
  action$.ofType(types.FETCH_PROVIDERS_PAGE).pipe(
    switchMap((action: actions.FetchProvidersPage) =>
      api.get(state$.value.providers.pagination.links[action.payload]).pipe(
        map(({ response: payload }) => ({
          type: types.FETCH_PROVIDERS_SUCCESS,
          payload,
        })),
        takeUntil(action$.ofType(types.FETCH_PROVIDERS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_PROVIDERS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

export const providersEpics = [fetchProvidersEpic, fetchProvidersPageEpic];
