export function getDateToFileName(): string {
  const date = new Date();
  const formattedDate =
    date.getFullYear().toString() +
    (date.getMonth() + 1).toString().padStart(2, '0') +
    date
      .getDate()
      .toString()
      .padStart(2, '0') +
    date
      .getHours()
      .toString()
      .padStart(2, '0') +
    date
      .getMinutes()
      .toString()
      .padStart(2, '0') +
    date
      .getSeconds()
      .toString()
      .padStart(2, '0');
  return formattedDate;
}

export function convertDateToStandardFormat(dataInput: string | Date): string {
  if (dataInput === null || dataInput === undefined) return '';

  const date = typeof dataInput === 'string' ? new Date(dataInput) : dataInput;
  //MM-dd-yyyy hh:mm
  return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}-${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
}
