import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { State, KubernetesCertificateRequest } from '../../models';
import * as actions from './actions';
import * as types from './types';

type CertsState = State['kubeCerts'];

const initialState = {
  certsById: {},
  certsByIdLoading: {},
  certsByIdError: {},
  certsByIdSaving: {},
  certsByIdSavingError: {},
  certsByIdDeleting: {},
  certsByIdDeletingError: {},
  validationById: {},
  validationByIdLoading: {},
  validationByIdError: {},
} as CertsState;

export default function(state: CertsState = initialState, action: actions.CertsActions): CertsState {
  switch (action.type) {
    case types.FETCH_CERTS:
      return {
        ...state,
        loadingAll: true,
        loadingAllError: null,
      };
    case types.FETCH_CERTS_SUCCESS:
      const certsById = { ...state.certsById };
      action.payload.forEach(f => (certsById[f.OrgId] = f));

      return {
        ...state,
        certsById,
        loadingAll: false,
      };
    case types.FETCH_CERTS_FAIL:
      return {
        ...state,
        loadingAll: true,
        loadingAllError: action.payload.error,
      };
    case types.FETCH_CERTS_CANCEL:
      return {
        ...state,
        loadingAll: false,
      };
    case types.FETCH_CERT:
      return {
        ...state,
        certsByIdLoading: {
          ...state.certsByIdLoading,
          [action.payload.orgId]: true,
        },
        certsByIdError: {
          ...state.certsByIdError,
          [action.payload.orgId]: null,
        },
      };
    case types.FETCH_CERT_SUCCESS:
      return {
        ...state,
        certsById: {
          ...state.certsById,
          [action.payload.orgId]: action.payload.cert,
        },
        certsByIdLoading: {
          ...state.certsByIdLoading,
          [action.payload.orgId]: false,
        },
      };
    case types.FETCH_CERT_FAIL:
      return {
        ...state,
        certsByIdLoading: {
          ...state.certsByIdLoading,
          [action.payload.orgId]: false,
        },
        certsByIdError: {
          ...state.certsByIdError,
          [action.payload.orgId]: action.payload.error,
        },
      };
    case types.FETCH_CERT_CANCEL:
      return {
        ...state,
        certsByIdLoading: {
          ...state.certsByIdLoading,
          [action.payload.orgId]: false,
        },
      };
    case types.SAVE_CERT:
      return {
        ...state,
        certsByIdSaving: {
          ...state.certsByIdSaving,
          [action.payload.orgId]: true,
        },
        certsByIdError: {
          ...state.certsByIdError,
          [action.payload.orgId]: null,
        },
      };
    case types.SAVE_CERT_SUCCESS:
      return {
        ...state,
        certsByIdSaving: {
          ...state.certsByIdSaving,
          [action.payload.orgId]: false,
        },
        certsById: {
          ...state.certsById,
          [action.payload.orgId]: action.payload.cert,
        },
      };
    case types.SAVE_CERT_FAIL:
      return {
        ...state,
        certsByIdSaving: {
          ...state.certsByIdSaving,
          [action.payload.orgId]: false,
        },
        certsByIdError: {
          ...state.certsByIdError,
          [action.payload.orgId]: action.payload.error,
        },
      };
    case types.SAVE_CERT_CANCEL:
      return {
        ...state,
        certsByIdSaving: {
          ...state.certsByIdSaving,
          [action.payload.orgId]: false,
        },
      };
    case types.VALIDATE_CERT:
      return {
        ...state,
        validationByIdLoading: {
          ...state.validationByIdLoading,
          [action.payload.orgId]: true,
        },
        validationByIdError: {
          ...state.validationByIdError,
          [action.payload.orgId]: null,
        },
      };
    case types.VALIDATE_CERT_SUCCESS:
      return {
        ...state,
        validationByIdLoading: {
          ...state.validationByIdLoading,
          [action.payload.orgId]: false,
        },
        validationById: {
          ...state.validationById,
          [action.payload.orgId]: action.payload.response,
        },
      };
    case types.VALIDATE_CERT_FAIL:
      return {
        ...state,
        validationByIdLoading: {
          ...state.validationByIdLoading,
          [action.payload.orgId]: false,
        },
        validationByIdError: {
          ...state.validationById,
          [action.payload.orgId]: action.payload.error,
        },
      };
    case types.VALIDATE_CERT_CANCEL:
      return {
        ...state,
        validationByIdLoading: {
          ...state.validationByIdLoading,
          [action.payload.orgId]: false,
        },
      };
    case types.DELETE_CERT:
      return {
        ...state,
        certsByIdDeleting: {
          ...state.certsByIdDeleting,
          [action.payload.orgId]: true,
        },
        certsByIdDeletingError: {
          ...state.certsByIdDeletingError,
          [action.payload.orgId]: null,
        },
      };
    case types.DELETE_CERT_SUCCESS:
      return {
        ...state,
        certsByIdDeleting: {
          ...state.certsByIdDeleting,
          [action.payload.orgId]: false,
        },
        certsById: {
          ...state.certsById,
          [action.payload.orgId]: null,
        },
      };
    case types.DELETE_CERT_FAIL:
      return {
        ...state,
        certsByIdDeleting: {
          ...state.certsByIdDeleting,
          [action.payload.orgId]: false,
        },
        certsByIdDeletingError: {
          ...state.certsByIdDeletingError,
          [action.payload.orgId]: action.payload.error,
        },
      };
    case types.DELETE_CERT_CANCEL:
      return {
        ...state,
        certsByIdDeleting: {
          ...state.certsByIdDeleting,
          [action.payload.orgId]: false,
        },
      };
    default:
      return state;
  }
}

// action creators
export const fetchCerts = (): actions.FetchCerts => ({
  type: types.FETCH_CERTS,
});

export const fetchCert = (orgId: string): actions.FetchCert => ({
  type: types.FETCH_CERT,
  payload: { orgId },
});

export const saveCert = (orgId: string, cert: KubernetesCertificateRequest): actions.SaveCert => ({
  type: types.SAVE_CERT,
  payload: { orgId, cert },
});

export const validateCert = (orgId: string, cert: KubernetesCertificateRequest): actions.ValidateCert => ({
  type: types.VALIDATE_CERT,
  payload: { orgId, cert },
});

export const deleteCert = (orgId: string): actions.DeleteCert => ({
  type: types.DELETE_CERT,
  payload: { orgId },
});

// epics
const fetchCertsEpic: HyperEpic<actions.FetchCertsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_CERTS).pipe(
    switchMap((action: actions.FetchCerts) =>
      api.get(`/api/certs`).pipe(
        map(data => ({
          type: types.FETCH_CERTS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_CERTS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_CERTS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchCertEpic: HyperEpic<actions.FetchCertActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_CERT).pipe(
    switchMap((action: actions.FetchCert) =>
      api.get(`/api/certs/${action.payload.orgId}`).pipe(
        map(data => ({
          type: types.FETCH_CERT_SUCCESS,
          payload: { orgId: action.payload.orgId, cert: data.response },
        })),
        takeUntil(action$.ofType(types.FETCH_CERT_CANCEL)),
        catchError(error => {
          if (error.status === 404) {
            // Just say 404 is a success
            return of({
              type: types.FETCH_CERT_SUCCESS,
              payload: { orgId: action.payload.orgId, cert: null },
            });
          } else {
            return of({
              type: types.FETCH_CERT_FAIL,
              payload: { orgId: action.payload.orgId, error: error.xhr.response },
            });
          }
        }),
      ),
    ),
  );

const saveCertEpic: HyperEpic<actions.SaveCertActions> = (action$, state$, { api }) =>
  action$.ofType(types.SAVE_CERT).pipe(
    switchMap((action: actions.SaveCert) =>
      api
        .post(`/api/certs/${action.payload.orgId}`, {
          ...action.payload.cert,
          publicKey: action.payload.cert.publicKey ? btoa(action.payload.cert.publicKey) : undefined,
          privateKey: action.payload.cert.privateKey ? btoa(action.payload.cert.privateKey) : undefined,
        })
        .pipe(
          map(data => ({
            type: types.SAVE_CERT_SUCCESS,
            payload: { orgId: action.payload.orgId, cert: data.response },
          })),
          takeUntil(action$.ofType(types.SAVE_CERT_CANCEL)),
          catchError(error =>
            of({
              type: types.SAVE_CERT_FAIL,
              payload: { orgId: action.payload.orgId, error: error.xhr.response },
            }),
          ),
        ),
    ),
  );

const validateCertEpic: HyperEpic<actions.ValidateCertActions> = (action$, state$, { api }) =>
  action$.ofType(types.VALIDATE_CERT).pipe(
    switchMap((action: actions.ValidateCert) =>
      api
        .post(`/api/certs/validate`, {
          ...action.payload.cert,
          publicKey: action.payload.cert.publicKey ? btoa(action.payload.cert.publicKey) : undefined,
          privateKey: action.payload.cert.privateKey ? btoa(action.payload.cert.privateKey) : undefined,
        })
        .pipe(
          map(data => ({
            type: types.VALIDATE_CERT_SUCCESS,
            payload: { orgId: action.payload.orgId, response: data.response },
          })),
          takeUntil(action$.ofType(types.VALIDATE_CERT_CANCEL)),
          catchError(error =>
            of({
              type: types.VALIDATE_CERT_FAIL,
              payload: { orgId: action.payload.orgId, error: error.xhr.response },
            }),
          ),
        ),
    ),
  );

const deleteCertEpic: HyperEpic<actions.DeleteCertActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_CERT).pipe(
    switchMap((action: actions.DeleteCert) =>
      api.del(`/api/certs/${action.payload.orgId}`).pipe(
        map(data => ({
          type: types.DELETE_CERT_SUCCESS,
          payload: { orgId: action.payload.orgId },
        })),
        takeUntil(action$.ofType(types.DELETE_CERT_CANCEL)),
        catchError(error =>
          of({
            type: types.DELETE_CERT_FAIL,
            payload: { orgId: action.payload.orgId, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

export const kubeCertsEpics = [fetchCertsEpic, fetchCertEpic, saveCertEpic, validateCertEpic, deleteCertEpic];
