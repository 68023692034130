import { Icon, StyleUtil } from '@hyperfish/fishfood';
import * as React from 'react';
import Clipboard from 'react-clipboard.js';

import classes from './styles.module.scss';

interface Props {
  text: string;
}

interface State {
  copied: boolean;
}

export class Copy extends React.Component<Props, State> {
  private timeout: number;

  constructor(props: Props) {
    super(props);
    this.state = { copied: false };
  }

  componentWillUnmount() {
    if (this.timeout) {
      window.clearTimeout(this.timeout);
    }
  }

  handleSuccess = () => {
    this.setState({ copied: true }, () => {
      this.timeout = window.setTimeout(() => this.setState({ copied: false }), 3000);
    });
  };

  render() {
    const { text } = this.props;
    const { copied } = this.state;

    return (
      <Clipboard data-clipboard-text={text} onSuccess={this.handleSuccess} className={classes.copy}>
        <Icon
          name={copied ? 'checkmark' : 'copy'}
          style={
            copied
              ? {
                  color: StyleUtil.colors.green,
                }
              : {}
          }
        />
      </Clipboard>
    );
  }
}
