import { dateFormatter, getFrom } from '@hyperfish/fishfood';
import * as React from 'react';

import { License, Org, Setting, Audit, mapLicenseToName, getCombinedOrgLicense } from '../../models';
import { Copy } from '../Copy';
import { Spinner } from '../Spinner';
import { SummaryTable } from '../SummaryTable';

import classes from './styles.module.scss';

interface Props {
  org: Org;
  licenses: License[];
  settings?: Setting[];
  latestAudit?: Audit;
}

export const OrgSummaryTable = ({ org, licenses, settings, latestAudit }: Props) => {
  const combinedLicense = getCombinedOrgLicense(licenses);
  return (
    <SummaryTable
      rows={[
        { label: 'ID', value: org.id, isCopyable: true },
        { label: 'Tenant ID', value: org.tenantId, isCopyable: true },
        { label: 'Type', value: org.type, isCopyable: false },
        {
          label: 'Created At',
          value: dateFormatter(org.createdAt),
          isCopyable: false,
        },
        settings && {
          label: 'Mode',
          isCopyable: false,
          value: settings
            .filter(s => s.audienceId === s.orgId && s.settingProperty === 'global_mode')
            .map(s => s.settingValue.value)
            .join(','),
        },
        settings && {
          label: 'Read-only mode',
          isCopyable: false,
          value:
            settings
              .filter(s => s.audienceId === s.orgId && s.settingProperty === 'read_only_mode')
              .map(s => s.settingValue.value)
              .join(',') == 'true'
              ? 'Enabled'
              : 'Disabled',
        },
        settings &&
          org.type == 'Online' && {
            label: 'Data source',
            isCopyable: false,
            value:
              settings
                .filter(s => s.audienceId === s.orgId && s.settingProperty === 'audit_source')
                .map(s => s.settingValue.value)
                .join(',') == 'ProfileApi'
                ? 'Profile Api'
                : 'Ms Graph',
          },
        {
          label: `Active License`,
          value:
            (combinedLicense && (
              <span>
                Type: {combinedLicense.softName}
                <br />
                User count: {combinedLicense.licensedUsers || 'Unlimited'}
                <br />
                Time remaining: {combinedLicense.timeRemaining}
              </span>
            )) ||
            'None',
          isCopyable: false,
          isLoading: !licenses,
        },
        latestAudit && {
          label: 'Accounts',
          isCopyable: false,
          value: (getFrom(latestAudit)('auditRecordCount').value || 0).toLocaleString(),
        },
      ].filter(Boolean)}
    />
  );
};
