import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { ProvidersParams, State, License } from '../../models';
import * as actions from './actions';
import * as types from './types';
import { FETCH_ORG_LICENSES_SUCCESS, FETCH_ORGS_SUCCESS } from 'store/Orgs/types';
import { FetchOrgLicensesSuccess, FetchOrgsSuccess } from 'store/Orgs/actions';

type LicensesState = State['licenses'];

const initialState = {
  licensesById: {},
  licensesByIdError: {},
  licensesByIdLoading: {},
  licensesByIdSaving: {},
  licensesByIdSavingError: {},
} as LicensesState;

export default function(
  state: LicensesState = initialState,
  action: actions.Action | FetchOrgLicensesSuccess | FetchOrgsSuccess,
): LicensesState {
  switch (action.type) {
    case types.FETCH_LICENSE:
      return {
        ...state,
        licensesByIdLoading: {
          ...state.licensesByIdLoading,
          [action.payload.id]: true,
        },
        licensesByIdError: {
          ...state.licensesByIdError,
          [action.payload.id]: null,
        },
      };
    case FETCH_ORGS_SUCCESS:
      return {
        ...state,
        licensesById: {
          ...state.licensesById,
          ...Object.keys(action.payload.relatedEntities.licenses)
            .map(orgId => action.payload.relatedEntities.licenses[orgId])
            .reduce((p, c) => {
              for (const license of c) {
                p[license.id] = license;
              }
              return p;
            }, {}),
        },
      };
    case FETCH_ORG_LICENSES_SUCCESS:
      return {
        ...state,
        licensesById: {
          ...state.licensesById,
          ...action.payload.items.reduce((p, c) => {
            p[c.id] = c;
            return p;
          }, {}),
        },
      };
    case types.FETCH_LICENSE_SUCCESS:
      return {
        ...state,
        licensesById: {
          ...state.licensesById,
          [action.payload.id]: action.payload.license,
        },
        licensesByIdLoading: {
          ...state.licensesByIdLoading,
          [action.payload.id]: false,
        },
      };
    case types.FETCH_LICENSE_FAIL:
      return {
        ...state,
        licensesByIdLoading: {
          ...state.licensesByIdLoading,
          [action.payload.id]: false,
        },
        licensesByIdError: {
          ...state.licensesByIdError,
          [action.payload.id]: action.payload.error,
        },
      };
    case types.FETCH_LICENSE_CANCEL:
      return {
        ...state,
        licensesByIdLoading: {
          ...state.licensesByIdLoading,
          [action.payload.id]: false,
        },
      };
    case types.SAVE_LICENSE:
      return {
        ...state,
        licensesByIdSaving: {
          ...state.licensesByIdSaving,
          [action.payload.id]: true,
        },
        licensesByIdError: {
          ...state.licensesByIdError,
          [action.payload.id]: null,
        },
      };
    case types.SAVE_LICENSE_SUCCESS:
      return {
        ...state,
        licensesByIdSaving: {
          ...state.licensesByIdSaving,
          [action.payload.id]: false,
        },
        licensesById: {
          ...state.licensesById,
          [action.payload.id]: action.payload.license,
        },
      };
    case types.SAVE_LICENSE_FAIL:
      return {
        ...state,
        licensesByIdSaving: {
          ...state.licensesByIdSaving,
          [action.payload.id]: false,
        },
        licensesByIdError: {
          ...state.licensesByIdError,
          [action.payload.id]: action.payload.error,
        },
      };
    case types.SAVE_LICENSE_CANCEL:
      return {
        ...state,
        licensesByIdSaving: {
          ...state.licensesByIdSaving,
          [action.payload.id]: false,
        },
      };
    case types.CREATE_LICENSE:
      return {
        ...state,
        creating: true,
        creatingError: null,
      };
    case types.CREATE_LICENSE_SUCCESS:
      return {
        ...state,
        creating: false,
        licensesById: {
          ...state.licensesById,
          [action.payload.id]: action.payload,
        },
      };
    case types.CREATE_LICENSE_FAIL:
      return {
        ...state,
        creating: false,
        creatingError: action.payload,
      };
    case types.CREATE_LICENSE_CANCEL:
      return {
        ...state,
        creating: false,
      };
    default:
      return state;
  }
}

// action creators
export const fetchLicense = (id: string): actions.FetchLicense => ({
  type: types.FETCH_LICENSE,
  payload: { id },
});

export const saveLicense = (id: string, license: License): actions.SaveLicense => ({
  type: types.SAVE_LICENSE,
  payload: { id, license },
});

export const createLicense = (license: License): actions.CreateLicense => ({
  type: types.CREATE_LICENSE,
  payload: license,
});

// epics
const fetchLicenseEpic: HyperEpic<actions.FetchLicenseActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_LICENSE).pipe(
    switchMap((action: actions.FetchLicense) =>
      api.get(`/api/licenses/${action.payload.id}`).pipe(
        map(data => ({
          type: types.FETCH_LICENSE_SUCCESS,
          payload: { id: action.payload.id, license: data.response },
        })),
        takeUntil(action$.ofType(types.FETCH_LICENSE_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_LICENSE_FAIL,
            payload: { id: action.payload.id, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

const saveLicenseEpic: HyperEpic<actions.SaveLicenseActions> = (action$, state$, { api }) =>
  action$.ofType(types.SAVE_LICENSE).pipe(
    switchMap((action: actions.SaveLicense) =>
      api.put(`/api/licenses/${action.payload.id}`, action.payload.license).pipe(
        map(data => ({
          type: types.SAVE_LICENSE_SUCCESS,
          payload: { id: action.payload.id, license: data.response },
        })),
        takeUntil(action$.ofType(types.SAVE_LICENSE_CANCEL)),
        catchError(error =>
          of({
            type: types.SAVE_LICENSE_FAIL,
            payload: { id: action.payload.id, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

const createLicenseEpic: HyperEpic<actions.CreateLicenseActions> = (action$, state$, { api }) =>
  action$.ofType(types.CREATE_LICENSE).pipe(
    switchMap((action: actions.CreateLicense) =>
      api.post(`/api/licenses`, action.payload).pipe(
        map(data => ({
          type: types.CREATE_LICENSE_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.CREATE_LICENSE_CANCEL)),
        catchError(error =>
          of({
            type: types.CREATE_LICENSE_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

export const licensesEpics = [fetchLicenseEpic, saveLicenseEpic, createLicenseEpic];
