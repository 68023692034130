import * as React from 'react';
import { Link } from 'react-router-dom';

import classes from './styles.module.scss';

interface Props {
  links: (
    | {
        label: string;
        path: string;
      }
    | {
        label: React.ReactNode;
        onClick: () => void;
      }
  )[];
}

const Linklist = ({ links }: Props) => (
  <ul className={classes.linkList}>
    {links
      .filter(l => !!l)
      .map((l, i) =>
        l['onClick'] != null ? (
          <li key={i}>
            <a onClick={l['onClick']}>{l.label}</a>
          </li>
        ) : (
          <li key={l['path']}>
            <Link to={l['path']}>{l.label}</Link>
          </li>
        ),
      )}
  </ul>
);

export default Linklist;
