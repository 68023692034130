import { History } from 'history';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { applyMiddleware, combineReducers, compose, createStore, ReducersMapObject, Store } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';

import { State } from './models';
import Api from './services/Api';
import Auth from './services/Auth';
import { reducers, rootEpic } from './store';

function buildRootReducer(allReducers: ReducersMapObject) {
  return combineReducers<State>(Object.assign({}, allReducers, { routing: routerReducer }));
}

export default function configureStore(history: History, initialState?: State) {
  const auth = new Auth();
  const api = new Api(auth);

  // Build middleware. These are functions that can process the actions before they reach the store.
  const epicMiddleware = createEpicMiddleware({ dependencies: { api } });
  const epic$ = new BehaviorSubject(rootEpic);

  // If devTools is installed, connect to it
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window['__REDUX_DEVTOOLS_EXTENSION__']) {
    enhancers.push(window['__REDUX_DEVTOOLS_EXTENSION__']());
  }

  const createStoreWithMiddleware = compose(
    applyMiddleware(epicMiddleware, routerMiddleware(history)),
    ...enhancers,
  )(createStore);

  // Combine all reducers and instantiate the app-wide store instance
  const allReducers = buildRootReducer(reducers);
  const store = createStoreWithMiddleware(allReducers, initialState) as Store<State>;

  epicMiddleware.run(rootEpic);

  if (module.hot) {
    module.hot.accept('./store', () => {
      const nextRootEpic = require('./store').rootEpic;
      epic$.next(nextRootEpic);
    });
  }

  return store;
}
