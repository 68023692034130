import { Card } from '@hyperfish/fishfood';
import * as React from 'react';
import { connect } from 'react-redux';

import { clearAudits, fetchAudits, nextPage, prevPage, firstPage, lastPage } from '../../store/Audits';
import { AuditsTable } from '../../components/AuditsTable';

const connector = connect(state => state.audits, {
  clearAudits,
  fetchAudits,
  nextPage,
  prevPage,
  firstPage,
  lastPage,
});

type Props = typeof connector['props'];

@connector
export class AuditsAll extends React.Component<Props> {
  componentDidMount() {
    this.props.clearAudits();
    this.props.fetchAudits({});
  }

  render() {
    const {
      audits,
      isLoading,
      error,
      nextPage,
      prevPage,
      firstPage,
      lastPage,
      pagination,
      relatedEntities,
    } = this.props;

    return (
      <AuditsTable
        audits={audits}
        isLoading={isLoading}
        orgs={relatedEntities && relatedEntities.orgs}
        pagerProps={{
          pagination,
          nextPage,
          prevPage,
          firstPage,
          lastPage,
        }}
      />
    );
  }
}
