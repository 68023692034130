import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import { State } from '../../models';
import * as actions from './actions';
import * as types from './types';
import { AuthInteropTableRow, AuthInteropUser, convertAuthInteropRowToId } from 'models/api/AuthInterop';

type SystemState = State['system'];

const initialState = {
  authInteropUserById: {},
  authInteropUsersLoading: false,
  authInteropUsersError: {},
  authInteropUserByIdSaving: {},
  authInteropUserByIdSavingError: {},
  authInteropUserByIdDeleting: {},
  authInteropUserByIdDeletingError: {},
  authInteropAnalysis: null,
  authInteropAnalysisLoading: false,
  authInteropAnalysisError: null,
} as SystemState;

export default function(state: SystemState = initialState, action: actions.SystemActions): SystemState {
  let id: string;

  switch (action.type) {
    case types.FETCH_AUTH_INTEROP_USERS:
      return {
        ...state,
        authInteropUsersLoading: true,
      };
    case types.FETCH_AUTH_INTEROP_USERS_SUCCESS:
      const newUsersPostLoad = action.payload.reduce<{ [id: string]: AuthInteropUser }>((p, c) => {
        const id = convertAuthInteropRowToId(c);
        const r: AuthInteropUser = { id, ...c };
        p[id] = r;
        return p;
      }, {});

      return {
        ...state,
        authInteropUsersLoading: false,
        authInteropUserById: newUsersPostLoad,
        authInteropUsersError: null,
      };
    case types.FETCH_AUTH_INTEROP_USERS_FAIL:
      return {
        ...state,
        authInteropUsersLoading: false,
        authInteropUsersError: action.payload.error,
      };
    case types.FETCH_AUTH_INTEROP_USERS_CANCEL:
      return {
        ...state,
        authInteropUsersLoading: false,
        authInteropUsersError: null,
      };
    case types.SAVE_AUTH_INTEROP_USER:
      return {
        ...state,
        authInteropUserByIdSaving: {
          ...state.authInteropUserByIdSaving,
          [convertAuthInteropRowToId(action.payload)]: true,
        },
      };
    case types.SAVE_AUTH_INTEROP_USER_SUCCESS:
      id = convertAuthInteropRowToId(action.payload);

      return {
        ...state,
        authInteropUserByIdSaving: {
          ...state.authInteropUserByIdSaving,
          [id]: false,
        },
        authInteropUserById: {
          ...state.authInteropUserById,
          [id]: { id, ...action.payload },
        },
        authInteropUserByIdSavingError: null,
      };
    case types.SAVE_AUTH_INTEROP_USER_FAIL:
      id = convertAuthInteropRowToId(action.payload);

      return {
        ...state,
        authInteropUserByIdSaving: {
          ...state.authInteropUserByIdSaving,
          [id]: false,
        },
        authInteropUserByIdSavingError: {
          ...state.authInteropUserByIdSavingError,
          [id]: action.payload.error || true,
        },
      };
    case types.SAVE_AUTH_INTEROP_USER_CANCEL:
      return {
        ...state,
        authInteropUserByIdSaving: {
          ...state.authInteropUserByIdSaving,
          [convertAuthInteropRowToId(action.payload)]: false,
        },
      };
    case types.DELETE_AUTH_INTEROP_USER:
      return {
        ...state,
        authInteropUserByIdDeleting: {
          ...state.authInteropUserByIdDeleting,
          [convertAuthInteropRowToId(action.payload)]: true,
        },
      };
    case types.DELETE_AUTH_INTEROP_USER_SUCCESS:
      const authInteropUserById = state.authInteropUserById;
      delete authInteropUserById[convertAuthInteropRowToId(action.payload)];

      return {
        ...state,
        authInteropUserByIdDeleting: {
          ...state.authInteropUserByIdDeleting,
          [convertAuthInteropRowToId(action.payload)]: false,
        },
        authInteropUserById,
        authInteropUserByIdDeletingError: null,
      };
    case types.DELETE_AUTH_INTEROP_USER_FAIL:
      return {
        ...state,
        authInteropUserByIdDeleting: {
          ...state.authInteropUserByIdDeleting,
          [convertAuthInteropRowToId(action.payload)]: false,
        },
        authInteropUserByIdDeletingError: {
          ...state.authInteropUserByIdDeletingError,
          [convertAuthInteropRowToId(action.payload)]: action.payload.error || true,
        },
      };
    case types.DELETE_AUTH_INTEROP_USER_CANCEL:
      return {
        ...state,
        authInteropUserByIdDeleting: {
          ...state.authInteropUserByIdDeleting,
          [convertAuthInteropRowToId(action.payload)]: false,
        },
      };
    case types.FETCH_AUTH_INTEROP_ANALYSIS:
      return {
        ...state,
        authInteropUsersLoading: true,
      };
    case types.FETCH_AUTH_INTEROP_ANALYSIS_SUCCESS:
      return {
        ...state,
        authInteropAnalysis: action.payload,
        authInteropAnalysisLoading: false,
        authInteropAnalysisError: null,
      };
    case types.FETCH_AUTH_INTEROP_ANALYSIS_FAIL:
      return {
        ...state,
        authInteropAnalysisLoading: false,
        authInteropAnalysisError: action.payload.error,
      };
    case types.FETCH_AUTH_INTEROP_ANALYSIS_CANCEL:
      return {
        ...state,
        authInteropAnalysisLoading: false,
        authInteropAnalysisError: null,
      };
    default:
      return state;
  }
}

// action creators
export const fetchAuthInteropUsers = (): actions.FetchAuthInteropUsers => ({
  type: types.FETCH_AUTH_INTEROP_USERS,
});

export const saveAuthInteropUser = (body: AuthInteropTableRow) => ({
  type: types.SAVE_AUTH_INTEROP_USER,
  payload: body,
});

export const cancelSaveAuthInteropUser = (body: AuthInteropTableRow) => ({
  type: types.SAVE_AUTH_INTEROP_USER_CANCEL,
  payload: body,
});

export const deleteAuthInteropUser = (body: AuthInteropTableRow) => ({
  type: types.DELETE_AUTH_INTEROP_USER,
  payload: body,
});

export const fetchAuthInteropAnalysis = (): actions.FetchAuthInteropAnalysis => ({
  type: types.FETCH_AUTH_INTEROP_ANALYSIS,
});

// epics
const fetchAuthInteropUsersEpic: HyperEpic<actions.FetchAuthInteropUsersActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_AUTH_INTEROP_USERS).pipe(
    switchMap((action: actions.FetchAuthInteropUsers) =>
      api.get(`/api/authinterop/users`).pipe(
        map(data => ({
          type: types.FETCH_AUTH_INTEROP_USERS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_AUTH_INTEROP_USERS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_AUTH_INTEROP_USERS_FAIL,
            payload: { error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

const saveAuthInteropUserEpic: HyperEpic<actions.SaveAuthInteropUserActions> = (action$, state$, { api }) =>
  action$.ofType(types.SAVE_AUTH_INTEROP_USER).pipe(
    switchMap((action: actions.SaveAuthInteropUser) =>
      api.patch(`/api/authinterop/users`, action.payload).pipe(
        map(data => ({
          type: types.SAVE_AUTH_INTEROP_USER_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.SAVE_AUTH_INTEROP_USER_CANCEL)),
        catchError(error =>
          of({
            type: types.SAVE_AUTH_INTEROP_USER_FAIL,
            payload: { ...action.payload, error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

const deleteAuthInteropUserEpic: HyperEpic<actions.DeleteAuthInteropUserActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_AUTH_INTEROP_USER).pipe(
    switchMap((action: actions.DeleteAuthInteropUser) =>
      api
        .del(`/api/authinterop/users`, { body: { username: action.payload.username, region: action.payload.region } })
        .pipe(
          map(data => ({
            type: types.DELETE_AUTH_INTEROP_USER_SUCCESS,
            payload: action.payload,
          })),
          takeUntil(action$.ofType(types.DELETE_AUTH_INTEROP_USER_CANCEL)),
          catchError(error =>
            of({
              type: types.DELETE_AUTH_INTEROP_USER_FAIL,
              payload: { ...action.payload, error: error.xhr.response },
            }),
          ),
        ),
    ),
  );

const fetchAuthInteropAnalysisEpic: HyperEpic<actions.FetchAuthInteropAnalysisActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_AUTH_INTEROP_ANALYSIS).pipe(
    switchMap((action: actions.FetchAuthInteropAnalysis) =>
      api.get(`/api/authinterop/analysis`).pipe(
        map(data => ({
          type: types.FETCH_AUTH_INTEROP_ANALYSIS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_AUTH_INTEROP_ANALYSIS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_AUTH_INTEROP_ANALYSIS_FAIL,
            payload: { error: error.xhr.response },
          }),
        ),
      ),
    ),
  );

export const systemEpics = [
  fetchAuthInteropUsersEpic,
  saveAuthInteropUserEpic,
  deleteAuthInteropUserEpic,
  fetchAuthInteropAnalysisEpic,
];
