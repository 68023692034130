export function deserializeKeys<T>(item: T, keys: (keyof T)[]): T {
  for (const key of keys) {
    try {
      item[key] = JSON.parse(item[key] as any);
    } catch {
      item[key] = {} as any;
    }
  }
  return item;
}

export function serializeKeys<T>(item: T, keys: (keyof T)[]): T {
  for (const key of keys) {
    try {
      item[key] = JSON.stringify(item[key]) as any;
    } catch {
      item[key] = '' as any;
    }
  }
  return item;
}
