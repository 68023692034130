import { Button, Card, LoadingSplash, Modal, StyleUtil } from '@hyperfish/fishfood';
import * as React from 'react';
import { connect } from 'react-redux';

import { Copy } from '../../components/Copy';
import Linklist from '../../components/Linklist';
import { clearLicensingLink, fetchLicensingLink } from '../../store/Links';
import { LicenseEditModal } from '../../components/LicenseEditModal';
import { License, GetInitialLicense } from '../../models';
import Auth from '../../services/Auth';

import classes from './styles.module.scss';

const connector = connect(
  state => ({
    link: state.links.licensingLink,
    linkIsLoading: state.links.licensingLinkIsLoading,
    linkError: state.links.licensingLinkError,
  }),
  {
    clearLicensingLink,
    fetchLicensingLink,
  },
);

type Props = typeof connector['props'];

interface State {
  dirtyLicense?: License;
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.createNewLicense = this.createNewLicense.bind(this);
  }

  public render() {
    const { link, linkIsLoading, linkError } = this.props;
    const { dirtyLicense } = this.state;

    return (
      <div>
        <Card>
          <h1>What would you like to do?</h1>
          <Linklist
            links={[
              { path: '/orgs', label: 'Find an Org' },
              { path: '/devices', label: 'View Devices' },
              { path: '/reports/lite-orgs', label: 'View Lite Orgs Report' },
              Auth.doesUserHaveScope('create-license') && {
                label: 'Generate a Licensing Link',
                onClick: this.createNewLicense,
              },
            ]}
          />
        </Card>
        {dirtyLicense != null && !link && !linkIsLoading && this.renderCreateLicenseModal()}
        {(link != null || linkIsLoading || linkError != null) && this.renderLinkModal()}
      </div>
    );
  }

  createNewLicense() {
    this.setState({
      dirtyLicense: GetInitialLicense(),
    });
  }

  renderCreateLicenseModal() {
    const { fetchLicensingLink } = this.props;
    const { dirtyLicense } = this.state;

    if (!dirtyLicense) {
      return null;
    }

    const closeModal = () => this.setState({ dirtyLicense: null });
    const setValue = <T extends keyof License>(key: T, value: License[T]) => {
      this.setState(({ dirtyLicense }) => ({
        dirtyLicense: {
          ...dirtyLicense,
          [key]: value,
        },
      }));
    };
    const onSave = () => {
      fetchLicensingLink({ payload: this.state.dirtyLicense, type: 'CREATE_LICENSE' });
      closeModal();
    };

    return (
      <LicenseEditModal
        dirtyLicense={dirtyLicense}
        onCancel={closeModal}
        onEdit={setValue}
        onSave={onSave}
        isCreating={true}
      />
    );
  }

  renderLinkModal() {
    const { clearLicensingLink, link, linkError, linkIsLoading } = this.props;

    return (
      <Modal onClose={clearLicensingLink} style={StyleUtil.styles.modals.newPadding}>
        <h1 style={StyleUtil.styles.modals.header}>Licensing Link</h1>

        {linkIsLoading && <LoadingSplash />}

        {linkError && <p style={{ color: StyleUtil.colors.red }}>Error getting link</p>}

        {link && (
          <div className={classes.linkContainer}>
            <div className={classes.linkWrapper}>
              <a href={link.href} target="_blank" rel="noopener noreferrer">
                {link.href}
              </a>
            </div>
            <Copy text={link.href} />
          </div>
        )}

        <div style={StyleUtil.styles.modals.buttonContainer}>
          <Button size="small" type="primary" onClick={clearLicensingLink}>
            DONE
          </Button>
        </div>
      </Modal>
    );
  }
}

export default connector(Home) as typeof Home;
