import * as React from 'react';

import { Copy } from '../Copy';
import { Spinner } from '../Spinner';

import classes from './styles.module.scss';

interface SummaryRow {
  label: string;
  value: React.ReactNode;
  isCopyable?: boolean;
  isLoading?: boolean;
}

interface Props {
  rows: SummaryRow[];
}

export const SummaryTable = ({ rows }: Props) => {
  return (
    <table className={classes.summaryTable}>
      <tbody>
        {rows &&
          rows.map((r, i) => (
            <tr key={i}>
              <td>{r.label}:</td>
              <td colSpan={r.isCopyable && typeof r.value === 'string' ? 1 : 2}>
                {r.isLoading ? <Spinner /> : r.value}
              </td>
              {r.isCopyable && typeof r.value === 'string' && (
                <td>
                  <Copy text={r.value} />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
