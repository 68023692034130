import * as queryString from 'query-string';
import { HyperEpic } from 'redux-observable';
import { concat, of } from 'rxjs';
import { catchError, debounceTime, filter, flatMap, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';

import { Audience, Org, Permission, Setting, SettingsPost, State, License } from '../../models';
import { deserializeKeys, serializeKeys } from '../../utils/jsonUtil';
import { mapArrayToObject } from '../../utils/mapArrayToObject';
import * as actions from './actions';
import * as types from './types';
import { FETCH_ORGS_FAIL, SAVE_ORG_FAIL } from './types';
import { CREATE_LICENSE_SUCCESS } from 'store/Licenses/types';
import { CreateLicenseSuccess } from 'store/Licenses/actions';
import { exportToCsv } from 'utils/fileUtil';
import { getDateToFileName } from 'utils/dateUtil';

type OrgsState = State['orgs'];

// helpers
const deserializePermission = (d: Permission) => deserializeKeys(d, ['config', 'definition', 'meta']);
const serializePermission = (d: Permission) => serializeKeys(d, ['config', 'definition', 'meta']);

const deserializeSetting = (s: Setting) => deserializeKeys(s, ['settingValue']);
const serializeSetting = (s: Setting) => serializeKeys(s, ['settingValue']);

const deserializeAudience = (a: Audience) => deserializeKeys(a, ['definition', 'lastModifiedMeta']);
const serializeAudience = (a: Audience) => serializeKeys(a, ['definition', 'lastModifiedMeta']);

const deserializeSettingDictionary = (dict: { [key: string]: Setting[] }) => {
  const newDict = {};
  Object.keys(dict).forEach(key => (newDict[key] = dict[key].map(deserializeSetting)));
  return newDict;
};

const initialState = {
  audiencesById: {},
  audiencesByIdError: {},
  audiencesByIdLoading: {},
  deleteProgress: {},
  fieldsById: {},
  fieldsByIdError: {},
  fieldsByIdLoading: {},
  isSavingById: {},
  licenseIdsById: {},
  licensesByIdLoading: {},
  latestAuditById: {},
  orgsById: {},
  orgsByIdLoading: {},
  permissionsById: {},
  permissionsByIdError: {},
  permissionsByIdLoading: {},
  settingsById: {},
  settingsByIdError: {},
  settingsByIdLoading: {},
} as State['orgs'];

export default function(state: OrgsState = initialState, action: actions.Action | CreateLicenseSuccess): OrgsState {
  switch (action.type) {
    case types.FETCH_ORGS:
    case types.FETCH_ORGS_PAGE:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_ORGS_SUCCESS:
      return {
        ...state,
        orgIds: action.payload.items.map(({ id }) => id),
        orgsById: mapArrayToObject(action.payload.items),
        pagination: action.payload.pagination,
        isLoading: false,
        settingsById: {
          ...state.settingsById,
          ...deserializeSettingDictionary(action.payload.relatedEntities.modes),
        },
        licenseIdsById: {
          ...state.licenseIdsById,
          ...Object.keys(action.payload.relatedEntities.licenses).reduce((p, c) => {
            p[c] = action.payload.relatedEntities.licenses[c].map(l => l.id);
            return p;
          }, {}),
        },
        latestAuditById: {
          ...state.latestAuditById,
          ...action.payload.relatedEntities.latestAudits,
        },
      };

    case types.EXPORT_ORGS:
      return { ...state, isExporting: true };
    case types.EXPORT_ORGS_SUCCESS:
    case types.EXPORT_ORGS_FAIL:
    case types.EXPORT_ORGS_CANCEL:
      return { ...state, isExporting: false };
    case types.FETCH_ORG:
      return {
        ...state,
        orgsByIdLoading: { ...state.orgsByIdLoading, [action.payload]: true },
      };
    case types.FETCH_ORG_SUCCESS:
      return {
        ...state,
        orgsByIdLoading: {
          ...state.orgsByIdLoading,
          [action.payload.id]: false,
        },
        orgsById: { ...state.orgsById, [action.payload.id]: action.payload },
      };
    case types.FETCH_ORG_LICENSES:
      return {
        ...state,
        licensesByIdLoading: { ...state.licensesByIdLoading, [action.payload]: true },
      };
    case types.FETCH_ORG_LICENSES_SUCCESS:
      return {
        ...state,
        licensesByIdLoading: { ...state.licensesByIdLoading, [action.payload.id]: false },
        licenseIdsById: {
          ...state.licenseIdsById,
          [action.payload.id]: action.payload.items.map(l => l.id),
        },
      };
    case CREATE_LICENSE_SUCCESS:
      return {
        ...state,
        licenseIdsById: {
          ...state.licenseIdsById,
          [action.payload.antreaOrgId]: [...state.licenseIdsById[action.payload.antreaOrgId], action.payload.id],
        },
      };
    case types.FETCH_ORG_FIELDS:
      return {
        ...state,
        fieldsById: { ...state.fieldsById, [action.payload]: null },
        fieldsByIdLoading: {
          ...state.fieldsByIdLoading,
          [action.payload]: true,
        },
      };
    case types.FETCH_ORG_FIELDS_SUCCESS:
      return {
        ...state,
        fieldsByIdLoading: {
          ...state.fieldsByIdLoading,
          [action.payload.id]: false,
        },
        fieldsByIdError: {
          ...state.fieldsByIdError,
          [action.payload.id]: null,
        },
        fieldsById: {
          ...state.fieldsById,
          [action.payload.id]: action.payload.items,
        },
      };
    case types.FETCH_ORG_FIELDS_FAIL:
      return {
        ...state,
        fieldsByIdLoading: {
          ...state.fieldsByIdLoading,
          [action.payload.id]: false,
        },
        fieldsByIdError: {
          ...state.fieldsByIdError,
          [action.payload.id]: action.payload.error || true,
        },
      };
    case types.FETCH_ORG_FIELDS_CANCEL:
      return {
        ...state,
        fieldsByIdLoading: {
          ...state.fieldsByIdLoading,
          [action.payload]: false,
        },
      };
    case types.SET_ORG_COLLECTION_BY_ATTRIBUTE:
      return {
        ...state,
        collectionByAttributeSaving: true,
        collectionByAttributeError: null,
      };
    case types.SET_ORG_COLLECTION_BY_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        collectionByAttributeSaving: false,
      };
    case types.SET_ORG_COLLECTION_BY_ATTRIBUTE_FAIL:
      return {
        ...state,
        collectionByAttributeSaving: false,
        collectionByAttributeError: action.payload.error || true,
      };
    case types.SET_ORG_COLLECTION_BY_ATTRIBUTE_CANCEL:
      return {
        ...state,
        collectionByAttributeSaving: false,
      };
    case types.SAVE_ORG:
      return {
        ...state,
        isSavingById: { ...state.isSavingById, [action.payload.id]: true },
      };
    case types.SAVE_ORG_SUCCESS:
      return {
        ...state,
        isSavingById: { ...state.isSavingById, [action.payload.id]: false },
        orgsById: { ...state.orgsById, [action.payload.id]: action.payload },
      };
    case types.SAVE_ORG_FAIL:
      return {
        ...state,
        isSavingById: { ...state.isSavingById, [action.payload.id]: false },
        savingError: action.payload || true,
      };
    case types.DELETE_ORG_START:
      return {
        ...state,
        deleteProgress: { id: action.payload },
      };
    case types.DELETE_ORG_PROGRESS:
      return {
        ...state,
        deleteProgress: {
          ...state.deleteProgress,
          [`${action.payload.task}Result`]: action.payload.result,
        },
      };
    case types.DELETE_ORG_END:
      return {
        ...state,
        deleteProgress: initialState.deleteProgress,
      };
    case types.EDIT_ORG_START:
      return {
        ...state,
        dirtyOrg: JSON.parse(JSON.stringify(state.orgsById[action.payload])),
      };
    case types.EDIT_ORG_END:
      return {
        ...state,
        dirtyOrg: null,
      };
    case types.EDIT_ORG:
      return {
        ...state,
        dirtyOrg: {
          ...state.dirtyOrg,
          [action.payload.key]: action.payload.value,
        },
      };
    case types.FETCH_PERMISSIONS:
      return {
        ...state,
        permissionsByIdLoading: {
          ...state.permissionsByIdLoading,
          [action.payload]: true,
        },
        permissionsByIdError: {
          ...state.permissionsByIdError,
          [action.payload]: null,
        },
      };
    case types.FETCH_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissionsByIdLoading: {
          ...state.permissionsByIdLoading,
          [action.payload.orgId]: false,
        },
        permissionsById: {
          ...state.permissionsById,
          [action.payload.orgId]: action.payload.permissions.map(deserializePermission),
        },
      };
    case types.FETCH_PERMISSIONS_FAIL:
      return {
        ...state,
        permissionsByIdLoading: {
          ...state.permissionsByIdLoading,
          [action.payload.orgId]: false,
        },
        permissionsByIdError: {
          ...state.permissionsByIdError,
          [action.payload.orgId]: action.payload.error || true,
        },
      };
    case types.FETCH_PERMISSIONS_CANCEL:
      return {
        ...state,
        permissionsByIdLoading: {
          ...state.permissionsByIdLoading,
          [action.payload]: false,
        },
      };
    case types.FETCH_ORG_SETTINGS:
      return {
        ...state,
        settingsByIdLoading: {
          ...state.settingsByIdLoading,
          [action.payload]: true,
        },
        settingsByIdError: {
          ...state.settingsByIdError,
          [action.payload]: null,
        },
      };
    case types.FETCH_ORG_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsByIdLoading: {
          ...state.settingsByIdLoading,
          [action.payload.orgId]: false,
        },
        settingsById: {
          ...state.settingsById,
          [action.payload.orgId]: action.payload.settings.map(deserializeSetting),
        },
      };
    case types.FETCH_ORG_SETTINGS_FAIL:
      return {
        ...state,
        settingsByIdLoading: {
          ...state.settingsByIdLoading,
          [action.payload.orgId]: false,
        },
        settingsByIdError: {
          ...state.settingsByIdError,
          [action.payload.orgId]: action.payload.error || true,
        },
      };
    case types.FETCH_ORG_SETTINGS_CANCEL:
      return {
        ...state,
        settingsByIdLoading: {
          ...state.settingsByIdLoading,
          [action.payload]: false,
        },
      };

    case types.SAVE_ORG_SETTINGS:
      return {
        ...state,
        settingsIsSaving: true,
        settingsSavingError: null,
      };
    case types.SAVE_ORG_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsIsSaving: false,
        settingsById: {
          ...state.settingsById,
          [action.payload.orgId]: action.payload.settings.map(deserializeSetting),
        },
      };
    case types.SAVE_ORG_SETTINGS_FAIL:
      return {
        ...state,
        settingsIsSaving: false,
        settingsSavingError: action.payload,
      };
    case types.SAVE_ORG_SETTINGS_CANCEL:
      return {
        ...state,
        settingsIsSaving: false,
      };

    case types.FETCH_ORG_AUDIENCES:
      return {
        ...state,
        audiencesByIdLoading: {
          ...state.audiencesByIdLoading,
          [action.payload]: true,
        },
        audiencesByIdError: {
          ...state.audiencesByIdError,
          [action.payload]: null,
        },
      };
    case types.FETCH_ORG_AUDIENCES_SUCCESS:
      return {
        ...state,
        audiencesByIdLoading: {
          ...state.audiencesByIdLoading,
          [action.payload.orgId]: false,
        },
        audiencesById: {
          ...state.audiencesById,
          [action.payload.orgId]: action.payload.audiences.map(deserializeAudience),
        },
      };
    case types.FETCH_ORG_AUDIENCES_FAIL:
      return {
        ...state,
        audiencesByIdLoading: {
          ...state.audiencesByIdLoading,
          [action.payload.orgId]: false,
        },
        audiencesByIdError: {
          ...state.audiencesByIdError,
          [action.payload.orgId]: action.payload.error || true,
        },
      };
    case types.FETCH_ORG_AUDIENCES_CANCEL:
      return {
        ...state,
        audiencesByIdLoading: {
          ...state.audiencesByIdLoading,
          [action.payload]: false,
        },
      };
    default:
      return state;
    // The following line guarantees that every action in the KnownAction union has been covered by a case above
    // const exhaustiveCheck: never = action;
  }
}

// action creators
export const exportOrgs = (payload: actions.ExportOrgs['payload'] = {}): actions.ExportOrgs => ({
  type: types.EXPORT_ORGS,
  payload,
});

export const fetchOrgs = (payload: actions.FetchOrgs['payload'] = {}): actions.FetchOrgs => ({
  type: types.FETCH_ORGS,
  payload,
});

export const nextPage = (): actions.FetchOrgsPage => ({
  type: types.FETCH_ORGS_PAGE,
  payload: 'next',
});
export const prevPage = (): actions.FetchOrgsPage => ({
  type: types.FETCH_ORGS_PAGE,
  payload: 'prev',
});
export const firstPage = (): actions.FetchOrgsPage => ({
  type: types.FETCH_ORGS_PAGE,
  payload: 'first',
});
export const lastPage = (): actions.FetchOrgsPage => ({
  type: types.FETCH_ORGS_PAGE,
  payload: 'last',
});

export const fetchOrg = (orgId: string): actions.FetchOrg => ({
  type: types.FETCH_ORG,
  payload: orgId,
});

export const fetchLicenses = (orgId: string): actions.FetchOrgLicenses => ({
  type: types.FETCH_ORG_LICENSES,
  payload: orgId,
});

export const fetchFields = (orgId: string): actions.FetchOrgFields => ({
  type: types.FETCH_ORG_FIELDS,
  payload: orgId,
});

export const setCollectionByAttribute = (
  orgId: string,
  field: string,
  enabled?: boolean,
): actions.SetOrgCollectionByAttribute => ({
  type: types.SET_ORG_COLLECTION_BY_ATTRIBUTE,
  payload: { orgId, field, enabled },
});

export const saveOrg = (org: Org): actions.SaveOrg => ({
  type: types.SAVE_ORG,
  payload: org,
});
export const startEdit = (orgId: string): actions.EditOrgStart => ({
  type: types.EDIT_ORG_START,
  payload: orgId,
});
export const startDelete = (orgId: string): actions.DeleteOrgStart => ({
  type: types.DELETE_ORG_START,
  payload: orgId,
});
export const endDelete = (): actions.DeleteOrgEnd => ({
  type: types.DELETE_ORG_END,
});
export const endEdit = (): actions.EditOrgEnd => ({
  type: types.EDIT_ORG_END,
});
export const editOrg = <T extends keyof Org>(key: T, value: Org[T]): actions.EditOrg => ({
  type: 'EDIT_ORG',
  payload: { key, value },
});

export const fetchPermissions = (orgId: string): actions.FetchPermissions => ({
  type: types.FETCH_PERMISSIONS,
  payload: orgId,
});

export const fetchSettings = (orgId: string): actions.FetchOrgSettings => ({
  type: types.FETCH_ORG_SETTINGS,
  payload: orgId,
});

export const saveSettings = (orgId: string, settings: SettingsPost): actions.SaveOrgSettings => ({
  type: types.SAVE_ORG_SETTINGS,
  payload: { orgId, settings },
});

export const saveSettingsCancel = (): actions.SaveOrgSettingsCancel => ({
  type: types.SAVE_ORG_SETTINGS_CANCEL,
});

export const fetchAudiences = (orgId: string): actions.FetchOrgAudiences => ({
  type: types.FETCH_ORG_AUDIENCES,
  payload: orgId,
});

// epics
const exportOrgsEpic: HyperEpic<actions.ExportOrgsActions> = (action$, state$, { api }) =>
  action$.ofType(types.EXPORT_ORGS).pipe(
    debounceTime(500),
    switchMap((action: actions.ExportOrgs) =>
      api.get(`/api/orgs/export?${queryString.stringify(action.payload as any)}`).pipe(
        map(data => {
          exportToCsv('Orgs_' + getDateToFileName() + '.csv', data.response);
          return {
            type: types.EXPORT_ORGS_SUCCESS,
            payload: 'success',
          };
        }),
        takeUntil(action$.ofType(types.EXPORT_ORGS_CANCEL)),
        catchError(error =>
          of({
            type: types.EXPORT_ORGS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchOrgsEpic: HyperEpic<actions.FetchOrgsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORGS).pipe(
    debounceTime(500),
    switchMap((action: actions.FetchOrgs) =>
      api.get(`/api/orgs?${queryString.stringify(action.payload as any)}`).pipe(
        map(data => ({
          type: types.FETCH_ORGS_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_ORGS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORGS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchOrgsPageEpic: HyperEpic<actions.FetchOrgsPage | actions.FetchOrgsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORGS_PAGE).pipe(
    switchMap((action: actions.FetchOrgsPage) =>
      api.get(state$.value.orgs.pagination.links[action.payload]).pipe(
        map(({ response: payload }) => ({
          type: types.FETCH_ORGS_SUCCESS,
          payload,
        })),
        takeUntil(action$.ofType(types.FETCH_ORGS_CANCEL)),
        catchError(error =>
          of({
            type: FETCH_ORGS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchOrgEpic: HyperEpic<actions.FetchOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORG).pipe(
    mergeMap((action: actions.FetchOrg) =>
      api.get(`/api/orgs/${action.payload}`).pipe(
        map(data => ({
          type: types.FETCH_ORG_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_ORG_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORG_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchOrgLicensesEpic: HyperEpic<actions.FetchOrgLicensesActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORG_LICENSES).pipe(
    mergeMap((action: actions.FetchOrgLicenses) =>
      api.get(`/api/orgs/${action.payload}/licenses`).pipe(
        map(data => ({
          type: types.FETCH_ORG_LICENSES_SUCCESS,
          payload: { id: action.payload, items: data.response } as any,
        })),
        takeUntil(action$.ofType(types.FETCH_ORG_LICENSES_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORG_LICENSES_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchOrgFieldsEpic: HyperEpic<actions.FetchOrgFieldsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORG_FIELDS).pipe(
    switchMap((action: actions.FetchOrgFields) =>
      api.get(`/api/orgs/${action.payload}/fields`).pipe(
        map(data => ({
          type: types.FETCH_ORG_FIELDS_SUCCESS,
          payload: { id: action.payload, items: data.response } as any,
        })),
        takeUntil(action$.ofType(types.FETCH_ORG_FIELDS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORG_FIELDS_FAIL,
            payload: { id: action.payload, error: error.xhr.response } as any,
          }),
        ),
      ),
    ),
  );

const setCollectionByAttributeEpic: HyperEpic<actions.SetOrgCollectionByAttributeActions> = (
  action$,
  state$,
  { api },
) =>
  action$.ofType(types.SET_ORG_COLLECTION_BY_ATTRIBUTE).pipe(
    switchMap((action: actions.SetOrgCollectionByAttribute) =>
      api
        .post(`/api/orgs/${action.payload.orgId}/setcollectionattribute`, {
          field: action.payload.field,
          enabled: action.payload.enabled,
        })
        .pipe(
          map(data => ({
            type: types.SET_ORG_COLLECTION_BY_ATTRIBUTE_SUCCESS,
            payload: action.payload.orgId as any,
          })),
          takeUntil(action$.ofType(types.SET_ORG_COLLECTION_BY_ATTRIBUTE_CANCEL)),
          catchError(error =>
            of({
              type: types.SET_ORG_COLLECTION_BY_ATTRIBUTE_FAIL,
              payload: {
                orgId: action.payload.orgId,
                error: error.xhr.response,
              } as any,
            }),
          ),
        ),
    ),
  );

const saveOrgEpic: HyperEpic<actions.SaveOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.SAVE_ORG).pipe(
    mergeMap((action: actions.SaveOrg) =>
      api.put(`/api/orgs/${action.payload.id}`, action.payload).pipe(
        map(data => ({ type: types.SAVE_ORG_SUCCESS, payload: data.response })),
        takeUntil(action$.ofType(types.SAVE_ORG_CANCEL)),
        catchError(error =>
          of({
            type: SAVE_ORG_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const deleteOrgLicensesEpic: HyperEpic<actions.DeleteOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_ORG_START).pipe(
    mergeMap((action: actions.DeleteOrgStart) =>
      api.del(`/api/orgs/${action.payload}/licenses`).pipe(
        map(
          data =>
            ({
              type: types.DELETE_ORG_PROGRESS,
              payload: { task: 'licenses', result: data },
            } as actions.DeleteOrgProgress),
        ),
        catchError(error =>
          of({
            type: types.DELETE_ORG_PROGRESS,
            payload: { task: 'licenses', result: error.xhr },
          } as actions.DeleteOrgProgress),
        ),
      ),
    ),
  );

const deleteOrgSchedulesEpic: HyperEpic<actions.DeleteOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_ORG_START).pipe(
    mergeMap((action: actions.DeleteOrgStart) =>
      api.del(`/api/orgs/${action.payload}/schedules`).pipe(
        map(
          data =>
            ({
              type: types.DELETE_ORG_PROGRESS,
              payload: { task: 'schedules', result: data },
            } as actions.DeleteOrgProgress),
        ),
        catchError(error =>
          of({
            type: types.DELETE_ORG_PROGRESS,
            payload: { task: 'schedules', result: error.xhr },
          } as actions.DeleteOrgProgress),
        ),
      ),
    ),
  );

const deleteOrgBroadcastsEpic: HyperEpic<actions.DeleteOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_ORG_START).pipe(
    mergeMap((action: actions.DeleteOrgStart) =>
      api.del(`/api/orgs/${action.payload}/broadcasts`).pipe(
        map(
          data =>
            ({
              type: types.DELETE_ORG_PROGRESS,
              payload: { task: 'broadcasts', result: data },
            } as actions.DeleteOrgProgress),
        ),
        catchError(error =>
          of({
            type: types.DELETE_ORG_PROGRESS,
            payload: { task: 'broadcasts', result: error.xhr },
          } as actions.DeleteOrgProgress),
        ),
      ),
    ),
  );

const deleteOrgUsersEpic: HyperEpic<actions.DeleteOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_ORG_START).pipe(
    mergeMap((action: actions.DeleteOrgStart) =>
      api.del(`/api/orgs/${action.payload}/users`).pipe(
        map(
          data =>
            ({
              type: types.DELETE_ORG_PROGRESS,
              payload: { task: 'users', result: data },
            } as actions.DeleteOrgProgress),
        ),
        catchError(error =>
          of({
            type: types.DELETE_ORG_PROGRESS,
            payload: { task: 'users', result: error.xhr },
          } as actions.DeleteOrgProgress),
        ),
      ),
    ),
  );

const deleteOrgProfilesEpic: HyperEpic<actions.DeleteOrgActions> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_ORG_START).pipe(
    mergeMap((action: actions.DeleteOrgStart) =>
      api.del(`/api/orgs/${action.payload}/profiles`).pipe(
        map(
          data =>
            ({
              type: types.DELETE_ORG_PROGRESS,
              payload: { task: 'profiles', result: data },
            } as actions.DeleteOrgProgress),
        ),
        catchError(error =>
          of({
            type: types.DELETE_ORG_PROGRESS,
            payload: { task: 'profiles', result: error.xhr },
          } as actions.DeleteOrgProgress),
        ),
      ),
    ),
  );

const deleteOrgEpic: HyperEpic<actions.DeleteOrgActions | actions.SaveOrgSuccess> = (action$, state$, { api }) =>
  action$.ofType(types.DELETE_ORG_PROGRESS).pipe(
    filter(() => {
      const p = state$.value.orgs.deleteProgress;
      return (
        p != null &&
        p.licensesResult != null &&
        p.schedulesResult != null &&
        p.broadcastsResult != null &&
        p.usersResult != null &&
        p.profilesResult != null &&
        p.orgResult == null
      );
    }),
    mergeMap((action: actions.DeleteOrgProgress) =>
      api
        .put(`/api/orgs/${state$.value.orgs.deleteProgress.id}`, {
          ...state$.value.orgs.orgsById[state$.value.orgs.deleteProgress.id],
          tenantId: null,
          orgName: state$.value.orgs.orgsById[state$.value.orgs.deleteProgress.id].orgName + ' --WIPED',
        })
        .pipe(
          flatMap(data =>
            concat(
              of({
                type: types.SAVE_ORG_SUCCESS,
                payload: data.response,
              }),
              of({
                type: types.DELETE_ORG_PROGRESS,
                payload: { task: 'org', result: data },
              } as actions.DeleteOrgProgress),
            ),
          ),
          catchError(({ xhr }) =>
            of({
              type: types.DELETE_ORG_PROGRESS,
              payload: { task: 'org', result: xhr },
            } as actions.DeleteOrgProgress),
          ),
        ),
    ),
  );

const fetchPermissionsEpic: HyperEpic<actions.FetchPermissionsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_PERMISSIONS).pipe(
    flatMap((action: actions.FetchPermissions) =>
      api.get(`/api/orgs/${action.payload}/permissions`).pipe(
        map(data => ({
          type: types.FETCH_PERMISSIONS_SUCCESS,
          payload: { orgId: action.payload, permissions: data.response },
        })),
        takeUntil(action$.ofType(types.FETCH_PERMISSIONS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_PERMISSIONS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchSettingsEpic: HyperEpic<actions.FetchOrgSettingsActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORG_SETTINGS).pipe(
    flatMap((action: actions.FetchOrgSettings) =>
      api.get(`/api/orgs/${action.payload}/settings`).pipe(
        map(data => ({
          type: types.FETCH_ORG_SETTINGS_SUCCESS,
          payload: { orgId: action.payload, settings: data.response },
        })),
        takeUntil(action$.ofType(types.FETCH_ORG_SETTINGS_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORG_SETTINGS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const saveSettingsEpic: HyperEpic<actions.SaveOrgSettingsActions> = (action$, state$, { api }) =>
  action$.ofType(types.SAVE_ORG_SETTINGS).pipe(
    mergeMap((action: actions.SaveOrgSettings) =>
      api.put(`/api/orgs/${action.payload.orgId}/settings`, action.payload.settings).pipe(
        map(data => ({
          type: types.SAVE_ORG_SETTINGS_SUCCESS,
          payload: { orgId: action.payload.orgId, settings: data.response },
        })),
        takeUntil(action$.ofType(types.SAVE_ORG_SETTINGS_CANCEL)),
        catchError(error =>
          of({
            type: types.SAVE_ORG_SETTINGS_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchAudiencesEpic: HyperEpic<actions.FetchOrgAudiencesActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_ORG_AUDIENCES).pipe(
    flatMap((action: actions.FetchOrgAudiences) =>
      api.get(`/api/orgs/${action.payload}/audiences`).pipe(
        map(data => ({
          type: types.FETCH_ORG_AUDIENCES_SUCCESS,
          payload: { orgId: action.payload, audiences: data.response },
        })),
        takeUntil(action$.ofType(types.FETCH_ORG_AUDIENCES_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_ORG_AUDIENCES_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

export const orgsEpics = [
  deleteOrgBroadcastsEpic,
  deleteOrgEpic,
  deleteOrgLicensesEpic,
  deleteOrgSchedulesEpic,
  deleteOrgUsersEpic,
  deleteOrgProfilesEpic,
  fetchAudiencesEpic,
  fetchOrgEpic,
  fetchOrgFieldsEpic,
  setCollectionByAttributeEpic,
  fetchOrgLicensesEpic,
  fetchOrgsEpic,
  fetchOrgsPageEpic,
  fetchPermissionsEpic,
  fetchSettingsEpic,
  saveSettingsEpic,
  saveOrgEpic,
  exportOrgsEpic,
];
