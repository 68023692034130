export const FETCH_EMAILS = 'FETCH_EMAILS' as 'FETCH_EMAILS';
export const FETCH_EMAILS_SUCCESS = 'FETCH_EMAILS_SUCCESS' as 'FETCH_EMAILS_SUCCESS';
export const FETCH_EMAILS_FAIL = 'FETCH_EMAILS_FAIL' as 'FETCH_EMAILS_FAIL';
export const FETCH_EMAILS_CANCEL = 'FETCH_EMAILS_CANCEL' as 'FETCH_EMAILS_CANCEL';
export const CLEAR_EMAILS = 'CLEAR_EMAILS' as 'CLEAR_EMAILS';

export const FETCH_EMAIL_DETAIL = 'FETCH_EMAIL_DETAIL' as 'FETCH_EMAIL_DETAIL';
export const FETCH_EMAIL_DETAIL_SUCCESS = 'FETCH_EMAIL_DETAIL_SUCCESS' as 'FETCH_EMAIL_DETAIL_SUCCESS';
export const FETCH_EMAIL_DETAIL_FAIL = 'FETCH_EMAIL_DETAIL_FAIL' as 'FETCH_EMAIL_DETAIL_FAIL';
export const FETCH_EMAIL_DETAIL_CANCEL = 'FETCH_EMAIL_DETAIL_CANCEL' as 'FETCH_EMAIL_DETAIL_CANCEL';

export const SEND_EMAIL = 'SEND_EMAIL' as 'SEND_EMAIL';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS' as 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL' as 'SEND_EMAIL_FAIL';
export const SEND_EMAIL_CANCEL = 'SEND_EMAIL_CANCEL' as 'SEND_EMAIL_CANCEL';

export const FETCH_EMAILS_PAGE = 'FETCH_EMAILS_PAGE' as 'FETCH_EMAILS_PAGE';
