import { HyperEpic } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';

import { State, License } from '../../models';
import * as actions from './actions';
import * as types from './types';
import { CreateLicense } from 'store/Licenses/actions';

type LinksState = State['links'];

const initialState = {} as LinksState;

export default function(state: LinksState = initialState, action: actions.Action): LinksState {
  switch (action.type) {
    case types.FETCH_LICENSING_LINK:
      return {
        ...state,
        licensingLinkIsLoading: true,
      };
    case types.FETCH_LICENSING_LINK_SUCCESS:
      return {
        ...state,
        licensingLinkIsLoading: false,
        licensingLink: action.payload,
        licensingLinkError: null,
      };
    case types.FETCH_LICENSING_LINK_FAIL:
      return {
        ...state,
        licensingLinkIsLoading: false,
        licensingLinkError: action.payload || true,
      };
    case types.FETCH_LICENSING_LINK_CANCEL:
      return {
        ...state,
        licensingLinkIsLoading: false,
      };
    case types.CLEAR_LICENSING_LINK:
      return {
        ...state,
        licensingLink: null,
        licensingLinkError: null,
        licensingLinkIsLoading: false,
      };

    case types.FETCH_IMPERSONATION_LINK:
      return {
        ...state,
        impersonationLinkIsLoading: true,
      };
    case types.FETCH_IMPERSONATION_LINK_SUCCESS:
      return {
        ...state,
        impersonationLinkIsLoading: false,
        impersonationLink: action.payload,
        impersonationLinkError: null,
      };
    case types.FETCH_IMPERSONATION_LINK_FAIL:
      return {
        ...state,
        impersonationLinkIsLoading: false,
        impersonationLinkError: action.payload || true,
      };
    case types.FETCH_IMPERSONATION_LINK_CANCEL:
      return {
        ...state,
        impersonationLinkIsLoading: false,
      };
    case types.CLEAR_IMPERSONATION_LINK:
      return {
        ...state,
        impersonationLink: null,
        impersonationLinkError: null,
        impersonationLinkIsLoading: false,
      };
    default:
      return state;
  }
}

// action creators
export const fetchLicensingLink = (license: CreateLicense): actions.FetchLicensingLink => ({
  type: types.FETCH_LICENSING_LINK,
  payload: { license: license.payload },
});
export const clearLicensingLink = (): actions.ClearLicensingLink => ({ type: types.CLEAR_LICENSING_LINK });

export const fetchImpersonationLink = (
  orgId: string,
  id: string,
  isProvider = false,
): actions.FetchImpersonationLink => ({ type: types.FETCH_IMPERSONATION_LINK, payload: { orgId, id, isProvider } });
export const clearImpersonationLink = (): actions.ClearImpersonationLink => ({ type: types.CLEAR_IMPERSONATION_LINK });

// epics
const fetchLicensingLinkEpic: HyperEpic<actions.LicensingLinkActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_LICENSING_LINK).pipe(
    mergeMap((action: actions.FetchLicensingLink) =>
      api.post(`/api/licenses/links`, action.payload.license).pipe(
        map(data => ({
          type: types.FETCH_LICENSING_LINK_SUCCESS,
          payload: data.response,
        })),
        takeUntil(action$.ofType(types.FETCH_LICENSING_LINK_CANCEL)),
        catchError(error =>
          of({
            type: types.FETCH_LICENSING_LINK_FAIL,
            payload: error.xhr.response,
          }),
        ),
      ),
    ),
  );

const fetchImpersonationLinkEpic: HyperEpic<actions.ImpersonationLinkActions> = (action$, state$, { api }) =>
  action$.ofType(types.FETCH_IMPERSONATION_LINK).pipe(
    mergeMap((action: actions.FetchImpersonationLink) =>
      api
        .post(
          action.payload.isProvider
            ? `/api/providers/${action.payload.id}/links`
            : `/api/orgs/${action.payload.orgId}/permissions/${action.payload.id}/links`,
        )
        .pipe(
          map(data => ({
            type: types.FETCH_IMPERSONATION_LINK_SUCCESS,
            payload: data.response,
          })),
          takeUntil(action$.ofType(types.FETCH_IMPERSONATION_LINK_CANCEL)),
          catchError(error =>
            of({
              type: types.FETCH_IMPERSONATION_LINK_FAIL,
              payload: error.xhr.response,
            }),
          ),
        ),
    ),
  );

export const linksEpics = [fetchLicensingLinkEpic, fetchImpersonationLinkEpic];
